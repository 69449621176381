import React from "react";



class NotFound extends React.Component {
    

    

    

    render() {


        return (
            
            <div className="extra-hero container">
                <style>
                    {
                        `.inner-page-btn {
                            display: none;
                        }
                        .extra-hero img {
                            filter: invert(1);
                        }`
                    }
                </style>
                <div className="home-cover-out"></div>
                {/* <div className="home-hero-gra  d-none d-lg-block"></div>
                <div className="home-hero-gra-mob d-block d-lg-none"></div> */}

                    <img alt="Fingerprints Future" src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/fpslogo.svg" className="img-fluid extra-logo" />
                    <h1 className="extra-h1">Uh oh, it looks like you've <br className="d-none d-lg-block"/>found a missing page.</h1>
                    {/* <p className="extra-p">Please book a time slot that works for you.</p> */}
                    <div className="extra-row">
                        <div className="extra-button-col">
                            <a href="/" className="extra-button">BACK TO WEBSITE</a>
                        </div>
                    </div>
            </div>
         );
    }
    
}
 
export default NotFound;