import React from "react";
import Footer from "../components/footer";
import { Helmet } from "react-helmet";
import Lottie from "lottie-react";
import Slider from "react-slick";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import SplitText from "gsap/SplitText";

//Assets
import ResearchJson from "../assets/magnifier icon-02.json";
import DesignJson from "../assets/square icons.json";
import BuildJson from "../assets/open bracket-02.json";
import ContactButton from "../components/maincontactbutton";

import BannerImg from "./../assets/home-banner-shape-img.png";
import BannerImgResp from "./../assets/v2-home-banner-shape-img-resp1-1.png";
import ShapeImg from "./../assets/v2-home-shape-img2-2.png";
import ShapeImgResp from "./../assets/v2-home-shape-img2-2-resp.png";

class Home extends React.Component {
  homeGsap = () => {
    gsap.registerPlugin(ScrollTrigger, SplitText);

    // Home Hero Timeline

    gsap.from(".normal-header", 0.9, {
      opacity: 0,
      ease: "Power3.easeOut",
      delay: 2.5,
    });
    gsap.from("h1.line span", 0.9, {
      y: 180,
      rotateZ: 6,
      ease: "Power3.easeOut",
      delay: 2.5,
      stagger: {
        amount: 0.15,
      },
    });
    gsap.set(".home-hero-gra-mob", { opacity: 0 });
    gsap.to(".home-hero-gra-mob", {
      yPercent: 0,
      opacity: 1,
      ease: "Power3.easeOut",
      delay: 2,
      duration: 1.5,
    });
    gsap.set(".home-hero-gra", { yPercent: 100 });
    gsap.to(".home-hero-gra", {
      yPercent: 0,
      ease: "Power3.easeOut",
      delay: 2,
      duration: 1.5,
    });
    gsap.set(".hh-mobile-h1 h1", { opacity: 0, yPercent: 20 });
    gsap.to(".hh-mobile-h1 h1", {
      delay: 3,
      opacity: 1,
      yPercent: 0,
      duration: 0.7,
    });
    gsap.set(".extra-p", { opacity: 0, yPercent: 40 });
    gsap.to(".extra-p", {
      delay: 3.4,
      opacity: 1,
      yPercent: 0,
      duration: 0.7,
    });
    gsap.set(".extra-button", { opacity: 0, yPercent: 40 });
    gsap.to(".extra-button", {
      delay: 3.7,
      opacity: 1,
      yPercent: 0,
      duration: 0.7,
    });
    gsap.set(".footer-nav-parent", { opacity: 0 });
    gsap.to(".footer-nav-parent", {
      delay: 3,
      opacity: 1,
      yPercent: 0,
      duration: 0.7,
    });
    gsap.set(".ha-mobile-h2 h2", { opacity: 0, yPercent: 20 });
    gsap.to(".ha-mobile-h2 h2", {
      opacity: 1,
      yPercent: 0,
      scrollTrigger: {
        trigger: ".ha-mobile-h2 h2",
        start: "top 70%",
      },
    });
    gsap.set(".ha-mobile-a a", { opacity: 0, yPercent: 20 });
    gsap.to(".ha-mobile-a a", {
      opacity: 1,
      yPercent: 0,
      scrollTrigger: {
        trigger: ".ha-mobile-a a",
        start: "top 70%",
      },
    });
    gsap.set(".home-about .line span", { y: 180, rotateZ: 6 });
    gsap.to(".home-about .line span", {
      y: 0,
      rotateZ: 0,
      ease: "Power3.easeOut",
      delay: 0,
      stagger: {
        amount: 0.15,
      },
      scrollTrigger: {
        trigger: ".home-about .line span",
        start: "top 90%",
      },
    });

    gsap.set(".home-clients .line span", { y: 180, rotateZ: 6 });
    gsap.to(".home-clients .line span", {
      y: 0,
      rotateZ: 0,
      ease: "Power3.easeOut",
      delay: 0,
      stagger: {
        amount: 0.15,
      },
      scrollTrigger: {
        trigger: ".home-clients .line span",
        start: "top 90%",
      },
    });

    gsap.set(".home-what .line span", { y: 180, rotateZ: 6 });
    gsap.to(".home-what .line span", {
      y: 0,
      rotateZ: 0,
      ease: "Power3.easeOut",
      delay: 0,
      duration: 1,
      scrollTrigger: {
        trigger: ".home-what .line span",
        start: "top 90%",
      },
    });
    gsap.set(".hw-mobile-h2 h2", { opacity: 0, yPercent: 20 });
    gsap.to(".hw-mobile-h2 h2", {
      opacity: 1,
      yPercent: 0,
      scrollTrigger: {
        trigger: ".hw-mobile-h2 h2",
        start: "top 70%",
      },
    });
    gsap.set(".home-what a", { opacity: 0 });
    gsap.to(".home-what a", {
      opacity: 1,
      ease: "Power3.easeOut",
      delay: 0.7,
      scrollTrigger: {
        trigger: ".home-what .line span",
        start: "top 90%",
      },
    });
    gsap.set(".home-about .link", { opacity: 0 });
    gsap.to(".home-about .link", {
      opacity: 1,
      ease: "Power3.easeOut",
      delay: 1,
      scrollTrigger: {
        trigger: ".home-about .line span",
        start: "top 90%",
      },
    });
    gsap.set(".home-about-inner2-h2", { opacity: 0 });
    gsap.to(".home-about-inner2-h2", {
      opacity: 1,
      ease: "Power3.easeOut",
      duration: 1,
      scrollTrigger: {
        trigger: ".home-about-inner2-h2",
        start: "top 60%",
      },
    });
    gsap.set(".home-about-inner2-line", { width: "0%" });
    gsap.to(".home-about-inner2-line", {
      width: "73.3%",
      ease: "Power3.easeOut",
      delay: 0.5,
      duration: 1,
      scrollTrigger: {
        trigger: ".home-about-inner2-line",
        start: "top 60%",
      },
    });
    gsap.set(".home-about-inner2-h3", { y: "24vw", rotateZ: "5deg" });
    gsap.to(".home-about-inner2-h3", {
      y: 0,
      rotateZ: "0deg",
      ease: "Power3.easeOut",
      delay: 1,
      scrollTrigger: {
        trigger: ".home-about-inner2-line",
        start: "top 60%",
      },
    });
    gsap.set(".home-about-inner2-h32", { y: "24vw", rotateZ: "5deg" });
    gsap.to(".home-about-inner2-h32", {
      y: 0,
      rotateZ: "0deg",
      ease: "Power3.easeOut",
      delay: 0.5,
      scrollTrigger: {
        trigger: ".home-about-inner2-h3",
        start: "top 60%",
      },
    });
    gsap.set(".mob-gra-text-1", { y: "24vw", rotateZ: "5deg" });
    gsap.to(".mob-gra-text-1", {
      y: 0,
      rotateZ: "0deg",
      ease: "Power3.easeOut",
      delay: 0.3,
      scrollTrigger: {
        trigger: ".mob-gra-text-1",
        start: "top 60%",
      },
    });
    gsap.set(".mob-gra-text-2", { y: "24vw", rotateZ: "5deg" });
    gsap.to(".mob-gra-text-2", {
      y: 0,
      rotateZ: "0deg",
      ease: "Power3.easeOut",
      delay: 0.6,
      scrollTrigger: {
        trigger: ".mob-gra-text-1",
        start: "top 60%",
      },
    });

    gsap.set(".fixed-work", { position: "relative" });
    gsap.to(".fixed-work", {
      position: "fixed",
      top: "10%",
      ease: "Power3.easeOut",
      scrollTrigger: {
        trigger: ".fixed-work",
        start: "1%",
        scrub: true,
      },
    });
    // gsap.set(".fixed-work", {  color: "#FFFFFF" });
    // gsap.to(".fixed-work", {
    // color: "#808080",
    // ease: "Power3.easeOut",
    // scrollTrigger: {
    //     trigger: ".fixed-work",
    //     start: "25%",
    //     scrub: true
    // }
    // });
    gsap.set(".fixed-work", { opacity: 1 });
    gsap.to(".fixed-work", {
      opacity: 0,
      ease: "Power3.easeOut",
      scrollTrigger: {
        trigger: ".work-hide",
        start: "top 150%",
        scrub: true,
      },
    });
    gsap.set(".vd-col-row", { scale: 1.5 });
    gsap.to(".vd-col-row", {
      scale: 1,
      ease: "Power3.linear",
      scrollTrigger: {
        trigger: ".home-section2",
        duration: 1,
        start: "top 95%",
      },
    });
    gsap.set(".video-col1", { yPercent: -15 });
    gsap.to(".video-col1", {
      yPercent: 0,
      ease: "Power3.easeOut",
      scrollTrigger: {
        trigger: ".home-section2",
        start: "top 90%",
        scrub: true,
      },
    });
    gsap.set(".video-col3", { yPercent: -30 });
    gsap.to(".video-col3", {
      yPercent: 0,
      ease: "Power3.easeOut",
      scrollTrigger: {
        trigger: ".home-section2",
        start: "top 90%",
        scrub: true,
      },
    });
    gsap.set(".video-col2", { yPercent: 15 });
    gsap.to(".video-col2", {
      yPercent: -15,
      ease: "Power3.easeOut",
      scrollTrigger: {
        trigger: ".home-section2",
        start: "top 90%",
        scrub: true,
      },
    });
    // gsap.set(".home-section2", {visibility: "visible" });
    // gsap.to(".home-section2", {
    //   visibility: "hidden",
    //   ease: "Power3.easeOut",
    //   scrollTrigger: {
    //     trigger: ".home-infinity",
    //     start: 'top 40%',
    //     scrub: true,
    //   }
    // });
    gsap.set(".home-what-inner2-row", { width: 0 });
    gsap.to(".home-what-inner2-row", {
      width: "100%",
      ease: "Power3.easeOut",
      duration: 1,
      scrollTrigger: {
        trigger: ".home-what-inner2",
        start: "top 80%",
      },
    });
    gsap.set(".lottie-up", { display: "none" });
    gsap.to(".lottie-up", {
      display: "block",
      ease: "Power3.easeOut",
      duration: 1,
      scrollTrigger: {
        trigger: ".home-what-inner2",
        start: "top 70%",
      },
    });
    gsap.set(".home-infinity", { opacity: 1 });
    gsap.to(".home-infinity", {
      opacity: 0,
      ease: "Power3.easeOut",
      scrollTrigger: {
        trigger: ".home-what-inner1",
        end: "top 25%",
        scrub: true,
      },
    });
    gsap.set(".home-infinity", { visibility: "hidden" });
    gsap.to(".home-infinity", {
      visibility: "visible",
      ease: "Power3.easeOut",
      scrollTrigger: {
        trigger: ".home-about",
        end: "top 25%",
        scrub: true,
      },
    });
    gsap.set(".workanimation", { visibility: "hidden" });
    ScrollTrigger.batch(".workanimation", {
      onEnter: (batch) => {
        gsap.from(batch, {
          duration: 1.5,
          delay: 0,
          yPercent: 50,
          scale: 1,
          autoAlpha: 0,
          ease: "Power3.easeOut",
          stagger: 0.4,
          visibility: "visible",
        });
      },
      toggleActions: "play none none none",
      once: true,
      start: "top 80%",
      end: "top 40%",
      markers: false,
    });

    //Experimental
    gsap.set(".svg1", { visibility: "hidden" });
    ScrollTrigger.batch(".svg1", {
      onEnter: (batch) => {
        gsap.from(batch, {
          duration: 1,
          opacity: 1,
          delay: 0,
          yPercent: 100,
          rotateZ: 5,
          scale: 1,
          autoAlpha: 0,
          ease: "Power3.easeOut",
          stagger: 0.08,
          visibility: "visible",
        });
      },
      toggleActions: "play none none none",
      once: true,
      start: "top 80%",
      end: "top 40%",
      markers: false,
    });

    // gsap.set(".loadingh2 span", {translateY: "100%" });
    // gsap.to(".loadingh2 span", {
    //   translateY: "0%",
    //   delay: 0.1,
    //   duration: 1,
    //   ease: "Power3.easeIn",
    // });
    gsap.set(".loadingh2 span", { visibility: "hidden" });
    ScrollTrigger.batch(".loadingh2 span", {
      onEnter: (batch) => {
        gsap.from(batch, {
          duration: 0.5,
          delay: 0,
          yPercent: 50,
          scale: 1,
          autoAlpha: 0,
          ease: "Power3.easeOut",
          stagger: 0.1,
          visibility: "visible",
        });
      },
      toggleActions: "play none none none",
      once: true,
      start: "top 80%",
      end: "top 40%",
      markers: false,
    });

    gsap.set(".home-cover", { translateY: 0 });
    gsap.to(".home-cover", {
      translateY: "-100%",
      delay: 1.2,
      duration: 1,
      ease: "Power3.easeIn",
    });
    gsap.set(".homeparent", { height: "100vh" });
    gsap.to(".homeparent", {
      height: "auto",
      delay: 3,
    });

    gsap.set(".slogan div", { position: "absolute", top: "0px", left: "0px" });

    var slogans = gsap.utils.toArray(".slogan div");
    var container = document.querySelector(".container0");

    var animationTime = 0.5;
    var transitionDelay = 2;
    var texts = ["Websites", "Apps", "MVPS", "Saas"];

    var totalSlogans = slogans.length;
    var currentSlogan = 0;

    animateSlogans();

    function animateSlogans() {
      var nextSlogan = (currentSlogan + 1) % totalSlogans;

      gsap.to(slogans[currentSlogan], {
        top: "-20px",
        alpha: 0,
        rotationX: 90,
        duration: animationTime,
      });
      gsap.fromTo(
        slogans[nextSlogan],
        { top: "20px", alpha: 0, rotationX: -90 },
        { top: "0px", alpha: 1, rotationX: 0, duration: animationTime }
      );

      currentSlogan = nextSlogan;
      gsap.delayedCall(transitionDelay, animateSlogans);
    }

    function updateTexts() {
      slogans.forEach(function (slogan, index) {
        slogan.textContent = texts[(currentSlogan + index) % texts.length];
      });
    }

    updateTexts();
  };

  componentDidMount() {
    this.homeGsap();
  }

  render() {
    var testimonialSettings = {
      dots: false,
      arrows: true,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 1500,
      slidesToShow: 1,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    var logoSettings = {
      dots: false,
      arrows: false,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 5000,
      centerMode: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    return (
      <div className="homeparent home2">
        <Helmet>
          <title>
            Leading UI UX Design Company in India | UI UX Design Agency -
            Fingerprints Future
          </title>
          <meta
            name="description"
            content="Fingerprints Future is the leading UI UX Design Company in India. We offer professional UI UX Design services by expert designers. Get creative and advanced UI UX Design solutions for your business today!"
          />
        </Helmet>
        <div className="home-cover-out"></div>
        <div className="home-cover d-flex justify-content-center align-items-center position-fixed t-0 l-0 w-100 vh-100 min-vh-100 z-10000 bg-color-white color-black overflow-hidden visibility-hidden pointer-events-none">
          {/* <svg width="1147" height="309" viewBox="0 0 1147 309" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_1548_28162)">
                    <path className="svg1 svg2 svg3" d="M89.2691 41.4495C100.924 41.4495 115.793 42.6653 129.493 43.8812L133.904 5.92308C117.02 3.49139 95.3158 0.199707 75.1893 0.199707C36.1925 0.229362 0 13.2775 0 67.1898V91.5364H59.4446V152.418H0V301.077H59.094V152.803H121.839V91.2695H59.094V73.2987C59.094 47.9735 71.5672 41.4495 89.2399 41.4495H89.2691Z" fill="white"/>
                    <path className="svg1 svg2 svg3" d="M344.577 301.079H296.729L293.107 272.907C264.568 295.77 233.195 308.018 205.065 308.018C171.297 308.018 147.957 290.047 147.957 250.043V96.9649H207.08V234.533C207.08 252.089 215.113 260.659 231.997 260.659C248.881 260.659 266.174 253.305 285.483 238.626V96.9649H344.577V301.079V301.079Z" fill="white"/>
                    <path className="svg1 svg2 svg3" d="M515.745 265.167C504.499 265.968 492.406 266.383 483.175 266.383C466.671 266.383 454.636 260.244 454.636 235.364V152.716H515.19V90.9747H454.636V29.7673H395.104V91.5382H454.636V152.419H395.104V241.058C395.104 294.94 430.098 308.018 467.898 308.018C485.57 308.018 504.47 305.171 519.367 302.71L515.745 265.167Z" fill="white"/>
                    <path className="svg1 svg2 svg3" d="M755.61 301.079H707.762L704.14 272.907C675.601 295.77 644.228 308.018 616.098 308.018C582.33 308.018 558.99 290.047 558.99 250.043V96.9649H618.113V234.533C618.113 252.089 626.147 260.659 643.031 260.659C659.915 260.659 677.207 253.305 696.516 238.626V96.9649H755.61V301.079Z" fill="white"/>
                    <path className="svg1 svg2 svg3" d="M937.271 91.2713L932.043 140.261C922.374 140.261 914.75 139.846 907.505 140.646C891.001 141.477 876.95 147.586 854.02 165.972V301.079H794.926V96.965H843.182L846.805 129.229C880.193 100.672 899.881 90.0554 924.418 90.0554C929.238 90.0554 933.269 90.4706 937.271 91.2713V91.2713Z" fill="white"/>
                    <path className="svg1 svg2 svg3" d="M1130.79 114.521C1144.87 131.276 1148.87 156.571 1145.25 194.114L1143.64 212.5H1019.38C1020.61 254.55 1030.25 264.752 1060.39 264.752C1092.55 264.752 1109.44 260.659 1129.54 255.766L1135.58 295.771C1119.11 301.909 1095.36 308.018 1061.18 308.018C980.355 308.018 958.242 279.045 958.242 194.529C958.242 116.152 989.615 89.6106 1061.18 90.0258C1091.74 90.0258 1115.87 97.3802 1130.76 114.521H1130.79ZM1019 183.527H1097.81C1098.19 165.556 1096.21 153.309 1091.39 145.984C1085.34 136.584 1075.29 133.322 1060.42 133.322C1033.08 133.322 1021.02 143.137 1019 183.527V183.527Z" fill="white"/>
                    </g>
                    <defs>
                    <clipPath id="clip0_1548_28162">
                    <rect width="1146.51" height="307.787" fill="white" transform="translate(0 0.23114)"/>
                    </clipPath>
                    </defs>
                </svg> */}
          <h2 className="loadingh2">
            <span className="loading loading1">The </span>
            <span className="loading loading2">future </span>
            <span className="loading loading3">will </span>
            <span className="loading loading4">be </span>
            <span className="loading loading5">the </span>
            <span className="loading loading6">one </span>
            <span className="loading loading7">you </span>
            <span className="loading loading8">build</span>
          </h2>
        </div>

        <style>
          {`.inner-page-btn {
                            display: none;
                        }
                        
                        footer h2{
                            color: #23262F;
                        }
                        label {
                            color: #23262F;
                        }
                        input, textarea {
                            color: #23262F;
                            border: 0.5px solid #23262F;
                        }
                        .form-control {
                            color: #23262F;
                            border: 1px solid #23262F;
                        }
                        .form-control:hover, .form-control:focus {
                            background-color: rgb(255, 255, 255);
                            color: #23262F;
                        }
                        .footer-content h5 {
                            color: #23262F;
                        }
                        .footer-content .mail {
                            color: #23262F;
                        }
                        .footer-content .number {
                            color: #23262F;
                        }
                        .copyrights h6 {
                            color: #23262F;
                        }`}
        </style>

        {/* Hero Section */}
        <div className="home-hero" id="hero">
          <div className="v2-home-banner-shape-img v2-home-banner-video">
            <div className="d-none d-lg-block d-md-block">
              <img
                alt="Fingerprints Future"
                className="img-fluid"
                src={BannerImg}
              />
              {/* <video className='project-text'  src="https://storage.googleapis.com/fingerprintsfuturewebsite/videos/v2-home-banner-video.mp4" muted autoPlay={"autoplay"} preload="auto" loop playsInline controlsList="nodownload" /> */}
            </div>
            <div className="d-block d-lg-none d-md-none">
              <img
                alt="Fingerprints Future"
                className="img-fluid"
                src={BannerImgResp}
              />
              {/* <video className='project-text'  src="https://storage.googleapis.com/fingerprintsfuturewebsite/videos/v2-home-banner-video.mp4" muted autoPlay={"autoplay"} preload="auto" loop playsInline controlsList="nodownload" /> */}
            </div>
          </div>
          <div className="container">
            <div className="col-lg-8 home-hero-content">
              <h1 className="line d-none d-lg-block d-md-block">
                <span>Imagine, ideate </span>
              </h1>
              <h1 className="line d-none d-lg-block d-md-block">
                <span>& evolve.</span>
              </h1>
              <div className="hh-mobile-h1 d-block d-lg-none d-md-none">
                <h1 className="">
                  Imagine, <br className="d-block d-lg-none d-md-none" /> ideate
                  & <br className="d-block d-lg-none d-md-none" /> evolve.
                </h1>
              </div>
              {/* <p className="extra-p">We are an UI/UX organization that <br className="d-block d-lg-none d-md-none"/> brings ideas<br className="d-none d-lg-block d-md-block"/> to life through <br className="d-block d-lg-none d-md-none"/> digital experiences.</p> */}

              <div className="extra-button-col">
                {/* <a href="/contact" className="extra-button">Get Started</a> */}
                <ContactButton />
              </div>
            </div>
          </div>
          <div className="marquee-parent">
            <div className="content">
              <h2 className="marquee-e">
                {" "}
                WEBSITES <span></span> MOBILE APPS <span></span> SAAS
                APPLICATIONS <span></span> WEBSITES <span></span> MOBILE APPS{" "}
                <span></span> SAAS APPLICATIONS <span></span> WEBSITES{" "}
                <span></span> MOBILE APPS <span></span> SAAS APPLICATIONS{" "}
                <span></span>
              </h2>
            </div>
          </div>
        </div>

        <div className="v2-section2">
          <div className="row">
            <div className="col-lg-7 col-md-6 v2-home-shape-img2">
              <div className="d-none d-lg-block d-md-block">
                <img
                  alt="Fingerprints Future"
                  className="img-fluid"
                  src={ShapeImg}
                />
              </div>
              <div className="d-block d-lg-none d-md-none">
                <img
                  alt="Fingerprints Future"
                  className="img-fluid"
                  src={ShapeImgResp}
                />
              </div>
            </div>
            <div className="col-lg-5 col-md-6">
              <div className="container v2-section2-cont home-about-inner1">
                <div className="home-about-h2">
                  <h2 className="line d-none d-lg-block d-md-block">
                    <span className="workanimation">We build simple and </span>
                  </h2>
                  <h2 className="workanimation d-block d-lg-none d-md-none">
                    <span className="">We build simple and</span>
                  </h2>
                  <h2 className="workanimation d-flex align-items-start">
                    {/* <span className="d-none d-lg-block d-md-block">feature-rich </span> */}
                    <span className="">complex </span>
                    <div class="container0">
                      <div class="slogan-container">
                        <h2 class="slogan">
                          <div>Websites</div>
                        </h2>
                        <h2 class="slogan">
                          <div>Apps</div>
                        </h2>
                        <h2 class="slogan">
                          <div>MVPS</div>
                        </h2>
                        <h2 class="slogan">
                          <div>Saas</div>
                        </h2>
                      </div>
                    </div>
                  </h2>
                  <p className="shrink d-none d-lg-block workanimation">
                    We build customer centric experiences backed by{" "}
                    <br className="d-none d-lg-block d-md-block" /> creativity,
                    strategy, design and data.
                  </p>
                  <p className="shrink d-none d-lg-block workanimation mt-3">
                    Found what you are looking for?
                  </p>
                  <p className="shrink d-block d-lg-none workanimation">
                    We build customer centric experiences backed by creativity,
                    strategy, design and data.{" "}
                    <br className="d-none d-lg-block d-md-block" />
                  </p>
                  <p className="shrink d-block d-lg-none workanimation mt-3">
                    Found what you are looking for?
                  </p>
                </div>
                <div className="extra-button-col d-none d-lg-block workanimation">
                  {/* <a href="/work" className="extra-button work-link">Our Work</a> */}
                  <ContactButton />
                </div>
                <div className="ha-mobile-a d-block d-lg-none workanimation">
                  {/* <a className="process-link" href="/services">Our Capabilities</a> */}
                  <div className="extra-button-col">
                    {/* <a href="/work" className="extra-button work-link">Our Work</a> */}
                    <ContactButton />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container"></div>
        </div>
        {/* About Section */}
        <div className="home-about v2-home-about">
          <div className="container home-about-inner1">
            <div className="home-about-h2">
              <h2 className="line d-none d-lg-block d-md-block">
                <span className="workanimation">
                  From concept to launch, we are with{" "}
                </span>
              </h2>
              <h2 className="line d-none d-lg-block d-md-block">
                <span className="workanimation">you every step of the way</span>
              </h2>
              <div className="workanimation d-block d-lg-none d-md-none shrink">
                <h2>
                  From concept to <br className="d-block d-lg-none d-md-none" />{" "}
                  launch, we are with you{" "}
                  <br className="d-block d-lg-none d-md-none" /> every step of
                  the way
                </h2>
              </div>
            </div>
            <div className="extra-button-col d-none d-lg-block workanimation">
              <a href="/work" className="extra-button work-link">
                Our Work
              </a>
              {/* <ContactButton/> */}
            </div>
            <div className="ha-mobile-a d-block d-lg-none workanimation">
              {/* <a className="process-link" href="/services">Our Capabilities</a> */}
              <div className="extra-button-col">
                <a href="/work" className="extra-button work-link">
                  Our Work
                </a>
                {/* <ContactButton/> */}
              </div>
            </div>
          </div>
          <div className="container v2-home-about-new-inner2 home-about-new-inner2 d-none d-lg-flex">
            <div className="cover workanimation">
              <Lottie
                loop
                animationData={ResearchJson}
                play
                style={{
                  width: "2.778vw",
                  height: "2.778vw",
                  filter: "invert(1)",
                }}
              />
              <h3>Research</h3>
              <p>
                Digital Product Strategy <br />
                UX research <br />
                Heuristic Analysis <br />
                User Personas <br />
                Content Strategy <br />
                Information Architecture <br />
                Design Audit <br />
                Usability Testing <br />
                User Interviews <br />
                Wireframing <br />
              </p>
            </div>
            <div className="cover workanimation">
              <Lottie
                loop
                animationData={DesignJson}
                play
                style={{
                  width: "2.778vw",
                  height: "2.778vw",
                  filter: "invert(1)",
                }}
              />
              <h3>Design</h3>
              <p>
                Digital Branding <br />
                User Experience Design <br />
                User interface Design <br />
                Interaction Design <br />
                Digital Prototyping <br />
                Motion Design <br />
                Illustrations and Iconography <br />
                Design System <br />
              </p>
            </div>
            <div className="workanimation">
              <Lottie
                loop
                animationData={BuildJson}
                play
                style={{
                  width: "2.778vw",
                  height: "2.778vw",
                  filter: "invert(1)",
                }}
              />
              <h3>Build</h3>
              <p>
                Front-End Development <br />
                Web Application <br />
                Mobile Application <br />
                Custom Application <br />
                SAAS Implementation <br />
              </p>
            </div>
          </div>
          <div className="home-what-inner2-mobile v2-home-what-inner2-mobile d-block d-lg-none">
            <div className="accordion" id="accordionExample">
              <div className="accordion-item workanimation">
                <h2 className="accordion-header" id="headingOne">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="false"
                    aria-controls="collapseOne">
                    <div className="row">
                      <div className="col-6 mobile-lottie-acc">
                        <Lottie
                          loop
                          animationData={ResearchJson}
                          play
                          style={{
                            width: "30px",
                            height: "30px",
                            filter: "invert(1)",
                          }}
                        />
                      </div>
                      <div className="col-6">
                        <h3>Research</h3>
                      </div>
                    </div>
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample">
                  <div className="accordion-body">
                    <p>
                      Digital Product Strategy <br />
                      UX research <br />
                      Heuristic Analysis <br />
                      User Personas <br />
                      Content Strategy <br />
                      Information Architecture <br />
                      Design Audit <br />
                      Usability Testing <br />
                      User Interviews <br />
                      Wireframing <br />
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item workanimation">
                <h2 className="accordion-header" id="headingTwo">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo">
                    <div className="row">
                      <div className="col-6 mobile-lottie-acc">
                        <Lottie
                          loop
                          animationData={DesignJson}
                          play
                          style={{
                            width: "30px",
                            height: "30px",
                            filter: "invert(1)",
                          }}
                        />
                      </div>
                      <div className="col-6">
                        <h3>Design</h3>
                      </div>
                    </div>
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample">
                  <div className="accordion-body">
                    <p>
                      Digital Branding <br />
                      User Experience Design <br />
                      User interface Design <br />
                      Interaction Design <br />
                      Digital Prototyping <br />
                      Motion Design <br />
                      Illustrations and Iconography <br />
                      Design System <br />
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item workanimation">
                <h2 className="accordion-header" id="headingThree">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree">
                    <div className="row">
                      <div className="col-6 mobile-lottie-acc">
                        <Lottie
                          loop
                          animationData={BuildJson}
                          play
                          style={{
                            width: "30px",
                            height: "30px",
                            filter: "invert(1)",
                          }}
                        />
                      </div>
                      <div className="col-6">
                        <h3>Build</h3>
                      </div>
                    </div>
                  </button>
                </h2>
                <div
                  id="collapseThree"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingThree"
                  data-bs-parent="#accordionExample">
                  <div className="accordion-body">
                    <p>
                      Front-End Development <br />
                      Web Application <br />
                      Mobile Application <br />
                      Custom Application <br />
                      SAAS Implementation <br />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Video Scroll Section */}
        <div className="home-section2 hide-nav workanimation">
          <video
            src="https://storage.googleapis.com/fingerprintsfuturewebsite/videos/FP-Future.mp4"
            muted
            autoPlay={"autoplay"}
            preload="auto"
            loop
            playsInline
            controlsList="nodownload"
            poster="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/poster-video-p.png"
          />
        </div>
        {/* Work Lead */}
        <div className="home-work-lead v2-home-work-lead">
          <div className="container home-about-inner1">
            <div className="home-about-h2">
              <h2 className="line d-none d-lg-block d-md-block">
                <span className="workanimation">
                  Exceptional Design, Meticulous Development:{" "}
                </span>
              </h2>
              <h2 className="line d-none d-lg-block d-md-block">
                <span className="workanimation">
                  Why we are a cut above the rest
                </span>
              </h2>
              <div className="workanimation d-block d-lg-none d-md-none">
                <h2>
                  Exceptional Design, Meticulous Development: Why we are a cut
                  above the rest
                </h2>
              </div>
              <p className="d-none d-lg-block workanimation">
                What truly sets us apart is our people first approach, growth
                mindset, and <br className="d-none d-lg-block d-md-block" /> a
                keen understanding of what makes digital products work.
              </p>
              <p className="d-none d-lg-block workanimation">
                We are also backed by our outstanding team of experts with 16+
                years of <br className="d-none d-lg-block d-md-block" />{" "}
                branding, communication, and digital product building
                experience.
              </p>
              <p className="d-block d-lg-none workanimation">
                What truly sets us apart is our people first approach, growth
                mindset, and a keen understanding of what makes digital products
                work.
              </p>
              <p className="d-block d-lg-none workanimation">
                We are also backed by our outstanding team of experts with 16+
                years of branding, communication, and digital product building
                experience.
              </p>
            </div>
            <div className="extra-button-col d-none d-lg-block workanimation">
              <a href="/work" className="extra-button work-link">
                Our Work
              </a>
            </div>
            <div className="ha-mobile-a d-block d-lg-none workanimation">
              {/* <a className="process-link" href="/work">View all work</a> */}
              <div className="extra-button-col">
                <a href="/work" className="extra-button work-link">
                  Our Work
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* Work Section */}
        <div className="home-work v2-home-work">
          <div className="container fixed-work-cont">
            <div className="row home-work-parent">
              <div className="col-lg-6 col-md-6  home-work-child workanimation">
                <a className="mahasaghar-link" href="/work/mahasaghar"></a>
                <img
                  alt="Fingerprints Future"
                  className="img-fluid"
                  src="https://ik.imagekit.io/fingerprintsfuture/fps/mahasaghar-cover-sm.png"
                />
                <h3>Mahāsa Ghar</h3>
                <p>E-Commerce Website Design & Development</p>
              </div>
              <div className="col-lg-6 col-md-6  home-work-child workanimation">
                <a className="oc-academy-link" href="/work/oc-academy"></a>
                <img
                  alt="Fingerprints Future"
                  className="img-fluid"
                  src="https://ik.imagekit.io/fingerprintsfuture/fps/ocacademy-img-cover-sm.png"
                />
                <h3>OC Academy</h3>
                <p>Website Design</p>
              </div>
              <div className="col-lg-6 col-md-6 home-work-child workanimation">
                <a className="healthcare-link" href="/work/healthcare"></a>
                <img
                  alt="Fingerprints Future"
                  className="img-fluid"
                  src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/health-care-cover.png"
                />
                <h3>Healthcare</h3>
                <p>Product Design</p>
              </div>
              <div className="col-lg-6 col-md-6 home-work-child workanimation">
                <a className="xtm-link" href="/work/xtm"></a>
                <img
                  alt="Fingerprints Future"
                  className="img-fluid"
                  src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/xtm-img-cover.png"
                />
                <h3>XTM</h3>
                <p>Website Design & Development</p>
              </div>
              <div className="col-lg-6 col-md-6 home-work-child workanimation">
                <a className="eleveight-link" href="/work/eleveight"></a>
                <img
                  alt="Fingerprints Future"
                  width="348.06"
                  height="302.06"
                  className="img-fluid"
                  src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/eleveight-img-cover.png"
                />
                <h3>Eleveight</h3>
                <p>Website Design & Development</p>
              </div>
              <div className="col-lg-6 col-md-6 home-work-child workanimation">
                <a className="tnq-link" href="/work/tnq"></a>
                <img
                  alt="Fingerprints Future"
                  className="img-fluid"
                  src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/tnq-cover-1-2x.png"
                />
                <h3>TNQ</h3>
                <p>Website Design & Development</p>
              </div>
              <div className="col-lg-6 col-md-6 home-work-child workanimation">
                <a className="flyet-link" href="/work/Flyet"></a>
                <img
                  alt="Fingerprints Future"
                  className="img-fluid"
                  src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/flyet-cover-2x.png"
                />
                <h3>Flyet</h3>
                <p>Web App</p>
              </div>
              <div className="col-lg-6 col-md-6 home-work-child workanimation">
                <a
                  className="crypto-wallet-link"
                  href="/work/crypto-wallet"></a>
                <img
                  alt="Fingerprints Future"
                  className="img-fluid"
                  src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/crypto-cover-2x.png"
                />
                <h3>Crypto Wallet</h3>
                <p>Product Design</p>
              </div>
              <div className="col-lg-6 col-md-6 home-work-child workanimation">
                <a className="authorcafe-link" href="/work/authorcafe"></a>
                <img
                  alt="Fingerprints Future"
                  className="img-fluid"
                  src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/authorcafe-thumbx2.png"
                />
                <h3>Authorcafe</h3>
                <p>Website Design & Development</p>
              </div>
              <div className="col-lg-6 col-md-6 home-work-child workanimation">
                <a className="fut-bank-link" href="/work/fut-bank"></a>
                <img
                  alt="Fingerprints Future"
                  className="img-fluid"
                  src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/futx2.png"
                />
                <h3>Fut Bank</h3>
                <p>Product Design</p>
              </div>
              <div className="col-lg-6 col-md-6  home-work-child workanimation">
                <a
                  className="dealsplus-website-link"
                  href="/work/dealsplus-website"></a>
                <img
                  alt="Fingerprints Future"
                  className="img-fluid"
                  src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/dealsplus-website-cover-img-nw.png"
                />
                <h3>Deals Plus</h3>
                <p>Website Design & Development</p>
              </div>
              <div className="col-lg-6 col-md-6 home-work-child workanimation">
                <a
                  className="dealsplus-product-link"
                  href="/work/dealsplus-product"></a>
                <img
                  alt="Fingerprints Future"
                  className="img-fluid"
                  src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/dealsplus-thumbx2.png"
                />
                <h3>Deals Plus</h3>
                <p>Product Design</p>
              </div>
              <div className="col-lg-6 col-md-6 home-work-child workanimation">
                <a className="e-wallet-link" href="/work/e-wallet"></a>
                <img
                  alt="Fingerprints Future"
                  className="img-fluid"
                  src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/ewallet2x2.png"
                />
                <h3>E-Wallet</h3>
                <p>Mobile App</p>
              </div>
              <div className="col-lg-6 col-md-6 home-work-child workanimation">
                <a className="savittr-link" href="/work/savittr"></a>
                <img
                  alt="Fingerprints Future"
                  className="img-fluid"
                  src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/savittrx2.png"
                />
                <h3>Savittr</h3>
                <p>Wordpress Design & Development</p>
              </div>
              <div className="col-lg-6 col-md-6  home-work-child workanimation">
                <a className="sunnybee-link" href="/work/sunnybee"></a>
                <img
                  alt="Fingerprints Future"
                  className="img-fluid"
                  src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/sunnybeex2.png"
                />
                <h3>SunnyBee Market</h3>
                <p>Mobile App</p>
              </div>
            </div>
          </div>
        </div>
        {/* CTA Section */}
        <div className="home-cta v2-home-cta">
          <div className="container">
            <div className="left">
              <div className="home-about-h2">
                <h2 className="line d-none d-lg-block d-md-block">
                  <span className="workanimation">
                    Let's talk about how we can{" "}
                  </span>
                </h2>
                <h2 className="line d-none d-lg-block d-md-block">
                  <span className="workanimation">
                    help you reach your goals.{" "}
                  </span>
                </h2>
                <div className="workanimation d-block d-lg-none d-md-none">
                  <h2>
                    Let's talk about how{" "}
                    <br className="d-block d-lg-none d-md-none" /> we can help
                    you <br className="d-block d-lg-none d-md-none" /> reach
                    your goals.
                  </h2>
                </div>
                <p className="d-none d-lg-block workanimation">
                  Schedule your free 30-minute consultation call with us today.
                </p>
                <p className="d-block d-lg-none workanimation">
                  Schedule your free 30-minute consultation <br />
                  call with us today.
                </p>
              </div>
            </div>
            <div className="right workanimation">
              <a
                href="https://calendly.com/aishwaryadaswani"
                target="_blank"
                className="extra-button">
                Get Started
              </a>
            </div>
          </div>
        </div>
        {/* Clients Section */}
        <div className="home-client">
          <div className="container home-about-inner1">
            <div className="home-about-h2">
              <h2 className="line d-none d-lg-block d-md-block">
                <span className="workanimation">Partner’s talk</span>
              </h2>
              <div className="workanimation d-block d-lg-none d-md-none">
                <h2>Partner’s talk</h2>
              </div>
              <p className="workanimation d-none">
                We're not just about creativity for creativity's sake. Our
                designs <br className="d-none d-lg-block d-md-block" />
                are purposeful, enhancing the user experience and bringing{" "}
                <br className="d-none d-lg-block d-md-block" />
                functionality to the forefront.
              </p>
            </div>
            <div className="desktop-logos d-none d-lg-block">
              <div className="row clientrows">
                {/* <div className="col-lg-2 col-6"><img className="logos workanimation" src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/dc1.png" alt="logos"/></div>
                                <div className="col-lg-2 col-6"><img className="logos workanimation" src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/dc2.png" alt="logos"/></div>
                                <div className="col-lg-2 col-6"><img className="logos workanimation" src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/dc3.png" alt="logos"/></div>
                                <div className="col-lg-2 col-6"><img className="logos workanimation" src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/dc4.png" alt="logos"/></div>
                                <div className="col-lg-2 col-6"><img className="logos workanimation" src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/dc5.png" alt="logos"/></div>
                                <div className="col-lg-2 col-6"></div> */}
                <div className="col-lg-12 col-12">
                  <img
                    className="w-100 workanimation"
                    src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/dclr1.png"
                    alt="logos"
                  />
                </div>
              </div>
              <div className="row clientrows">
                {/* <div className="col-lg-2 col-6"><img className="logos workanimation" src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/dc6.png" alt="logos"/></div>
                                <div className="col-lg-2 col-6"><img className="logos workanimation" src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/dc7.png" alt="logos"/></div>
                                <div className="col-lg-2 col-6"><img className="logos workanimation" src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/dc8.png" alt="logos"/></div>
                                <div className="col-lg-2 col-6"><img className="logos workanimation" src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/dc9.png" alt="logos"/></div>
                                <div className="col-lg-2 col-6"><img className="logos workanimation" src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/dc10.png" alt="logos"/></div>
                                <div className="col-lg-2 col-6"></div> */}
                <div className="col-lg-12 col-12">
                  <img
                    className="w-100 workanimation"
                    src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/dclr2.png"
                    alt="logos"
                  />
                </div>
              </div>
              <div className="row clientrows">
                {/* <div className="col-lg-2 col-6"><img className="logos workanimation" src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/dc11.png" alt="logos"/></div>
                                <div className="col-lg-2 col-6"><img className="logos workanimation" src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/dc12.png" alt="logos"/></div>
                                <div className="col-lg-2 col-6"><img className="logos workanimation" src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/dc13.png" alt="logos"/></div>
                                <div className="col-lg-2 col-6"><img className="logos workanimation" src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/dc14.png" alt="logos"/></div>
                                <div className="col-lg-2 col-6"><img className="logos workanimation" src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/dc15.png" alt="logos"/></div>
                                <div className="col-lg-2 col-6"></div> */}
                <div className="col-lg-12 col-12">
                  <img
                    className="w-100 workanimation"
                    src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/dclr3.png"
                    alt="logos"
                  />
                </div>
              </div>
              <div className="row clientrows">
                {/* <div className="col-lg-2 col-6"><img className="logos workanimation" src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/dc16.png" alt="logos"/></div>
                                <div className="col-lg-2 col-6"><img className="logos workanimation" src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/dc17.png" alt="logos"/></div>
                                <div className="col-lg-2 col-6"></div>
                                <div className="col-lg-2 col-6"></div>
                                <div className="col-lg-2 col-6"></div>
                                <div className="col-lg-2 col-6"></div> */}
                <div className="col-lg-12 col-12">
                  <img
                    className="w-100 workanimation"
                    src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/dclr4.png"
                    alt="logos"
                  />
                </div>
              </div>
            </div>
            <div className="mobile-logos d-block d-lg-none d-md-block">
              <div className="row">
                <div className="col-6">
                  <img
                    className="img-fluid mc"
                    src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/mcl1.png"
                    alt="logos"
                  />
                </div>
                <div className="col-6">
                  <img
                    className="img-fluid mc"
                    src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/mcl2.png"
                    alt="logos"
                  />
                </div>
                <div className="col-6">
                  <img
                    className="img-fluid mc"
                    src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/mcl3.png"
                    alt="logos"
                  />
                </div>
                <div className="col-6">
                  <img
                    className="img-fluid mc"
                    src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/mcl4.png"
                    alt="logos"
                  />
                </div>
                <div className="col-6">
                  <img
                    className="img-fluid mc"
                    src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/mcl5.png"
                    alt="logos"
                  />
                </div>
                <div className="col-6">
                  <img
                    className="img-fluid mc"
                    src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/mcl6.png"
                    alt="logos"
                  />
                </div>
                <div className="col-6">
                  <img
                    className="img-fluid mc"
                    src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/mcl7.png"
                    alt="logos"
                  />
                </div>
                <div className="col-6">
                  <img
                    className="img-fluid mc"
                    src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/mcl8.png"
                    alt="logos"
                  />
                </div>
                <div className="col-6">
                  <img
                    className="img-fluid mc"
                    src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/mcl9.png"
                    alt="logos"
                  />
                </div>
                <div className="col-6">
                  <img
                    className="img-fluid mc"
                    src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/mcl10.png"
                    alt="logos"
                  />
                </div>
                <div className="col-6">
                  <img
                    className="img-fluid mc"
                    src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/mcl11.png"
                    alt="logos"
                  />
                </div>
                <div className="col-6">
                  <img
                    className="img-fluid mc"
                    src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/mcl12.png"
                    alt="logos"
                  />
                </div>
                <div className="col-6">
                  <img
                    className="img-fluid mc"
                    src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/mcl13.png"
                    alt="logos"
                  />
                </div>
                <div className="col-6">
                  <img
                    className="img-fluid mc"
                    src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/mcl14.png"
                    alt="logos"
                  />
                </div>
                <div className="col-6">
                  <img
                    className="img-fluid mc"
                    src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/mcl15.png"
                    alt="logos"
                  />
                </div>
                <div className="col-6">
                  <img
                    className="img-fluid mc"
                    src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/mcl16.png"
                    alt="logos"
                  />
                </div>
                <div className="col-6">
                  <img
                    className="img-fluid mc"
                    src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/mcl17.png"
                    alt="logos"
                  />
                </div>
                <div className="col-6">
                  <img
                    className="img-fluid mc"
                    src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/mcl18.png"
                    alt="logos"
                  />
                </div>
                <div className="col-6">
                  <img
                    className="img-fluid mc"
                    src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/mcl19.png"
                    alt="logos"
                  />
                </div>
                <div className="col-6">
                  <img
                    className="img-fluid mc"
                    src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/mcl20.png"
                    alt="logos"
                  />
                </div>
              </div>
              {/* <div className="collapse" id="collapseExample">
                            <div className="row">
                            </div>
                        </div>
                        <a data-bs-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample" className="extra-button plop">View More</a> */}
            </div>
          </div>
          <div className="container testimonials workanimation">
            <Slider {...testimonialSettings}>
              <div className="home-testimonials-inner">
                <h3>
                  We worked with Fingerprints for a year developing an
                  e-commerce mobile app and website for SunnyBee. They were
                  committed to creating the best user experience, and we're
                  thrilled with the final result. Fingerprints' deep dive into
                  the category, competitor analysis, and design system were
                  invaluable. We're confident the app will be a success.
                </h3>
                <h4>Sriram Sridharan</h4>
                <h5>WayCool Foods and Products</h5>
              </div>
              <div className="home-testimonials-inner">
                <h3>
                  We partnered with Fingerprints for two months to make our
                  website mobile-friendly and showcase our range of products on
                  the app. Their team helped us position ourselves as a
                  tech-first company and build brand awareness. We worked
                  closely together to achieve our goals, and every tweak and
                  modification brought us closer to success. We're thrilled to
                  have a committed partner in Fingerprints.
                </h3>
                <h4>Vikram</h4>
                <h5>GBR Metals</h5>
              </div>
              <div className="home-testimonials-inner">
                <h3>
                  Fingerprints helped us make our website mobile-friendly and
                  develop an app to showcase our product range. Their team
                  positioned us as a tech-first company and helped build brand
                  awareness. Together, we achieved our goals with every
                  modification bringing us closer to success. It was a pleasure
                  working with Fingerprints, and we're excited to continue our
                  partnership.
                </h3>
                <h4>Vishal Bysani</h4>
                <h5>Viveks </h5>
              </div>
            </Slider>
          </div>
        </div>
        {/* FAQ Section */}
        <div className="home-faq v2-home-faq">
          <div className="container home-about-inner1">
            <div className="home-about-h2">
              <h2 className="line d-none d-lg-block d-md-block">
                <span className="workanimation">
                  Some frequently asked questions.{" "}
                </span>
              </h2>
              <div className="workanimation d-block d-lg-none d-md-none">
                <h2>
                  Some frequently <br className="d-block d-lg-none d-md-none" />{" "}
                  asked questions.{" "}
                </h2>
              </div>
            </div>
          </div>
          <div className="container">
            <div class="accordion" id="accordionExample">
              <div class="accordion-item workanimation">
                <h2 class="accordion-header" id="One">
                  <button
                    class="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#accordionOne"
                    aria-expanded="true"
                    aria-controls="accordionOne">
                    What services do you offer?
                  </button>
                </h2>
                <div
                  id="accordionOne"
                  class="accordion-collapse collapse show"
                  aria-labelledby="One"
                  data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    We specialize in a wide range of digital product design
                    services, including website design, mobile app development,
                    product design, MVP development, rapid prototyping, design
                    sprints, design systems, UX consulting, UX audit and
                    testing, and design-to-development services.
                  </div>
                </div>
              </div>
              <div class="accordion-item workanimation">
                <h2 class="accordion-header" id="Two">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#accordionTwo"
                    aria-expanded="false"
                    aria-controls="accordionTwo">
                    How do you ensure the quality of your designs?
                  </button>
                </h2>
                <div
                  id="accordionTwo"
                  class="accordion-collapse collapse"
                  aria-labelledby="Two"
                  data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    We take pride in our rigorous quality assurance process. Our
                    team of experienced designers and developers follows
                    industry best practices, conducts thorough user testing, and
                    iterates based on feedback to deliver high-quality designs
                    that meet and exceed expectations.
                  </div>
                </div>
              </div>
              <div class="accordion-item workanimation">
                <h2 class="accordion-header" id="Three">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#accordionThree"
                    aria-expanded="false"
                    aria-controls="accordionThree">
                    Can you work with clients from different industries?
                  </button>
                </h2>
                <div
                  id="accordionThree"
                  class="accordion-collapse collapse"
                  aria-labelledby="Three"
                  data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    Absolutely! We have experience working with clients across
                    various industries, including startups, scale-ups, mid-sized
                    companies, large enterprises, and more. We adapt our design
                    approach to suit the unique needs and goals of each client.
                  </div>
                </div>
              </div>
              <div class="accordion-item workanimation">
                <h2 class="accordion-header" id="Four">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#accordionFour"
                    aria-expanded="false"
                    aria-controls="accordionFour">
                    How long does the design process take?
                  </button>
                </h2>
                <div
                  id="accordionFour"
                  class="accordion-collapse collapse"
                  aria-labelledby="Four"
                  data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    Our highly optimized design delivery process ensures
                    efficient turnaround times. Depending on the scope and
                    complexity of the project, we aim to deliver results in just
                    a few weeks, allowing you to bring your digital product to
                    market faster.
                  </div>
                </div>
              </div>
              <div class="accordion-item workanimation">
                <h2 class="accordion-header" id="Five">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#accordionFive"
                    aria-expanded="false"
                    aria-controls="accordionFive">
                    What is the “Design Sprint” methodology?
                  </button>
                </h2>
                <div
                  id="accordionFive"
                  class="accordion-collapse collapse"
                  aria-labelledby="Five"
                  data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    “Design Sprint” is a time-boxed, collaborative process that
                    rapidly validates ideas and produces tangible prototypes,
                    minimizing risks and maximizing the effectiveness of the
                    design process.
                  </div>
                </div>
              </div>
              <div class="accordion-item workanimation">
                <h2 class="accordion-header" id="Six">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#accordionSix"
                    aria-expanded="false"
                    aria-controls="accordionSix">
                    How do I get started?
                  </button>
                </h2>
                <div
                  id="accordionSix"
                  class="accordion-collapse collapse"
                  aria-labelledby="Six"
                  data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    Getting started is easy! Simply reach out to us through the
                    contact form on our website or give us a call. Our friendly
                    team will be more than happy to discuss your project,
                    understand your requirements, and provide you with tailored
                    solutions and a roadmap to success.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Home;
