import React, { Component } from "react";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
} from "react-share";
import AuthorIcon from "./../assets/blog-author.png";
import Footer from "../components/footer";
import SocialShare from "../components/socialShare";
import GetStartedCard from "../components/getStartedCard";

class NewblogFuture3DinUiDesign extends Component {
  render() {
    const recentBlogData = [
      {
        title: "Learning from Mario: Using Heuristics in UX /UI Design",
        publishedOn: "May 03, 2023",
        minuteRead: "5",
        imgUrl: "https://ik.imagekit.io/fingerprintsfuture/fps/blog-uh-img.png",
        blogUrl: "/blog/using-heuristics-ui-ux-design",
      },
      {
        title: "The Importance of Designing for usability in UI and UX",
        publishedOn: "Jan 24, 2023",
        minuteRead: "7",
        imgUrl:
          "https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/blog-du-img.png",
        blogUrl: "/blog/the-importance-of-designing-for-usability",
      },
      {
        title:
          "AI Isn't Making Us Dumb: The Positive Impact of Chat GPT and Other Technologies",
        publishedOn: "Apr 03, 2023",
        minuteRead: "3",
        imgUrl:
          "https://ik.imagekit.io/fingerprintsfuture/fps/blog-adn-img.png",
        blogUrl: "/blog/ai-dumb-or-not",
      },
    ];

    return (
      <>
        <div className="blog-temp-parent overflow-hidden v2-blog">
          <style>
            {`.inner-page-btn {
                            display: none;
                        }`}
          </style>
          <div className="home-cover-out"></div>
          <div className="container">
            <div className="row">
              <div className="col-lg-8">
                <div className="blog-temp-hero">
                  <div>
                    <a href="/blog" className="back-btn">
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="5"
                          height="9"
                          viewBox="0 0 5 9"
                          fill="none"
                        >
                          <path
                            d="M4.62012 0.879761L1 4.50014L4.62012 8.12026"
                            stroke="#23262F"
                          />
                        </svg>
                      </span>
                      Back
                    </a>
                  </div>

                  <div className="nwv-blog1-hero">
                    <h1 className="workanimation">
                      From Pixels to Prototypes:{" "}
                      <br className="d-none d-lg-block" />
                      Exploring the Future of 3D in UI Design
                    </h1>
                    <div className="nwv-blog1-hero-adt">
                      <p>12 Minute Read</p>
                      {/* <span></span> */}
                      {/* <img
                        className="img-fluid blog-author-img"
                        src={AuthorIcon}
                        alt="Author"
                      /> */}
                      {/* <p>By Future</p> */}
                      <span></span>
                      {/* <p>Jun 16, 2023</p> */}
                    </div>
                  </div>
                  <div>
                    <p className="workanimation">
                      We’ve come a long way from the flat, minimalistic designs
                      that dominated the early days of digital interfaces.
                      Today, 3D elements are popping up everywhere, adding
                      depth, interaction, and even a sense of realism to web and
                      mobile designs. It’s an exciting time, especially for
                      designers who are looking to push boundaries and create
                      experiences that go beyond static visuals.
                    </p>
                  </div>
                  <br />
                  <div className="main-hero-blog-row">
                    <div className="bth-right-p workanimation">
                      <img
                        alt="Fingerprints Future"
                        src="https://ik.imagekit.io/fingerprintsfuture/fps/future-3d-ui-design-img1.png"
                        className="img-fluid"
                      />
                    </div>
                  </div>
                </div>

                <div className="blog-content">
                  <div className="col-lg-12">
                    <p className="workanimation">
                      I’ve always been fascinated by the world of 3D design.
                      There’s something captivating about how it can make
                      digital elements feel almost tangible, whether you’re
                      interacting with a simple 3D button or navigating a
                      complex virtual product. And 3D isn’t just for video games
                      or animations anymore, it’s working its way into everyday
                      UI/UX design, from e-commerce platforms to educational
                      tools. What makes this shift so interesting is how 3D
                      elements, whether subtle or immersive, can transform a
                      user’s experience, making it more engaging, informative,
                      and, in some cases, even fun.
                    </p>
                    <br />

                    <p className="workanimation">
                      The best part? 3D doesn’t have to be overly complex to
                      make an impact. A simple 3D button with a slight shadow or
                      depth can make an app feel more interactive, while a
                      complex 3D model in an e-commerce platform can help users
                      visualize products more clearly before making a purchase.
                      And as 3D tools and rendering techniques become more
                      accessible, we’re seeing 3D design play a role in fields
                      we wouldn’t normally expect, from pharma and education to
                      shopping and beyond.
                    </p>
                    <br />

                    <p className="workanimation">
                      Let’s dive into how 3D is changing the game across various
                      industries and how these elements are shaping the future
                      of UI design.
                    </p>
                    <br />
                    <br />

                    <div className="txt-gradi">
                      <h3>
                        Simple vs. Complex 3D: How They're Changing UI Design
                      </h3>
                    </div>
                    <br />
                    <div className="blog-du-img3">
                      <img
                        className="img-fluid"
                        src="https://ik.imagekit.io/fingerprintsfuture/fps/future-3d-ui-design-img2.png"
                        alt="blog-img"
                      />
                    </div>
                    <br />
                    <p className="workanimation">
                      When we talk about 3D design in user interfaces, it’s
                      important to understand that not all 3D elements are
                      created equal. There’s a spectrum, from simple 3D that
                      adds subtle enhancements to an interface, to complex 3D
                      that delivers fully immersive, interactive experiences.
                    </p>
                    <br />

                    <div className="">
                      <h3>Simple 3D</h3>
                    </div>
                    <br />
                    <p className="workanimation">
                      Simple 3D often refers to subtle design elements that
                      bring just enough depth and dimension to make a UI feel
                      more interactive and engaging without overwhelming the
                      user. These could be:
                    </p>
                    <br />
                    <ul className="blog-list-items">
                      <li>
                        Buttons with depth that give the illusion of being
                        pressed.
                      </li>
                      <li>
                        Shadows and light effects that create a sense of
                        layering and hierarchy.
                      </li>
                      <li>
                        Small 3D icons or illustrations that add personality to
                        an app or website.
                      </li>
                    </ul>
                    <br />
                    <p className="workanimation">
                      The beauty of simple 3D is that it’s easy to implement
                      without impacting performance, especially on mobile
                      devices. It enhances the user experience by making
                      interactions feel more tactile and satisfying, without
                      being distracting.
                    </p>
                    <br />

                    <div className="">
                      <h3>Complex 3D</h3>
                    </div>
                    <br />
                    <p className="workanimation">
                      On the other end of the spectrum, we have complex 3D
                      elements. These are typically used in product
                      visualizations, virtual tours, or immersive interfaces
                      where users can interact with detailed 3D models. Complex
                      3D involves:
                    </p>
                    <br />
                    <ul className="blog-list-items">
                      <li>
                        Realistic 3D models that users can rotate, zoom into,
                        and explore.
                      </li>
                      <li>
                        3D environments where users can navigate through a
                        virtual space, like in real estate or gaming apps.
                      </li>
                      <li>
                        Detailed animations that simulate how a product works or
                        behaves in the real world.
                      </li>
                    </ul>
                    <br />
                    <p className="workanimation">
                      While complex 3D is visually impressive and engaging, it
                      requires more processing power, time, and technical
                      expertise to build. However, with the rise of 3D
                      prototyping tools, it's becoming more accessible to a
                      wider range of designers and industries.
                    </p>
                    <br />
                    <br />

                    <div className="txt-gradi">
                      <h3>3D Prototyping Across Different Industries</h3>
                    </div>
                    <br />
                    <div className="blog-du-img3">
                      <img
                        className="img-fluid"
                        src="https://ik.imagekit.io/fingerprintsfuture/fps/future-3d-ui-design-img3.png"
                        alt="blog-img"
                      />
                    </div>
                    <br />
                    <p className="workanimation">Now let’s look at how</p>
                    <br />
                    <div className="txt-gradi">
                      <h3>1. Pharma</h3>
                    </div>
                    <br />
                    <p className="workanimation">
                      In the pharmaceutical industry, 3D elements are being used
                      for everything from visualizing molecules to demonstrating
                      how medications interact with the body. Prototyping in
                      this sector allows developers to:
                    </p>
                    <br />
                    <ul className="blog-list-items">
                      <li>
                        Show detailed 3D models of drugs and their mechanisms.
                      </li>
                      <li>
                        Create interactive visualizations of how a drug is
                        delivered or absorbed by the body.
                      </li>
                      <li>
                        Build virtual tours of production processes to improve
                        transparency and trust.
                      </li>
                    </ul>
                    <br />
                    <p className="workanimation">
                      For example, a healthcare app might use 3D to help
                      patients understand how a new treatment will work inside
                      their body, making complex information more digestible.
                    </p>
                    <br />

                    <div className="txt-gradi">
                      <h3>2. Education Technology (EdTech)</h3>
                    </div>
                    <br />
                    <p className="workanimation">
                      In education, 3D design can revolutionize how students
                      learn by making abstract concepts more tangible. From
                      virtual dissections in biology apps to interactive history
                      timelines, 3D prototypes allow:
                    </p>
                    <br />
                    <ul className="blog-list-items">
                      <li>
                        Simulations where students can manipulate 3D models to
                        better understand scientific concepts.
                      </li>
                      <li>
                        Virtual learning environments that create immersive
                        educational experiences.
                      </li>
                      <li>
                        Interactive books or materials where students can
                        explore detailed 3D renderings of historical sites or
                        scientific phenomena.
                      </li>
                    </ul>
                    <br />
                    <p className="workanimation">
                      This transforms passive learning into an active, hands-on
                      experience that can deepen understanding.
                    </p>
                    <br />

                    <div className="txt-gradi">
                      <h3>3. E-Commerce and Retail</h3>
                    </div>
                    <br />
                    <p className="workanimation">
                      One of the most exciting uses of 3D is in e-commerce,
                      where 3D prototyping allows customers to interact with
                      products in ways that were never possible with just photos
                      or videos. Retailers can now:
                    </p>
                    <br />
                    <ul className="blog-list-items">
                      <li>
                        Show detailed 3D product models that users can rotate,
                        zoom, and explore from all angles.
                      </li>
                      <li>
                        Use virtual try-on features for clothes, accessories, or
                        makeup to give users a better idea of how products will
                        look on them.
                      </li>
                      <li>
                        Enable augmented reality (AR) shopping experiences where
                        users can place 3D models of furniture in their homes to
                        see how it fits.
                      </li>
                    </ul>
                    <br />
                    <p className="workanimation">
                      This kind of immersive shopping experience can reduce
                      returns and boost customer confidence in online purchases.
                    </p>
                    <br />

                    <div className="txt-gradi">
                      <h3>4. Architecture and Real Estate</h3>
                    </div>
                    <br />
                    <p className="workanimation">
                      In architecture and real estate, 3D models are already a
                      huge part of how spaces are presented. 3D prototyping
                      allows users to:
                    </p>
                    <br />
                    <ul className="blog-list-items">
                      <li>
                        Take virtual tours of properties before they’re built or
                        during the buying process.
                      </li>
                      <li>
                        Interact with 3D floor plans that let users explore
                        layouts, zoom into details, and even customize aspects
                        of the design.
                      </li>
                      <li>
                        Use augmented reality to see how a building will look in
                        its actual surroundings.
                      </li>
                    </ul>
                    <br />
                    <p className="workanimation">
                      This adds a layer of interactivity that helps clients
                      better understand what a space will feel like, making
                      decisions easier and faster.
                    </p>
                    <br />

                    <div className="txt-gradi">
                      <h3>5. Fashion and Beauty</h3>
                    </div>
                    <br />
                    <p className="workanimation">
                      The fashion and beauty industry has embraced 3D
                      prototyping in a big way, allowing customers to interact
                      with products before buying and brands to showcase designs
                      in innovative ways.
                    </p>
                    <br />
                    <ul className="blog-list-items">
                      <li>
                        Virtual Try-Ons: In beauty apps, 3D models allow users
                        to virtually try on makeup products like lipstick,
                        foundation, or eyeshadow in real time. Similarly,
                        fashion apps can offer virtual try-ons for clothing,
                        letting users see how outfits fit and drape before
                        making a purchase.
                      </li>
                      <li>
                        3D Fashion Previews: Designers can showcase their
                        collections using 3D fashion models, offering detailed
                        views of fabrics, textures, and fits that are hard to
                        capture in 2D images.
                      </li>
                      <li>
                        Interactive Customization: Fashion brands can allow
                        users to customize clothing or accessories in real-time
                        using 3D tools, adjusting colors, materials, and
                        patterns to create a personalized shopping experience.
                      </li>
                    </ul>
                    <br />
                    <p className="workanimation">
                      <strong>
                        This use of 3D makes the fashion and beauty experience
                        more engaging, giving customers the confidence to make
                        purchasing decisions online.
                      </strong>
                    </p>
                    <br />

                    <div className="txt-gradi">
                      <h3>6. Automotive Industry</h3>
                    </div>
                    <br />
                    <p className="workanimation">
                      For the automotive industry, 3D models and prototyping are
                      key for both design and marketing. Car manufacturers use
                      3D to:
                    </p>
                    <br />
                    <ul className="blog-list-items">
                      <li>
                        Show potential customers detailed views of vehicle
                        interiors and exteriors that they can rotate and explore
                        online.
                      </li>
                      <li>
                        Allow users to customize cars in real-time, seeing how
                        different colors, wheels, and features change the
                        overall look.
                      </li>
                      <li>
                        Simulate test drives using virtual reality (VR) to give
                        potential buyers a feel for the vehicle’s performance.
                      </li>
                    </ul>
                    <br />
                    <br />

                    <div className="txt-gradi">
                      <h3>
                        3D Design: Beauty Comes at a Cost—Here’s What You Need
                        to Know
                      </h3>
                    </div>
                    <br />
                    <div className="blog-du-img3">
                      <img
                        className="img-fluid"
                        src="https://ik.imagekit.io/fingerprintsfuture/fps/future-3d-ui-design-img4.png"
                        alt="blog-img"
                      />
                    </div>
                    <br />
                    <p className="workanimation">
                      We get asked this question all the time: “We want our
                      website to look fantastic, but we don’t want to spend too
                      much on 3D.” And here’s the truth,{" "}
                      <strong>beauty comes at a cost.</strong> Yes, 3D design
                      can transform your website, but it’s also an investment,
                      not a quick fix. Whether you’re going for{" "}
                      <strong>simple 3D enhancements</strong> or{" "}
                      <strong>complex 3D models,</strong> you’ll need to factor
                      in costs upfront. Think of it as an investment in creating
                      an experience that will engage your users, boost
                      conversions, and make your brand stand out.
                    </p>
                    <br />
                    <p className="workanimation">
                      Let’s break down the costs for 3D design in both{" "}
                      <strong>INR</strong> and <strong>USD,</strong> so you know
                      exactly what you’re getting into.
                    </p>
                    <br />

                    <div className="txt-gradi">
                      <h3>Simple 3D: Subtle, but Effective</h3>
                    </div>
                    <br />
                    <p className="workanimation">
                      Simple 3D elements can make your interface feel more
                      interactive and polished without being too heavy on
                      performance, or your budget. We’re talking about:
                    </p>
                    <br />
                    <ul className="blog-list-items">
                      <li>
                        <strong>3D buttons with depth</strong> that add tactile
                        feedback.
                      </li>
                      <li>
                        <strong>Light effects</strong> and{" "}
                        <strong>shadows</strong> to create a sense of layering.
                      </li>
                      <li>
                        Small <strong>3D icons</strong> or simple product
                        mockups.
                      </li>
                    </ul>
                    <br />

                    <div className="">
                      <h3>Cost in INR:</h3>
                    </div>
                    <br />
                    <ul className="blog-list-items">
                      <li>
                        For basic 3D elements like buttons, icons, or minor
                        animations, expect to spend between{" "}
                        <strong>₹20,000 and ₹50,000</strong>(about{" "}
                        <strong>$250 to $600</strong>). This would cover the
                        creation of small, subtle 3D enhancements that make your
                        website look clean and modern.
                      </li>
                    </ul>
                    <br />

                    <div className="">
                      <h3>Specific Use Cases:</h3>
                    </div>
                    <br />
                    <ul className="blog-list-items">
                      <li>
                        <strong>E-commerce websites</strong> adding 3D buttons
                        to enhance interaction or{" "}
                        <strong>real estate platforms</strong> using basic 3D
                        floor plans to create depth without full-blown virtual
                        tours.
                      </li>
                    </ul>
                    <br />

                    <div className="txt-gradi">
                      <h3>Complex 3D: Immersive and Impactful</h3>
                    </div>
                    <br />
                    <p className="workanimation">
                      Now, if you want to go all-in with complex 3D, you’re
                      looking at higher costs,but the payoff is substantial.
                      Complex 3D could include:
                    </p>
                    <br />
                    <ul className="blog-list-items">
                      <li>
                        <strong>Detailed product visualizations</strong> where
                        users can rotate, zoom, and interact with the model.
                      </li>
                      <li>
                        <strong>3D environments</strong> where users can take
                        virtual tours (think real estate, automotive, or even
                        education).
                      </li>
                      <li>
                        <strong>Advanced animations</strong> that showcase how a
                        product works in real time.
                      </li>
                    </ul>
                    <br />

                    <div className="">
                      <h3>Cost in INR:</h3>
                    </div>
                    <br />
                    <ul className="blog-list-items">
                      <li>
                        For complex 3D models, you’re looking at anywhere from{" "}
                        <strong>₹1,50,000 to ₹5,00,000</strong> (roughly{" "}
                        <strong>$1,800 to $6,000</strong>). This includes
                        high-quality, interactive 3D that users can fully engage
                        with.
                      </li>
                    </ul>
                    <br />

                    <div className="">
                      <h3>Specific Use Cases:</h3>
                    </div>
                    <br />
                    <ul className="blog-list-items">
                      <li>
                        <strong>Pharma</strong> companies showcasing how a drug
                        interacts with the body through detailed 3D models.
                      </li>
                      <li>
                        <strong>E-commerce</strong> brands offer{" "}
                        <strong>virtual try-on</strong> experiences for fashion
                        or beauty products, letting users see how clothing fits
                        or makeup looks in real time.
                      </li>
                      <li>
                        <strong>Real estate</strong> companies offer{" "}
                        <strong>immersive virtual tours</strong> of properties,
                        allowing potential buyers to explore every corner before
                        even visiting.
                      </li>
                    </ul>
                    <br />

                    <div className="blog-du-img3">
                      <img
                        className="img-fluid"
                        src="https://ik.imagekit.io/fingerprintsfuture/fps/future-3d-ui-design-img6.png"
                        alt="blog-img"
                      />
                    </div>
                    <br />

                    <div className="txt-gradi">
                      <h3>3D for Products: Showcasing What You Sell</h3>
                    </div>
                    <br />
                    <p className="workanimation">
                      In industries like e-commerce, furniture, and electronics,
                      3D models of products can elevate the user experience by
                      letting customers interact with products virtually. They
                      can rotate, zoom in, and explore details they wouldn’t get
                      from static images.
                    </p>
                    <br />

                    <div className="">
                      <h3>Cost in INR:</h3>
                    </div>
                    <br />
                    <ul className="blog-list-items">
                      <li>
                        For interactive 3D product models, expect costs between
                        <strong>₹50,000 and ₹2,00,000</strong> (about{" "}
                        <strong>$600 to $2,500</strong>), depending on the
                        complexity of the product and the level of interactivity
                        required.
                      </li>
                    </ul>
                    <br />

                    <div className="">
                      <h3>Specific Use Cases:</h3>
                    </div>
                    <br />
                    <ul className="blog-list-items">
                      <li>
                        <strong>Furniture companies</strong> offer 3D models so
                        users can see how a chair or table would look in their
                        living space, and even customize color or material.
                      </li>
                      <li>
                        <strong>Tech and electronics brands</strong> showcasing
                        products like smartphones or gadgets, allowing users to
                        explore features in detail through 3D.
                      </li>
                    </ul>
                    <br />

                    <div className="txt-gradi">
                      <h3>
                        3D for Manufacturing Equipment: Industrial Use Cases
                      </h3>
                    </div>
                    <br />
                    <p className="workanimation">
                      In the manufacturing and engineering industries, 3D models
                      of equipment and machinery provide an in-depth look at
                      complex systems. These models can be used for product
                      demonstrations, training, or even showcasing equipment to
                      potential buyers.
                    </p>
                    <br />

                    <div className="">
                      <h3>Cost in INR:</h3>
                    </div>
                    <br />
                    <ul className="blog-list-items">
                      <li>
                        For 3D models of manufacturing equipment, you’re looking
                        at anywhere from <strong>₹1,00,000 to ₹4,00,000</strong>{" "}
                        (roughly <strong>$1,200 to $5,000</strong>), depending
                        on the complexity and scale of the machinery.
                      </li>
                    </ul>
                    <br />

                    <div className="">
                      <h3>Specific Use Cases:</h3>
                    </div>
                    <br />
                    <ul className="blog-list-items">
                      <li>
                        <strong>Engineering firms</strong> using 3D models to
                        show how machinery operates in a production line.
                      </li>
                      <li>
                        <strong>Manufacturing companies</strong> showcasing
                        their equipment to clients through virtual
                        presentations, allowing for an interactive experience
                        that goes beyond photos and videos.
                      </li>
                    </ul>
                    <br />

                    <div className="txt-gradi">
                      <h3>Why You Should See This as an Investment</h3>
                    </div>
                    <br />
                    <div className="blog-du-img3">
                      <img
                        className="img-fluid"
                        src="https://ik.imagekit.io/fingerprintsfuture/fps/future-3d-ui-design-img5.png"
                        alt="blog-img"
                      />
                    </div>
                    <br />
                    <p className="workanimation">
                      Here’s the thing:{" "}
                      <strong>
                        3D design isn’t just about making your website look
                        fancy.
                      </strong>{" "}
                      It’s about <strong>enhancing user experience</strong>
                      and <strong>driving engagement,</strong> which ultimately
                      leads to higher
                      <strong>conversion rates.</strong> Users love interacting
                      with products they can manipulate and explore, and in
                      sectors like
                      <strong>fashion, real estate, and e-commerce,</strong> 3D
                      elements can mean the difference between a sale and a
                      bounce.
                    </p>
                    <br />
                    <p className="workanimation">
                      Think of it this way: you wouldn’t compromise on the
                      aesthetics of your storefront or showroom, right? Your
                      website or app is no different. Whether it’s a simple 3D
                      enhancement or an immersive virtual experience, investing
                      in 3D will{" "}
                      <strong>
                        boost your brand’s credibility, increase user
                        satisfaction, and drive more conversions.
                      </strong>
                    </p>
                    <br />
                    <p className="workanimation">
                      So, if you’re looking to take your website to the next
                      level, understand that{" "}
                      <strong>beauty comes at a cost,</strong> but it’s a cost
                      that’s worth every penny (or rupee) when you see the
                      results.
                    </p>
                    <br />
                    <br />

                    <p className="workanimation">
                      <strong>Stay Invested!</strong>
                    </p>
                    <p className="workanimation">
                      <strong>Stay Curious!</strong>
                    </p>
                    <br />
                    <br />
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="v2-blog-sidebar">
                  <div className="v2-blog-sidebar-cont">
                    <div className="v2-blog-sidebar-tags">
                      <h4>Tags</h4>
                      <div className="">
                        <span className="">UI design</span>
                        <span className="">UX DESIGN</span>
                        <span className="">ARTIFICIAL INTELLIGENCE</span>
                        <span className="">WEB DEVELOPMENT</span>
                        <span className="">FRONTEND DEVELOPMENT</span>
                        <span className="">USER INTERFACE</span>
                        <span className="">USER EXPERIENCE</span>
                        <span className="">RESPONSIVE DESIGN</span>
                      </div>
                    </div>

                    <div className="v2-blog-sidebar-posts">
                      <h4>POPULAR POSTS</h4>
                      <div className="all-posts">
                        {recentBlogData.map((blog, index) => (
                          <a
                            href={blog.blogUrl}
                            key={index}
                            className="post-container"
                          >
                            <img src={blog.imgUrl} alt="post-banner" />
                            <p>
                              {blog.title}
                              <br />
                              {/* <span>{blog.publishedOn}</span> */}
                              <span>{blog.minuteRead} Minute read</span>
                            </p>
                          </a>
                        ))}
                      </div>
                    </div>

                    <div className="v2-blog-sidebar-social">
                      <h4>SHARE</h4>
                      <div className="">
                        <div className="socials-container">
                          <FacebookShareButton
                            url={
                              "https://fingerprintsfuture.com/blog/exploring-the-future-of-3d-in-ui-design"
                            }
                            description={
                              "5G’s Impact on UI/UX: Designing for Lightning-Fast Speeds"
                            }
                            className="Demo__some-network__share-button"
                          >
                            <FacebookIcon size={36.67} round />
                          </FacebookShareButton>
                          <TwitterShareButton
                            title={
                              "5G’s Impact on UI/UX: Designing for Lightning-Fast Speeds"
                            }
                            url={
                              "https://fingerprintsfuture.com/blog/exploring-the-future-of-3d-in-ui-design"
                            }
                          >
                            <TwitterIcon size={36.67} round />
                          </TwitterShareButton>
                          <LinkedinShareButton
                            title={
                              "5G’s Impact on UI/UX: Designing for Lightning-Fast Speeds"
                            }
                            url={
                              "https://fingerprintsfuture.com/blog/exploring-the-future-of-3d-in-ui-design"
                            }
                          >
                            <LinkedinIcon size={36.67} round />
                          </LinkedinShareButton>
                        </div>
                      </div>
                    </div>
                    <div className="">
                      <GetStartedCard />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

export default NewblogFuture3DinUiDesign;
