import React, { Component } from 'react'
import Footer from '../components/footer'
import {Helmet} from "react-helmet";
import gsap from 'gsap'
import SplitText from 'gsap/SplitText'
import ScrollTrigger from 'gsap/ScrollTrigger'

export default class Flyet extends Component {

    projectGsap = () =>{
        gsap.registerPlugin(ScrollTrigger, SplitText)

        //Hero

        // gsap.from(".normal-header", 0.9, {
        //     opacity: 0,
        //     ease: "Power3.easeOut",
        //     delay: 3.5,
        //   })
          gsap.from("h2.line span", 0.9, {
            y: 180,
            rotateZ: 6,
            ease: "Power3.easeOut",
            delay: 0.7,
            stagger: {
              amount: 0.2
            }
          })
          gsap.from(".mobile-sunnybee-h4", 0.9, {
            y: 40,
            opacity: 0,
            ease: "Power3.easeOut",
            delay: 1.5,
            stagger: {
              amount: 0.2
            }
          })
          gsap.set(".sunnybee-hero", {height: "100vh"});
          gsap.to(".sunnybee-hero", {
            height: "80vh",
            delay: 1,
            ease: "Power3.easeOut",
            duration: 1
          });
          gsap.set(".sunnybee-hero-listitem li", { visibility: "hidden" });
          ScrollTrigger.batch(".sunnybee-hero-listitem li", {
              onEnter: batch => {
                  gsap.from(batch, {
                      duration: 1,
                      opacity: 0,
                      delay: 1.2,
                      yPercent: 100,
                      scale: 1,
                      autoAlpha: 0,
                      ease: "Power3.easeOut",
                      stagger: 0.1,
                      visibility: "visible",
                  })
              },
              toggleActions: "play none none none",
              once: true,
              markers: false,
          });
          gsap.set(".sunnybee-cover", {opacity: 1});
          gsap.to(".sunnybee-cover", {
            opacity: 0,
            delay: 0,
            ease: "Power3.easeIn",
            duration: 0.3
          });
          gsap.set(".sunnybee-heading", {opacity: 0});
          gsap.to(".sunnybee-heading", {
            opacity: 1,
            delay: 0.1,
            ease: "Power3.easeIn",
            duration: 1
          });
          gsap.set(".sunnybee-hero-cont", {"margin-bottom": 0});
          gsap.to(".sunnybee-hero-cont", {
            "margin-bottom": 90,
            delay: 1,
            ease: "Power3.linear",
            duration: 1.5
          });
          gsap.set(".our-approach-gra", {xPercent: 100});
          gsap.to(".our-approach-gra", {
            xPercent: 0,
            delay: 2,
            ease: "Power3.easeOut",
            duration: 1.5
          });
          gsap.set(".project-text", { visibility: "hidden" });
          ScrollTrigger.batch(".project-text", {
              onEnter: batch => {
                  gsap.from(batch, {
                      duration: 1.5,
                      delay: 0,
                      yPercent: 50,
                      scale: 1,
                      autoAlpha: 0,
                      ease: "Power3.easeOut",
                      stagger: 0.4,
                      visibility: "visible",
                  })
              },
              toggleActions: "play none none none",
              once: true,
              start: 'top 80%',
              end: 'top 40%',
              markers: false,
          });

          gsap.set(".bdr-top", {width: "0%"});
          gsap.to(".bdr-top", {
            width: "100%",
            ease: "Power3.easeOut",
            duration: 1,
            scrollTrigger:{
                trigger: ".bdr-top",
                start: "top 80%"
            }
          });



    }
    

    componentDidMount(){
        this.projectGsap();
        
    }

    render() {
        return (
            <>

            <div className="home-cover-out"></div>
            <div className='overflow-hidden'>
                <div className='sunnybee-cover'></div>
                {/* Savittr Hero */}
                <div className='sunnybee-hero'>
                    {/* <div className="our-approach-gra"></div> */}
                    <div className='container sunnybee-hero-cont'>
                        <div className='row'>
                            <div className='col-lg-9'>
                                <div>
                                    <h2 className='sunnybee-heading'>Flyet</h2>
                                </div>
                                <div className='d-none d-lg-block d-md-block'>
                                    <div>
                                        <h2 className='line'><span>Creating a Seamless Travel </span></h2>
                                    </div>
                                    <div>
                                        <h2 className='line'><span>Booking Experience </span></h2>
                                    </div>
                                    <div>
                                        <h2 className='line'><span></span></h2>
                                    </div>
                                </div>
                                <div className='d-block d-lg-none d-md-none'>
                                    <h4 className='mobile-sunnybee-h4'>Creating a Seamless Travel Booking Experience</h4>
                                </div>
                            </div>
                            <div className='col-lg-3 sunnybee-hero-listitem'>
                                <ul>
                                    <li>USER RESEARCH</li>
                                    <li>USER INTERFACE DESIGN (UI)</li>
                                    <li>USER EXPERIENCE DESIGN (UX)</li>
                                    <li>Rapid Prototyping</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Savittr Hero End */}

                <div className='container sunnybee-video1 hide-nav '>
                    <img alt="Fingerprints Future" className='img-fluid d-none d-lg-block' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/tb-img-1.png"  />
                    <img alt="Fingerprints Future" className='img-fluid d-block d-lg-none' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/tb-img-resp1.png"  />
                    {/* <img alt="Fingerprints Future" className='img-fluid d-lg-none d-block ' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/ewallet-img-1-m2.png"  /> */}
                    {/* <video src={Video1} muted autoPlay={"autoplay"} preload="auto" loop playsInline controlsList="nodownload" /> */}
                </div>

                {/* Savittr About */}
                <div className='sunnybee-about'>
                    <div className="d-none d-lg-block sunnybee-gra1"></div>
                    <div className="d-block d-lg-none sunnybee-gra1-reps"></div>
                    <div className='container sunnybee-about-cont'>
                        <div className='row'>
                            <div className='col-lg-1 d-lg-block d-md-none'></div>
                            <div className='col-lg-5 col-md-6'>
                                <div>
                                    <h4 className='project-text'>What went into the</h4>
                                </div>
                                <div>
                                    <h4 className='project-text'>creation of the app?</h4>
                                </div>
                            </div>
                            <div className='col-lg-5 col-md-6'>
                                <div>
                                    <p className='project-text'>To create a travel booking web application that truly catered to the needs of users, our team conducted extensive research and analysis of user needs and pain points. We carefully considered factors such as the booking process, navigation, user preferences, and common issues encountered by travelers.</p>
                                    <p className='project-text'>One of our key priorities was to create an all-in-one solution that would allow users to easily book flights, hotels, and car rentals on a single platform. This streamlines the booking process, making it more convenient and efficient for users.We also prioritized the design of an intuitive and user-friendly interface, which would enable users to easily navigate and interact with the application.</p>
                                </div>
                            </div>
                            <div className='col-lg-1 d-lg-block d-md-none'></div>
                        </div>
                    </div>
                </div>
                {/* Savittr About End */}

                <div className='container savittr-img3-4'>
                    <div className='row'>
                        <div className='col-lg-6 col-md-6 savittr-video3'>
                            {/* <img alt="Fingerprints Future" className='img-fluid img3' src={Img3} /> */}
                            <video className='project-text' src="https://storage.googleapis.com/fingerprintsfuturewebsite/videos/flyet-video-1.mp4" muted autoPlay={"autoplay"} preload="auto" loop playsInline controlsList="nodownload" />
                        </div>
                        <div className='col-lg-6 col-md-6 savittr-video2'>
                            {/* <img alt="Fingerprints Future" className='img-fluid' src={Img4} /> */}
                            <video className='project-text' src="https://storage.googleapis.com/fingerprintsfuturewebsite/videos/flyet-video-2.mp4" muted autoPlay={"autoplay"} preload="auto" loop playsInline controlsList="nodownload" />
                        </div>
                    </div>
                </div>

                <div className='container futbank-rd ew-resp'>
                    <div className='row savittr-creative-colr'>
                        <div className='col-lg-1 col-md-1'></div>
                        <div className='col-lg-4 col-md-5 align-self-center'>
                            <h4 className='project-text'>Simplified Flight Selection</h4>
                            <p className='project-text'>Selecting a flight from the flights result page is a critical step in the travel booking process, which is why we prioritized a seamless experience for this feature. By designing an intuitive and clean interface that allows users to easily filter and sort flight options based on their preferences.</p>
                        </div>
                        <div className='col-lg-1 col-md-1'></div>
                        <div className='col-lg-5 col-md-5 futbank-img6 resp-img-width'>
                            <img alt="Fingerprints Future" className='img-fluid project-text' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/tb-img-4.png" />
                        </div>
                        <div className='col-lg-1'></div>
                    </div>
                </div>

                <div className='container sunnybee-about-img'>
                    <div className='row authorcafe-video-img'>
                        <div className='col-lg-12 authorcafe-img6'>
                            <img className='img-fluid project-text d-none d-lg-block' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/tb-img-5.png"alt="Future" />
                            <img className='img-fluid project-text d-block d-lg-none' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/tb-img-resp2.png"alt="Future" />
                            {/* <img className='img-fluid project-text d-block d-lg-none' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/ewallet-img-5-m2.png" alt="Future" /> */}
                        </div>
                    </div>
                </div>

                <div className='container futbank-rd ew-resp'>
                    <div className='row'>
                        <div className='col-lg-1'></div>
                        <div className='col-lg-5 col-md-5 futbank-img8 resp-img-width'>
                            <img alt="Fingerprints Future" className='img-fluid project-text' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/tb-img-6.png" />
                        </div>
                        <div className='col-lg-1 col-md-1'></div>
                        <div className='col-lg-4 col-md-5 align-self-center'>
                            <h4 className='project-text'>Smarter Travel Planning</h4>
                            <p className='project-text'>Our travel booking app provides personalized travel recommendations to users based on their preferences and past bookings. By utilizing data analysis and machine learning algorithms, we are able to deliver customised travel suggestions that are tailored to the user's unique travel history, interests, and preferences.</p>
                            <p className='project-text'>This feature not only helps users discover new and exciting destinations that they may have otherwise overlooked, but it also streamlines the booking process by providing relevant and targeted recommendations.</p>
                        </div>
                        <div className='col-lg-1 col-md-1'></div>
                    </div>
                </div>

                <div className='tb-bg-sec'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-2'></div>
                            <div className='col-lg-8'>
                                <img alt="Fingerprints Future" className='img-fluid project-text' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/tb-img-7.png" />
                            </div>
                            <div className='col-lg-2'></div>
                        </div>
                    </div>
                    <div className='container sunnybee-about-cont txt-white'>
                        <div className='row'>
                            <div className='col-lg-2 d-lg-block d-md-none'></div>
                            <div className='col-lg-4 col-md-6'>
                                <div className='d-none d-lg-block d-md-block'>
                                    <h4 className='project-text'>A Scalable</h4>
                                </div>
                                <div className='d-none d-lg-block d-md-block'>
                                    <h4 className='project-text'>Design System</h4>
                                </div>
                                <div className='d-block d-lg-none d-md-none'>
                                    <h4 className='project-text'>A Scalable Design System</h4>
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-6'>
                                <div>
                                    <p className='project-text'>At the heart of our travel booking app is a robust design system that ensures a consistent user experience across all devices. We understand that users may access the app from various devices, which is why we focused on building a system that is both flexible and scalable.</p>
                                    <p className='project-text'>To achieve this, we created a suite of custom UI components that are designed to be modular and reusable, providing a consistent look and feel across the entire app. This ensures that users can easily navigate the app, regardless of the device they are using.</p>
                                </div>
                            </div>
                            <div className='col-lg-2 d-lg-block d-md-none'></div>
                        </div>
                    </div>
                </div>

                <div className='container tb-img-8'>
                    <img alt="Fingerprints Future" className='img-fluid project-text' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/tb-img-8-1.png"  />
                </div>

                <div className='sunnybee-about'>
                    <div className="d-none d-lg-block sunnybee-gra1"></div>
                    <div className="d-block d-lg-none sunnybee-gra1-reps"></div>
                    <div className='container sunnybee-about-cont'>
                        <div className='row'>
                            <div className='col-lg-1 d-lg-block d-md-none'></div>
                            <div className='col-lg-5 col-md-6'>
                                <div className='d-none d-lg-block d-md-block'>
                                    <h4 className='project-text'>Seamless Across</h4>
                                </div>
                                <div className='d-none d-lg-block d-md-block'>
                                    <h4 className='project-text'>All Devices</h4>
                                </div>
                                <div className='d-block d-lg-none d-md-none'>
                                    <h4 className='project-text'>Seamless Across All Devices</h4>
                                </div>
                            </div>
                            <div className='col-lg-5 col-md-6'>
                                <div>
                                    <p className='project-text'>Our travel booking web application is designed to work seamlessly across all devices, including desktops, laptops, tablets, and smartphones. This is achieved through a responsive design approach that ensures the app's layout and functionality adapt to fit the user's device.</p>
                                    <p className='project-text'>Regardless of the device a user is accessing the app from, they can expect a consistent and intuitive experience. The app's user interface is designed to be simple and easy to use, with all features and functions accessible from a single platform.</p>
                                </div>
                            </div>
                            <div className='col-lg-1 d-lg-block d-md-none'></div>
                        </div>
                    </div>
                </div>

            </div>


            <Footer />
            </>
        )
    }
}
