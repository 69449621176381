import React, { Component } from "react";
import Footer from "../components/footer";
import { Helmet } from "react-helmet";
import gsap from "gsap";
import SplitText from "gsap/SplitText";
import ScrollTrigger from "gsap/ScrollTrigger";

export default class AxeGlobal extends Component {
  projectGsap = () => {
    gsap.registerPlugin(ScrollTrigger, SplitText);

    //Hero

    // gsap.from(".normal-header", 0.9, {
    //     opacity: 0,
    //     ease: "Power3.easeOut",
    //     delay: 3.5,
    //   })
    gsap.from("h2.line span", 0.9, {
      y: 180,
      rotateZ: 6,
      ease: "Power3.easeOut",
      delay: 0.7,
      stagger: {
        amount: 0.2,
      },
    });
    gsap.from(".mobile-sunnybee-h4", 0.9, {
      y: 40,
      opacity: 0,
      ease: "Power3.easeOut",
      delay: 1.5,
      stagger: {
        amount: 0.2,
      },
    });
    gsap.set(".sunnybee-hero", { height: "100vh" });
    gsap.to(".sunnybee-hero", {
      height: "80vh",
      delay: 1,
      ease: "Power3.easeOut",
      duration: 1,
    });
    gsap.set(".sunnybee-hero-listitem li", { visibility: "hidden" });
    ScrollTrigger.batch(".sunnybee-hero-listitem li", {
      onEnter: (batch) => {
        gsap.from(batch, {
          duration: 1,
          opacity: 0,
          delay: 1.2,
          yPercent: 100,
          scale: 1,
          autoAlpha: 0,
          ease: "Power3.easeOut",
          stagger: 0.1,
          visibility: "visible",
        });
      },
      toggleActions: "play none none none",
      once: true,
      markers: false,
    });
    gsap.set(".sunnybee-cover", { opacity: 1 });
    gsap.to(".sunnybee-cover", {
      opacity: 0,
      delay: 0,
      ease: "Power3.easeIn",
      duration: 0.3,
    });
    gsap.set(".sunnybee-heading", { opacity: 0 });
    gsap.to(".sunnybee-heading", {
      opacity: 1,
      delay: 0.1,
      ease: "Power3.easeIn",
      duration: 1,
    });
    gsap.set(".sunnybee-hero-cont", { "margin-bottom": 0 });
    gsap.to(".sunnybee-hero-cont", {
      "margin-bottom": 90,
      delay: 1,
      ease: "Power3.linear",
      duration: 1.5,
    });
    gsap.set(".our-approach-gra", { xPercent: 100 });
    gsap.to(".our-approach-gra", {
      xPercent: 0,
      delay: 2,
      ease: "Power3.easeOut",
      duration: 1.5,
    });
    gsap.set(".project-text", { visibility: "hidden" });
    ScrollTrigger.batch(".project-text", {
      onEnter: (batch) => {
        gsap.from(batch, {
          duration: 1.5,
          delay: 0,
          yPercent: 50,
          scale: 1,
          autoAlpha: 0,
          ease: "Power3.easeOut",
          stagger: 0.4,
          visibility: "visible",
        });
      },
      toggleActions: "play none none none",
      once: true,
      start: "top 80%",
      end: "top 40%",
      markers: false,
    });

    gsap.set(".bdr-top", { width: "0%" });
    gsap.to(".bdr-top", {
      width: "100%",
      ease: "Power3.easeOut",
      duration: 1,
      scrollTrigger: {
        trigger: ".bdr-top",
        start: "top 80%",
      },
    });
  };

  componentDidMount() {
    this.projectGsap();
  }

  render() {
    return (
      <>
        <Helmet>
          <title>Axe Global | Fingerprints Future</title>
          <meta
            name="description"
            content="Revamp Axe Global brand tone and aesthetic. Utilized design thinking to redesign the website with improved navigation and fluid animations"
          />
        </Helmet>

        <style>
          {`.home-work-parent h3 {
                    color: #000;
                }`}
        </style>
        <div className="home-cover-out"></div>
        <div className="overflow-hidden">
          <div className="sunnybee-cover"></div>
          {/* Savittr Hero */}
          <div className="sunnybee-hero">
            {/* <div className="our-approach-gra"></div> */}
            <div className="container sunnybee-hero-cont">
              <div className="row">
                <div className="col-lg-9">
                  <div>
                    <h2 className="sunnybee-heading">AxeGlobal</h2>
                  </div>
                  <div className="d-none d-lg-block d-md-block">
                    <div>
                      <h2 className="line">
                        <span>Headline about Axe Global</span>
                      </h2>
                    </div>
                  </div>
                  <div className="d-block d-lg-none d-md-none">
                    <h4 className="mobile-sunnybee-h4">
                      Headline about Axe Global
                    </h4>
                  </div>
                </div>
                <div className="col-lg-3 sunnybee-hero-listitem">
                  <ul>
                    <li>Wireframing</li>
                    <li>User Experience Design (UX)</li>
                    <li>User Interface Design (UI)</li>
                    <li>Rapid Prototyping</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          {/* ocacademy Hero End */}

          <div className="container sunnybee-video1 hide-nav">
            <img
              alt="Fingerprints Future"
              className="img-fluid d-lg-block d-none"
              src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/axeglobal-img1.png"
            />
            <img
              alt="Fingerprints Future"
              className="img-fluid d-lg-none d-block "
              src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/axeglobal-img1.png"
            />
          </div>

          {/* Mahasaghar About */}
          <div className="sunnybee-about">
            <div className="d-none d-lg-block sunnybee-gra1"></div>
            <div className="d-block d-lg-none sunnybee-gra1-reps"></div>
            <div className="container sunnybee-about-cont">
              <div className="row">
                <div className="col-lg-1 d-lg-block d-md-none"></div>
                <div className="col-lg-5 col-md-6">
                  <div>
                    <h4 className="project-text">What went into the</h4>
                  </div>
                  <div>
                    <h4 className="project-text">creation of the website?</h4>
                  </div>
                </div>
                <div className="col-lg-5 col-md-6">
                  <div>
                    <p className="project-text">
                      We went all in with this one, by redesigning the brand’s
                      website and implementing a new marketing strategy. We gave
                      the website a completely new aesthetic and restructured
                      the navigation, creating an informative page that
                      highlighted the company’s services.completely new
                      aesthetic and restructured the navigation, creating an
                      informative page that highlighted the company’s services.
                    </p>
                  </div>
                </div>
                <div className="col-lg-2 d-lg-block d-md-none"></div>
              </div>
            </div>
          </div>
          {/* Savittr About End */}

          {/* <div className='container sunnybee-video1 project-text mahasaghar-video'>
                <img alt="Fingerprints Future" className='img-fluid d-lg-block d-none' src="https://ik.imagekit.io/fingerprintsfuture/fps/gsis-img2.png"  />
                <img alt="Fingerprints Future" className='img-fluid d-lg-none d-block' src="https://ik.imagekit.io/fingerprintsfuture/fps/gsis-img2.png"  />
            </div> */}
        </div>

        <div className="container sunnybee-about-img mahasaghar-img-padding">
          <div className="row">
            <div className="col-lg-6 col-md-6 img-b-padding">
              <img
                alt="Fingerprints Future"
                className="img-fluid project-text"
                src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/axeglobal-img2.png"
              />
            </div>
            <div className="col-lg-6 col-md-6">
              <img
                alt="Fingerprints Future"
                className="img-fluid project-text"
                src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/axeglobal-img3.png"
                style={{ height: "auto" }}
              />

              <div className="axe-cont">
                <div>
                  <div>
                    <h4 className="project-text">What went into the</h4>
                  </div>
                  <div>
                    <h4 className="project-text">creation of the website?</h4>
                  </div>
                </div>
                <div>
                  <p className="project-text">
                    We went all in with this one, by redesigning the brand’s
                    website and implementing a new marketing strategy. We gave
                    the website a completely new aesthetic and restructured the
                    navigation, creating an informative page that highlighted
                    the company’s services. We went all in with this one, by
                    redesigning the brand’s website and implementing a new
                    marketing strategy. We gave the website a completely new
                    aesthetic and restructured the navigation, creating an
                    informative page that highlighted the company’s services.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container sunnybee-video1 hide-nav project-text">
          <img
            alt="Fingerprints Future"
            className="img-fluid d-lg-block d-none"
            src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/axeglobal-img4.png"
          />
          <img
            alt="Fingerprints Future"
            className="img-fluid d-lg-none d-block "
            src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/axeglobal-img4.png"
          />
        </div>

        <div className="sunnybee-about">
          <div className="d-none d-lg-block sunnybee-gra1"></div>
          <div className="d-block d-lg-none sunnybee-gra1-reps"></div>
          <div className="container sunnybee-about-cont">
            <div className="row">
              <div className="col-lg-1 d-lg-block d-md-none"></div>
              <div className="col-lg-5 col-md-6 txt-break">
                <div className="d-none d-xl-block">
                  <h4 className="project-text">Design and Development:</h4>
                </div>
                <div className="d-block d-xl-none">
                  <h4 className="project-text">Design and Development:</h4>
                </div>
              </div>
              <div className="col-lg-5 col-md-6">
                <div>
                  <p className="project-text">
                    We went all in with this one, by redesigning the brand’s
                    website and implementing a new marketing strategy. We gave
                    the website a completely new aesthetic and restructured the
                    navigation, creating an informative page that highlighted
                    the company’s services. We went all in with this one, by
                    redesigning the brand’s website and implementing a new
                    marketing strategy. We gave the website a completely new
                    aesthetic and restructured the navigation, creating an
                    informative page that highlighted the company’s services.
                  </p>
                </div>
              </div>
              <div className="col-lg-1 d-lg-block d-md-none"></div>
            </div>
          </div>
        </div>

        <div className="container sunnybee-video1 project-text">
          <img
            alt="Fingerprints Future"
            className="img-fluid d-lg-block d-none"
            src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/axeglobal-img5.png"
          />
          <img
            alt="Fingerprints Future"
            className="img-fluid d-lg-none d-block "
            src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/axeglobal-img5.png"
          />
        </div>
        <br />
        <br />
        <div className="container sunnybee-video1 project-text">
          <img
            alt="Fingerprints Future"
            className="img-fluid d-lg-block d-none"
            src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/axeglobal-img6.png"
          />
          <img
            alt="Fingerprints Future"
            className="img-fluid d-lg-none d-block "
            src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/axeglobal-img6.png"
          />
        </div>

        {/* Work Section */}
        <div className="sunnybee-work">
          <div className="container fixed-work-cont">
            <div className="bdr-top"></div>
            <h4 className="project-text">You might also like</h4>
            <div className="row home-work-parent">
              <div className="col-lg-6  home-work-child project-text">
                <a className="xtm-link" href="/work/xtm"></a>
                <img
                  alt="Fingerprints Future"
                  className="img-fluid"
                  src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/xtm-img-cover.png"
                />
                <h3>XTM</h3>
                <p>Website Design & Development</p>
              </div>
              <div className="col-lg-6  home-work-child project-text">
                <a className="ewallet-website-link" href="/work/e-wallet"></a>
                <img
                  alt="Fingerprints Future"
                  className="img-fluid"
                  src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/ewallet2x2.png"
                />
                <h3>E-Wallet</h3>
                <p>Mobile App</p>
              </div>
            </div>
          </div>
        </div>
        {/* Work Section End*/}

        <Footer />
      </>
    );
  }
}
