import React, { Component } from 'react';
import ContactButton from "./maincontactbutton"

class GetStartedCard extends Component {
    render() {
        return (
            <div className='sidebar-card'>
                <h1>Start <br/> building <br/>your future<br/>today.</h1>
                <div href='#'>
                    <ContactButton/>
                </div>
            </div>
        );
    }
}

export default GetStartedCard;
