import React from "react";
import {Helmet} from "react-helmet";


class Thankyou extends React.Component {
    

    render() {


        return (
            
            
            <div className="extra-hero tq container">
                <Helmet>
                        <script>
                            {"gtag('event', 'conversion', {'send_to': 'AW-11104708926/KL4DCLXe044YEL7Ska8p'});"}
                        </script>
                </Helmet>
                <style>
                    {
                        `.inner-page-btn {
                            display: none !important;
                        }
                        .modal-backdrop{
                            display: none !important;
                            pointer-events: none !important;
                        }
                        .modal{
                            display: none !important;
                        }
                        `
                    }
                </style>
                <div className="home-cover-out"></div>

                    <img alt="Fingerprints Future" src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/future-logo.svg" className="img-fluid extra-logo" />
                    <h1 className="extra-h1">Thanks so much for <br className="d-none d-lg-block"/>reaching out to us!</h1>
                    <p className="extra-p">Please book a time slot that works for you.</p>
                    <div className="extra-row">
                        <div className="extra-button-col">
                            <a href="https://calendly.com/aishwaryadaswani" className="extra-button" target="_blank">SCHEDULE A MEETING</a>
                        </div>
                        <div className="extra-link-col">
                            <a href="/" className="extra-link">BACK TO WEBSITE</a>
                        </div>
                    </div>
            </div>
         );
    }
    
}
 
export default Thankyou;

