import React, { Component } from 'react'
import Footer from '../components/footer'
import {Helmet} from "react-helmet";
import gsap from 'gsap'
import SplitText from 'gsap/SplitText'
import ScrollTrigger from 'gsap/ScrollTrigger'

export default class Tnq extends Component {

    projectGsap = () =>{
        gsap.registerPlugin(ScrollTrigger, SplitText)

        //Hero

        // gsap.from(".normal-header", 0.9, {
        //     opacity: 0,
        //     ease: "Power3.easeOut",
        //     delay: 3.5,
        //   })
          gsap.from("h2.line span", 0.9, {
            y: 180,
            rotateZ: 6,
            ease: "Power3.easeOut",
            delay: 0.7,
            stagger: {
              amount: 0.2
            }
          })
          gsap.from(".mobile-sunnybee-h4", 0.9, {
            y: 40,
            opacity: 0,
            ease: "Power3.easeOut",
            delay: 1.5,
            stagger: {
              amount: 0.2
            }
          })
          gsap.set(".sunnybee-hero", {height: "100vh"});
          gsap.to(".sunnybee-hero", {
            height: "80vh",
            delay: 1,
            ease: "Power3.easeOut",
            duration: 1
          });
          gsap.set(".sunnybee-hero-listitem li", { visibility: "hidden" });
          ScrollTrigger.batch(".sunnybee-hero-listitem li", {
              onEnter: batch => {
                  gsap.from(batch, {
                      duration: 1,
                      opacity: 0,
                      delay: 1.2,
                      yPercent: 100,
                      scale: 1,
                      autoAlpha: 0,
                      ease: "Power3.easeOut",
                      stagger: 0.1,
                      visibility: "visible",
                  })
              },
              toggleActions: "play none none none",
              once: true,
              markers: false,
          });
          gsap.set(".sunnybee-cover", {opacity: 1});
          gsap.to(".sunnybee-cover", {
            opacity: 0,
            delay: 0,
            ease: "Power3.easeIn",
            duration: 0.3
          });
          gsap.set(".sunnybee-heading", {opacity: 0});
          gsap.to(".sunnybee-heading", {
            opacity: 1,
            delay: 0.1,
            ease: "Power3.easeIn",
            duration: 1
          });
          gsap.set(".sunnybee-hero-cont", {"margin-bottom": 0});
          gsap.to(".sunnybee-hero-cont", {
            "margin-bottom": 90,
            delay: 1,
            ease: "Power3.linear",
            duration: 1.5
          });
          gsap.set(".our-approach-gra", {xPercent: 100});
          gsap.to(".our-approach-gra", {
            xPercent: 0,
            delay: 2,
            ease: "Power3.easeOut",
            duration: 1.5
          });
          gsap.set(".project-text", { visibility: "hidden" });
          ScrollTrigger.batch(".project-text", {
              onEnter: batch => {
                  gsap.from(batch, {
                      duration: 1.5,
                      delay: 0,
                      yPercent: 50,
                      scale: 1,
                      autoAlpha: 0,
                      ease: "Power3.easeOut",
                      stagger: 0.4,
                      visibility: "visible",
                  })
              },
              toggleActions: "play none none none",
              once: true,
              start: 'top 80%',
              end: 'top 40%',
              markers: false,
          });

          gsap.set(".bdr-top", {width: "0%"});
          gsap.to(".bdr-top", {
            width: "100%",
            ease: "Power3.easeOut",
            duration: 1,
            scrollTrigger:{
                trigger: ".bdr-top",
                start: "top 80%"
            }
          });



    }
    

    componentDidMount(){
        this.projectGsap();
        
    }

    render() {
        return (
            <>

            <style>
                {
                    `.home-work-parent h3 {
                        color: #000;
                    }`
                }
            </style>

            <div className="home-cover-out"></div>
            <div className='overflow-hidden'>
                <div className='sunnybee-cover'></div>
                {/* Savittr Hero */}
                <div className='sunnybee-hero'>
                    {/* <div className="our-approach-gra"></div> */}
                    <div className='container sunnybee-hero-cont'>
                        <div className='row'>
                            <div className='col-lg-9'>
                                <div>
                                    <h2 className='sunnybee-heading'>TNQ</h2>
                                </div>
                                <div className='d-none d-lg-block d-md-block'>
                                    <div>
                                        <h2 className='line'><span>Transforming TNQ's Online</span></h2>
                                    </div>
                                    <div>
                                        <h2 className='line'><span>Identity for the Future</span></h2>
                                    </div>
                                    <div>
                                        <h2 className='line'><span></span></h2>
                                    </div>
                                </div>
                                <div className='d-block d-lg-none d-md-none'>
                                    <h4 className='mobile-sunnybee-h4'>Transforming TNQ's Online Identity for the Future</h4>
                                </div>
                            </div>
                            <div className='col-lg-3 sunnybee-hero-listitem'>
                                <ul>
                                    <li>USER RESEARCH</li>
                                    <li>PERSONAS</li>
                                    <li>USER FLOWS</li>
                                    <li>WIRE-FRAMING</li>
                                    <li>USER EXPERIENCE DESIGN (UX)</li>
                                    <li>USER INTERFACE DESIGN (UI)</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Savittr Hero End */}

                <div className='container sunnybee-video1 hide-nav '>
                    <div className='d-none d-lg-block'>
                        <img alt="Fingerprints Future" className='img-fluid' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/tnq-img-1.png"  />
                    </div>
                    <div className='d-block d-lg-none'>
                        <img alt="Fingerprints Future" className='img-fluid' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/tnq-img-resp1.png"  />
                    </div>
                    {/* <img alt="Fingerprints Future" className='img-fluid d-lg-none d-block ' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/ewallet-img-1-m2.png"  /> */}
                    {/* <video src={Video1} muted autoPlay={"autoplay"} preload="auto" loop playsInline controlsList="nodownload" /> */}
                </div>

                {/* Savittr About */}
                <div className='sunnybee-about'>
                    <div className="d-none d-lg-block sunnybee-gra1"></div>
                    <div className="d-block d-lg-none sunnybee-gra1-reps"></div>
                    <div className='container sunnybee-about-cont'>
                        <div className='row'>
                            <div className='col-lg-1 d-lg-block d-md-none'></div>
                            <div className='col-lg-5 col-md-6'>
                                <div>
                                    <h4 className='project-text'>What went into the</h4>
                                </div>
                                <div>
                                    <h4 className='project-text'>creation of the Website?</h4>
                                </div>
                            </div>
                            <div className='col-lg-5 col-md-6'>
                                <div>
                                    <p className='project-text'>The project goals were to reposition, redesign, and market TNQ's website in new and effective ways. The revamped website needed to be optimized for both desktop and mobile devices and designed to establish credibility for the brand. Additionally, it had to feature tools that enable effective marketing activities for the product and increase signups for their services.</p>
                                </div>
                            </div>
                            <div className='col-lg-1 d-lg-block d-md-none'></div>
                        </div>
                    </div>
                </div>
                {/* Savittr About End */}

                <div className='container sunnybee-about-img'>
                    <div className='row tnq-img2-3'>
                        {/* <div className='col-lg-12 dandd-img2'>
                            <img alt="Fingerprints Future" className='img-fluid project-text' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/dandd-img-2.png" />
                        </div> */}
                        <div className='col-lg-7 dandd-img3 tnq-img2 project-text'>
                            <img alt="Fingerprints Future" className='img-fluid' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/tnq-img-2.png" />
                            <div className='text-center'>
                                <h4 className=''>OLD</h4>
                            </div>
                            {/* <video src={Video1} muted autoPlay={"autoplay"} preload="auto" loop playsInline controlsList="nodownload" /> */}
                        </div>
                        <div className='col-lg-7 tnq-img3 project-text'>
                            <img alt="Fingerprints Future" className='img-fluid' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/tnq-img-3.png" />
                            <div className='text-center'>
                                <h4 className=''>NEW</h4>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='tnq-bg'>
                    <div className='tnq-bg-video'>
                        <video className='project-text' src="https://storage.googleapis.com/fingerprintsfuturewebsite/videos/tnq-video-1.mp4" muted autoPlay={"autoplay"} preload="auto" loop playsInline controlsList="nodownload" />
                    </div>
                    <div className='container sunnybee-about-img'>
                        <div className='row'>
                            <div className='col-lg-12 tnq-img4'>
                                <img alt="Fingerprints Future" className='img-fluid dealsplusP-img2-resp project-text' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/tnq-img-4.png" />
                            </div>
                        </div>
                    </div>
                </div>

                <div className='sunnybee-about'>
                    <div className='container sunnybee-about-cont'>
                        <div className='row'>
                            <div className='col-lg-1 d-lg-block d-md-none'></div>
                            <div className='col-lg-5 col-md-6'>
                                <div className=''>
                                    <h4 className='project-text'>Mobile First Approach </h4>
                                </div>
                            </div>
                            <div className='col-lg-5 col-md-6'>
                                <div>
                                    <p className='project-text'>We made the decision to prioritize mobile users by implementing a mobile-first design philosophy. This approach was driven by our analytics data, which revealed that the majority of our website traffic was coming from mobile devices. By designing for small screens and touch interfaces first, we were able to ensure an optimal user experience for our mobile users, before adapting the design for larger screens and desktop devices</p>
                                </div>
                            </div>
                            <div className='col-lg-1 d-lg-block d-md-none'></div>
                        </div>
                    </div>
                </div>

                <div className='container sunnybee-about-img'>
                    <div className='row'>
                        <div className='col-lg-6 col-md-6'>
                            <img alt="Fingerprints Future" className='img-fluid dealsplusP-img2-resp project-text' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/tnq-img-5.png" />
                        </div>
                        <div className='col-lg-6 col-md-6'>
                            <img alt="Fingerprints Future" className='img-fluid project-text' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/tnq-img-6.png" />
                        </div>
                    </div>
                </div>

                <div className='sunnybee-about'>
                    <div className='container sunnybee-about-cont'>
                        <div className='row futbank-ebp'>
                            <div className='col-lg-1 d-lg-block d-md-none'></div>
                            <div className='col-lg-5 col-md-6 d-none d-lg-block'>
                                <div>
                                    <h4 className='project-text'>Transformation to a</h4>
                                </div>
                                <div>
                                    <h4 className='project-text'>Contemporary Brand</h4>
                                </div>
                            </div>
                            <div className='col-lg-5 col-md-6 d-block d-lg-none'>
                                <div>
                                    <h4 className='project-text'>Transformation to a Contemporary Brand</h4>
                                </div>
                            </div>
                            <div className='col-lg-5 col-md-6'>
                                <div>
                                    <p className='project-text'>The blue circle in the logo was corrected for a more symmetrical and polished appearance, and the typeface was slightly adjusted to give it a more modern and technological company look. These modifications were made with the intention of making the TNQ logo more visually appealing and reflective of the company's forward-thinking values. The new logo is not only a symbol of TNQ's rebranding efforts, but also a testament to its commitment to staying current and relevant in an ever-evolving industry.</p>
                                </div>
                            </div>
                            <div className='col-lg-1 d-lg-block d-md-none'></div>
                        </div>
                    </div>
                </div>

                <div className='container sunnybee-video1 authorcafe-img6'>
                    <video className='project-text' src="https://storage.googleapis.com/fingerprintsfuturewebsite/videos/tnq-video-2.mp4" muted autoPlay={"autoplay"} preload="auto" loop playsInline controlsList="nodownload" />

                    <img alt="Fingerprints Future" className='img-fluid project-text d-none d-lg-block' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/tnq-img-7.png" />
                    <img alt="Fingerprints Future" className='img-fluid project-text tnq-img-resp2 d-block d-lg-none' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/tnq-img-resp2.png" />

                    <video className='sunnybee-video4 tnq-video-3 project-text' src="https://storage.googleapis.com/fingerprintsfuturewebsite/videos/tnq-video-3.mp4" muted autoPlay={"autoplay"} preload="auto" loop playsInline controlsList="nodownload" />
                </div>

                

            </div>

            
                
            <Footer />
            </>
        )
    }
}
