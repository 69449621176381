import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter} from 'react-router-dom';
import Header from './components/header';
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.min.js'

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import './styles/app.css'
import './styles/responsive.css'
import AnimatedRoutes from './components/AnimatedRoutes';







const root = ReactDOM.createRoot(document.getElementById('future'));
root.render(
  <BrowserRouter>
              <Header />
  <AnimatedRoutes />
  </BrowserRouter>
);

