import React, { Component, useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import swal from "sweetalert";
import Api from "../../package.json";
import axios, * as others from 'axios';
import { useNavigate } from 'react-router-dom';
import gsap from "gsap";





const qs = require("qs");
const Contact = () => {

    const headers = {
        type: "application/json",
    };
    const [captcha, setHeadercaptcha] = useState([]);
    const [SubmitButton, setSubmitButton] = useState("Send");
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm();
    const buttonRef = useRef();
    const navigate = useNavigate();
    const onSubmit = (data) => {
        buttonRef.current.disabled = true;
        setSubmitButton("SUBMITTING...");
        save_enquiry(data);
    };
    const save_enquiry = async (data) => {

        const response = await axios.post(
            Api.userdata.url + "/site/landing/save_enquiry_future",
            qs.stringify({
                name: data.name,
                email: data.email,
                phone: data.phone,
                message: data.services,
                budget: data.budget,
            })
        );
        const json = await response.data;
        if (json["status"] === "OK") {
            navigate('/thankyou');
            /*swal("Successful Submitted", json["message"], "success").then((value) => {
                window.location.reload(false);
            });*/
        } else {
            swal("Error !", json["message"], "error");
        }
        buttonRef.current.disabled = false;
        setSubmitButton("Submit");

    };
    useEffect(() => {
        // getLoadData();
        gsap.set(".sunnybee-cover", { opacity: 1 });
        gsap.to(".sunnybee-cover", {
            opacity: 0,
            delay: 0,
            ease: "Power3.easeIn",
            duration: 0.3
        });

        gsap.from("h2.line span", 0.9, {
            y: 180,
            rotateZ: 6,
            ease: "Power3.easeOut",
            delay: 0.5,
            stagger: {
                amount: 0.2
            }
        })


        gsap.set(".footer-content", { opacity: 0, yPercent: 20 });
        gsap.to(".footer-content", {
            opacity: 1,
            yPercent: 0,
            ease: "Power3.easeOut",
            duration: 1,
            delay: 1
        });
    }, []);

    return (
        <>

            <style>
                {
                    `.inner-page-btn {
                        display: none;
                    }`
                }
            </style>

            <div className="home-cover-out"></div>
            <div className='sunnybee-cover'></div>
            <div className="container contact-page">
                {/* <div className="contact-hero-gra"></div> */}
                <div className="col-lg-7">
                    <h2 className="line"><span>Join us on this</span></h2>
                    <h2 className="line"><span>exciting journey.</span></h2>
                </div>
                <div className="row footer-content">
                    <div className="col-lg-7 form-side">
                        <form className="form-group" method="POST" onSubmit={handleSubmit(onSubmit)}>
                            <label htmlFor="name">What is your name</label>
                            <input type="text" name="name" placeholder="Please enter your First and Last name"
                                {...register("name", {
                                    required: {
                                        value: true,
                                        message: "Please enter your name",
                                    },
                                })} />
                            {errors.name && (
                                <span className="errors">{errors.name.message}</span>
                            )}

                            <label htmlFor="email">What is your email</label>
                            <input type="email" name="email" placeholder="Please enter your work email"
                                {...register("email", {
                                    required: {
                                        value: true,
                                        message: "Please enter your email id",
                                    },
                                })} />
                            {errors.email && (
                                <span className="errors">{errors.email.message}</span>
                            )}

                            <label htmlFor="phone">What is your phone number</label>
                            <input type="number" name="phone" placeholder="Please enter your mobile number along with country code"
                                {...register("phone", {
                                    required: {
                                        value: true,
                                        message: "Please enter your mobile number",
                                    },
                                })} />
                            {errors.phone && (
                                <span className="errors">{errors.phone.message}</span>
                            )}

                            <label htmlFor="services">What services are you looking for?</label>
                            <input type="text" name="services" placeholder="Please specify what you require"
                                {...register("services", {
                                    required: {
                                        value: true,
                                        message: "Please tell us what you require",
                                    },
                                })} />
                            {errors.services && (
                                <span className="errors">{errors.services.message}</span>
                            )}

                            {/* <label htmlFor="landing-budget">Whats your Budget?</label>
                            <div className="radios-budget">
                                <div className="form-check">
                                    <input className="form-check-input" type="radio" name="budget" id="budget1" value="INR 1,50,000 - 3,00,000"
                                        {...register("budget", {
                                            required: {
                                                value: true,
                                                message: "Please select your budget",
                                            },
                                        })} />
                                    <label className="form-check-label" for="budget1">
                                        INR 1,50,000 - 3,00,000
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="radio" name="budget" id="budget2" value="INR 3,50,000 - 5,00,000"
                                        {...register("budget", {
                                            required: {
                                                value: true,
                                                message: "Please select your budget",
                                            },
                                        })} />
                                    <label className="form-check-label" for="budget2">
                                        INR 3,50,000 - 5,00,000
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="radio" name="budget" id="budget3" value="INR 5,00,000 - 7,00,000"
                                        {...register("budget", {
                                            required: {
                                                value: true,
                                                message: "Please select your budget",
                                            },
                                        })} />
                                    <label className="form-check-label" for="budget3">
                                        INR 5,00,000 - 7,00,000
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="radio" name="budget" id="budget4" value="INR 7,00,000 & 14,00,000"
                                        {...register("budget", {
                                            required: {
                                                value: true,
                                                message: "Please select your budget",
                                            },
                                        })} />
                                    <label className="form-check-label" for="budget4">
                                        INR 7,00,000 & 14,00,000
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="radio" name="budget" id="budget5" value="INR 14,00,000 & Above"
                                        {...register("budget", {
                                            required: {
                                                value: true,
                                                message: "Please select your budget",
                                            },
                                        })} />
                                    <label className="form-check-label" for="budget5">
                                        INR 14,00,000 & Above
                                    </label>
                                </div>
                                {errors.budget && (
                                    <span className="errors">{errors.budget.message}</span>
                                )}
                            </div> */}

                            {/* <label htmlFor="message">What services are you looking for?</label> */}
                            {/* <textarea name="message" placeholder="Can you help me with..."/> */}
                            <button id='main-submit' className="form-control" ref={buttonRef}> {SubmitButton}</button>
                            <div className="foot-bdr-btm d-block d-lg-none d-md-none"></div>
                        </form>
                    </div>
                    <div className="col-lg-1">
                    </div>
                    <div className="col-lg-4 contact-content">
                        <div className="c-block">
                            <h5>FOR BUSINESS ENQUIRIES</h5>
                            <a href="mailto:business@fingerprintsfuture.com" className="mail">business@fingerprintsfuture.com</a><br />
                            <a href="tel:+91 91761 11675" className="number">+91 91761 11675</a>
                        </div>
                        <div className="c-block">
                            <h5>FOR CAREERS</h5>
                            <a href="mailto:careers@fingerprintsfuture.com" className="mail">careers@fingerprintsfuture.com</a><br />
                        </div>
                        <div className="c-block d-none">
                            <h5>GENERAL ENQUIRIES</h5>
                            <a href="mailto:internal@fingerprintsfuture.com" className="mail">internal@fingerprintsfuture.com</a><br />
                            <a href="tel:+91 91761 11675" className="number">+91 91761 11675</a>
                        </div>
                        <div className="c-block">
                            <h5>LOCATED AT</h5>
                            <a className="mail">15/4, 3rd Floor, Haddows Lane,<br/>
Haddows Road, Nungambakkam,<br/>
Chennai - 600006</a><br />
                        </div>
                        <div className="c-block d-none">
                            <span><a target="_blank" className="mail">LinkedIn</a></span>
                            <span><a target="_blank" className="mail">Instagram</a></span>
                        </div>
                    </div>
                </div>
                <div className="copyrights">
                    <h6>2024 | Fingerprints Future</h6>
                    <a href="/privacy" className="privacy-link"><h6>Privacy Policy</h6></a>
                </div>
            </div>
        </>
    );
}

export default Contact;