import React, { Component } from 'react'
import Footer from '../components/footer'
import {Helmet} from "react-helmet";
import gsap from 'gsap'
import SplitText from 'gsap/SplitText'
import ScrollTrigger from 'gsap/ScrollTrigger'

export default class dealsplusW extends Component {
    projectGsap = () =>{
        gsap.registerPlugin(ScrollTrigger, SplitText)

        //Hero

        // gsap.from(".normal-header", 0.9, {
        //     opacity: 0,
        //     ease: "Power3.easeOut",
        //     delay: 3.5,
        //   })
          gsap.from("h2.line span", 0.9, {
            y: 180,
            rotateZ: 6,
            ease: "Power3.easeOut",
            delay: 0.7,
            stagger: {
              amount: 0.2
            }
          })
          gsap.from(".mobile-sunnybee-h4", 0.9, {
            y: 40,
            opacity: 0,
            ease: "Power3.easeOut",
            delay: 1.5,
            stagger: {
              amount: 0.2
            }
          })
          gsap.set(".sunnybee-hero", {height: "100vh"});
          gsap.to(".sunnybee-hero", {
            height: "80vh",
            delay: 1,
            ease: "Power3.easeOut",
            duration: 1
          });
          gsap.set(".sunnybee-hero-listitem li", { visibility: "hidden" });
          ScrollTrigger.batch(".sunnybee-hero-listitem li", {
              onEnter: batch => {
                  gsap.from(batch, {
                      duration: 1,
                      opacity: 0,
                      delay: 1.2,
                      yPercent: 100,
                      scale: 1,
                      autoAlpha: 0,
                      ease: "Power3.easeOut",
                      stagger: 0.1,
                      visibility: "visible",
                  })
              },
              toggleActions: "play none none none",
              once: true,
              markers: false,
          });
          gsap.set(".sunnybee-cover", {opacity: 1});
          gsap.to(".sunnybee-cover", {
            opacity: 0,
            delay: 0,
            ease: "Power3.easeIn",
            duration: 0.3
          });
          gsap.set(".sunnybee-heading", {opacity: 0});
          gsap.to(".sunnybee-heading", {
            opacity: 1,
            delay: 0.1,
            ease: "Power3.easeIn",
            duration: 1
          });
          gsap.set(".sunnybee-hero-cont", {"margin-bottom": 0});
          gsap.to(".sunnybee-hero-cont", {
            "margin-bottom": 90,
            delay: 1,
            ease: "Power3.linear",
            duration: 1.5
          });
          gsap.set(".our-approach-gra", {xPercent: 100});
          gsap.to(".our-approach-gra", {
            xPercent: 0,
            delay: 2,
            ease: "Power3.easeOut",
            duration: 1.5
          });
          gsap.set(".project-text", { visibility: "hidden" });
          ScrollTrigger.batch(".project-text", {
              onEnter: batch => {
                  gsap.from(batch, {
                      duration: 1.5,
                      delay: 0,
                      yPercent: 50,
                      scale: 1,
                      autoAlpha: 0,
                      ease: "Power3.easeOut",
                      stagger: 0.4,
                      visibility: "visible",
                  })
              },
              toggleActions: "play none none none",
              once: true,
              start: 'top 80%',
              end: 'top 40%',
              markers: false,
          });

          gsap.set(".bdr-top", {width: "0%"});
          gsap.to(".bdr-top", {
            width: "100%",
            ease: "Power3.easeOut",
            duration: 1,
            scrollTrigger:{
                trigger: ".bdr-top",
                start: "top 80%"
            }
          });



    }
    

    componentDidMount(){
        this.projectGsap();
        
    }

  render() {
    return (
      <>
        <Helmet>
            <title>Design for Deals Plus - Website Design & Development | Fingerprints Future</title>
            <meta name="description" content="Deals Plus website design for a leading Private equity fund company. UI UX website design, UI/UX design case study for fund management company." />
        </Helmet>

        
        <style>
            {
                `.home-work-parent h3 {
                    color: #000;
                }`
            }
        </style>

      <div className="home-cover-out"></div>
        <div className='overflow-hidden'>
            <div className='sunnybee-cover'></div>
            {/* Dealsplus Website Hero */}
            <div className='sunnybee-hero'>
            {/* <div className="our-approach-gra"></div> */}
                <div className='container sunnybee-hero-cont'>
                    <div className='row'>
                    <div className='col-lg-9'>
                            <div>
                                <h2 className='sunnybee-heading'>Deals Plus Website</h2>
                            </div>
                            <div className='d-none d-lg-block d-md-block'>
                                <div>
                                    <h2 className='line'><span>Introducing private fund</span></h2>
                                </div>
                                <div>
                                    <h2 className='line'><span> managers to the Future.</span></h2>
                                </div>
                            </div>
                            <div className='d-block d-lg-none d-md-none'>
                                <h4 className='mobile-sunnybee-h4'>Introducing private fund managers to the Future.</h4>
                            </div>
                        </div>
                        
                        <div className='col-lg-3 sunnybee-hero-listitem'>
                            <ul>
                                <li>Wireframing</li>
                                <li>User Experience Design (UX)</li>
                                <li>User Interface Design (UI)</li>
                                <li>Rapid Prototyping</li>
                                <li>Micro-interaction Animation</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            {/* Dealsplus Website Hero End */}

            <div className='container sunnybee-video1 hide-nav'>
                <img alt="Fingerprints Future" className='img-fluid d-none d-lg-block' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/dealsplus-website-img-nw1.png" />
                <img alt="Fingerprints Future" className='img-fluid d-lg-none d-block' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/dealsplus-website-img-resp-nw1-1.png" />
                {/* <video src={Video1} muted autoPlay={"autoplay"} preload="auto" loop playsInline controlsList="nodownload" /> */}
            </div>

            {/* Dealsplus Website About */}
            <div className='sunnybee-about'>
                <div className="d-none d-lg-block sunnybee-gra1"></div>
                <div className="d-block d-lg-none sunnybee-gra1-reps"></div>
                <div className='container sunnybee-about-cont'>
                    <div className='row dealsplus-resp-cont1'>
                        <div className='col-lg-1 d-lg-block d-md-none'></div>
                        <div className='col-lg-5 col-md-6'>
                            <div>
                                <h4 className='project-text'>What went into the</h4>
                            </div>
                            <div>
                                <h4 className='project-text'>creation of the website?</h4>
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-6'>
                            <div>
                                <p className='project-text'>We went all in with this one, by redesigning the brand’s website and implementing a new marketing strategy. We gave the website a completely new aesthetic and restructured the navigation, creating an informative page that highlighted the product features.</p>
                            </div>
                        </div>
                        <div className='col-lg-2 d-lg-block d-md-none'></div>
                    </div>
                </div>
            </div>
            {/* Dealsplus Website About End */}

            <div className='container sunnybee-about-img dealsplusW-video1'>
                {/* <img alt="Fingerprints Future" className='img-fluid' src={Img2} /> */}
                <video className='project-text' src="https://storage.googleapis.com/fingerprintsfuturewebsite/videos/dealsplusW-video-1.mp4" muted autoPlay={"autoplay"} preload="auto" loop playsInline controlsList="nodownload" />
            </div>
        </div>

        <div className='dealsplusW-sitecore'>
            <div className='container dealsplusW-img3-4-5'>
                <div className='row'>
                    <div className='col-lg-2'></div>
                    <div className='col-lg-8 dealsplusW-video2'>
                        {/* <img alt="Fingerprints Future" className='img-fluid' src={Img3} /> */}
                        <video className='project-text' src="https://storage.googleapis.com/fingerprintsfuturewebsite/videos/dealsplus-video-nw2-1.mp4" muted autoPlay={"autoplay"} preload="auto" loop playsInline controlsList="nodownload" />
                    </div>
                    <div className='col-lg-2'></div>
                </div>
                <img alt="Fingerprints Future" className='img-fluid project-text d-none d-lg-block' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/dealsplus-website-img-nw2-1.png" />
                <img alt="Fingerprints Future" className='img-fluid project-text d-none d-lg-block' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/dealsplus-website-img-nw3.png" />
                <img alt="Fingerprints Future" className='img-fluid project-text d-block d-lg-none' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/dealsplus-website-img-resp-nw2-2.png" />
                <img alt="Fingerprints Future" className='img-fluid project-text d-block d-lg-none' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/dealsplus-website-img-resp-nw3-1.png" />
            </div>

            <div className='container savittr-creative-contress'>
                <div className='row'>
                    <div className='col-lg-1 d-lg-block d-md-none'></div>
                    <div className='col-lg-2 col-md-3 savittr-img6'>
                        <img alt="Fingerprints Future" className='img-fluid project-text' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/dealsplus-website-img-6-new.png" />
                    </div>
                    <div className='col-lg-2 col-md-3 savittr-img7'>
                        <img alt="Fingerprints Future" className='img-fluid project-text' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/dealsplus-website-img-7-new.png" />
                    </div>
                    <div className='col-lg-2 d-lg-block d-md-none'></div>
                    <div className='col-lg-4 col-md-6 align-self-center'>
                        <h4 className='project-text'>Bringing the brand to life</h4>
                        <p className='project-text'>Everything from the brand colours to the fonts and brand tone of voice was recreated to be in line with the new brand identity. A new tone of voice was identified and then used uniformly, to give the brand a consistent sound as well as an easily identifiable look. </p>
                    </div>
                    <div className='col-lg-1 d-lg-block d-md-none'></div>
                </div>
            </div>

            <div className='container savittr-img8 dealsplusW-img3-4-5'>
                <div className='row'>
                    <div className='col-lg-1'></div>
                    <div className='col-lg-10'>
                        <img alt="Fingerprints Future" className='img-fluid project-text' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/dealsplus-website-img-8.svg" />
                    </div>
                    <div className='col-lg-1'></div>
                </div>
                <img alt="Fingerprints Future" className='img-fluid project-text d-none d-lg-block' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/dealsplus-website-img-nw4.png" />
                <img alt="Fingerprints Future" className='img-fluid project-text d-none d-lg-block' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/dealsplus-website-img-nw5-1.png" />
                <img alt="Fingerprints Future" className='img-fluid project-text d-block d-lg-none' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/dealsplus-website-img-resp-nw4-1.png" />
                <img alt="Fingerprints Future" className='img-fluid project-text d-block d-lg-none' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/dealsplus-website-img-resp-nw5-2.png" />
            </div>
        </div>

        {/* Work Section */}
        <div className="sunnybee-work">
            <div className="container fixed-work-cont">
                <div className='bdr-top'></div>
                <h4 className='project-text'>You might also like</h4>
                <div className="row home-work-parent">
                <div className="col-lg-6 home-work-child project-text">
                                <a className="authorcafe-link" href="/work/authorcafe"></a>
                                <img alt="Fingerprints Future" className="img-fluid" src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/authorcafe-thumbx2.png" />
                                <h3>Authorcafe</h3>
                                <p>Website Design & Development</p>
                            </div>
                            <div className="col-lg-6 home-work-child project-text">
                                <a className="dealsplus-product-link" href="/work/dealsplus-product"></a>
                                <img alt="Fingerprints Future" className="img-fluid" src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/dealsplus-thumbx2.png" />
                                <h3>Deals Plus</h3>
                                <p>Product Design</p>
                            </div>
                </div>
            </div>
            {/* <a href="#"><h3 className="view-all">View all case studies</h3></a> */}
        </div>
        {/* Work Section End*/}

      <Footer />
      </>
    )
  }
}
