import React, { Component } from "react";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
} from "react-share";
import AuthorIcon from "./../assets/blog-author.png";
import Footer from "../components/footer";
import SocialShare from "../components/socialShare";
import GetStartedCard from "../components/getStartedCard";

export default class NewblogDesignsystem extends Component {
  render() {
    const recentBlogData = [
      {
        title:
          "AI Isn't Making Us Dumb: The Positive Impact of Chat GPT and Other Technologies",
        publishedOn: "Apr 03, 2023",
        minuteRead: "3",
        imgUrl:
          "https://ik.imagekit.io/fingerprintsfuture/fps/blog-adn-img.png",
        blogUrl: "/blog/ai-dumb-or-not",
      },
      {
        title:
          "Simple Models, Big Impact: Why Gamification Works in  Digital Product Design",
        publishedOn: "Feb 16, 2023",
        minuteRead: "6",
        imgUrl:
          "https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/blog-smbi-img.png",
        blogUrl: "/blog/why-gamification-works-in-digital-product-design",
      },
      {
        title: "The Importance of Designing for usability in UI and UX",
        publishedOn: "Jan 24, 2023",
        minuteRead: "7",
        imgUrl:
          "https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/blog-du-img.png",
        blogUrl: "/blog/the-importance-of-designing-for-usability",
      },
    ];

    return (
      <>
        <div className="blog-temp-parent overflow-hidden v2-blog">
          <style>
            {`.inner-page-btn {
                    display: none;
                }`}
          </style>
          <div className="home-cover-out"></div>
          <div className="container">
            <div className="row">
              <div className="col-lg-8">
                <div className="blog-temp-hero">
                  <div>
                    <a href="/blog" className="back-btn">
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="5"
                          height="9"
                          viewBox="0 0 5 9"
                          fill="none"
                        >
                          <path
                            d="M4.62012 0.879761L1 4.50014L4.62012 8.12026"
                            stroke="#23262F"
                          />
                        </svg>
                      </span>
                      Back
                    </a>
                  </div>

                  <div className="nwv-blog1-hero">
                    <h1>Design Systems and Why They Matter</h1>
                    <div className="nwv-blog1-hero-adt">
                      <p>7 Minute Read</p>
                      {/* <span></span>
                      <img
                        className="img-fluid blog-author-img"
                        src={AuthorIcon}
                        alt="Author"
                      />
                      <p>Aishwarya Daswani</p> */}
                      {/* <span></span>
                                <p>Feb 07, 2023</p> */}
                    </div>
                  </div>
                  <div className="main-hero-blog-row">
                    <div className="bth-right-p workanimation">
                      <img
                        alt="Fingerprints Future"
                        src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/blog-ds-img1.png"
                        className="img-fluid"
                      />
                    </div>
                  </div>
                </div>
                <div className="blog-content">
                  <h2 className="workanimation sub-heading">
                    Design systems are an essential part of the design process,
                    particularly in the field of user experience (UX) design. At{" "}
                    <span className="txt-bold">Future,</span> it is a key
                    component of creating a seamless and consistent user
                    experience for our clients' customers.
                  </h2>

                  <div className="blog-ww">
                    <div className="row">
                      <div className="col-md-5">
                        <h2>
                          But what exactly is a design system, and why do they
                          matter?
                        </h2>
                      </div>
                      <div className="col-md-7">
                        <p>
                          A design system is a collection of design principles,
                          guidelines, and reusable components that can be
                          applied across a variety of digital products and
                          platforms. It helps to ensure that all of a company's
                          products and services have a cohesive look and feel,
                          which can improve brand recognition and customer
                          satisfaction.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="txt-bdr">
                    <h2 className="workanimation">
                      Here are a few reasons why design systems are important
                    </h2>
                  </div>
                  <br />

                  <div className="txt-outline">
                    <h4>01</h4>
                  </div>

                  <div className="txt-gradi">
                    <h3>Aids in Consistency</h3>
                  </div>

                  <p className="workanimation">
                    One of the main benefits of a design system is that it helps
                    to create consistency across a company's products and
                    services. This can improve the user experience and make it
                    easier for users to recognize and trust the brand. For
                    example, let's say a company has a mobile app and a website.{" "}
                  </p>
                  <br />
                  <p className="workanimation">
                    Without a design system, the designers working on these two
                    products would have to come up with their own designs and
                    styles for each element, such as buttons, navigation menus,
                    and headings. This could result in a disjointed user
                    experience, with the app and website feeling like two
                    separate entities rather than part of the same brand.
                  </p>
                  <br />

                  <div>
                    <img
                      className="w-100"
                      src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/blog-ds-img-2.png"
                      alt="blog-img"
                    />
                  </div>
                  <br />

                  <div className="txt-outline">
                    <h4>02</h4>
                  </div>

                  <div className="txt-gradi">
                    <h3>Better for Communication</h3>
                  </div>

                  <p className="workanimation">
                    One of the main benefits of a design system is that it helps
                    to create consistency across a company's products and
                    services. This can improve the user experience and make it
                    easier for users to recognize and trust the brand. For
                    example, let's say a company has a mobile app and a website.{" "}
                  </p>
                  <br />
                  <p className="workanimation">
                    Without a design system, the designers working on these two
                    products would have to come up with their own designs and
                    styles for each element, such as buttons, navigation menus,
                    and headings. This could result in a disjointed user
                    experience, with the app and website feeling like two
                    separate entities rather than part of the same brand.
                  </p>
                  <br />

                  <div className="txt-outline">
                    <h4>03</h4>
                  </div>

                  <div className="txt-gradi">
                    <h3>Iterate Faster</h3>
                  </div>

                  <p className="workanimation">
                    By using a design system, designers can iterate on product
                    designs more quickly and efficiently. Instead of starting
                    from scratch every time, they can use the design system to
                    make updates and improvements to existing products and
                    features. For example, if a design system specifies that all
                    buttons should be a certain size, color, and shape, one
                    change in the system can translate across all design assets
                    instantly.
                  </p>
                  <br />

                  <div>
                    <img
                      className="w-100"
                      src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/blog-ds-img-3.png"
                      alt="blog-img"
                    />
                  </div>
                  <br />

                  <div className="txt-outline">
                    <h4>04</h4>
                  </div>

                  <div className="txt-gradi">
                    <h3>SCALE FASTER</h3>
                  </div>

                  <p className="workanimation">
                    By using a design system, designers can iterate on product
                    designs more quickly and efficiently. Instead of starting
                    from scratch every time, they can use the design system to
                    make updates and improvements to existing products and
                    features. For example, if a design system specifies that all
                    buttons should be a certain size, color, and shape, one
                    change in the system can translate across all design assets
                    instantly.
                  </p>
                  <br />

                  <div className="blog-sf">
                    <div className="d-block">
                      <img
                        src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/blog-ds-img-4.png"
                        alt="blog-img"
                      />
                    </div>
                    <div className="d-block">
                      <img
                        src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/blog-ds-img-5.png"
                        alt="blog-img"
                      />
                    </div>
                    <div className="d-block">
                      <img
                        src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/blog-ds-img-6.png"
                        alt="blog-img"
                      />
                    </div>
                  </div>
                  <br />
                  <br />

                  <div className="txt-outline">
                    <h4>05</h4>
                  </div>

                  <div className="txt-gradi">
                    <h3>Helps with Collaboration</h3>
                  </div>

                  <p className="workanimation">
                    Design systems can facilitate collaboration within the
                    design team and across departments. By providing clear
                    guidelines and reusable components, a design system can make
                    it easier for designers to work together and ensure that the
                    final product meets the needs of the business. This is
                    especially true when working with multiple teams on
                    enterprise projects.
                  </p>
                  <br />

                  <div>
                    <img
                      className="w-100"
                      src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/blog-ds-img-7.png"
                      alt="blog-img"
                    />
                  </div>
                  <br />
                </div>
              </div>
              <div className="col-lg-4">
                <div className="v2-blog-sidebar">
                  <div className="v2-blog-sidebar-cont">
                    <div className="v2-blog-sidebar-tags">
                      <h4>Tags</h4>
                      <div className="">
                        <span className="">UI design</span>
                        <span className="">UX DESIGN</span>
                        <span className="">ARTIFICIAL INTELLIGENCE</span>
                        <span className="">WEB DEVELOPMENT</span>
                        <span className="">FRONTEND DEVELOPMENT</span>
                        <span className="">USER INTERFACE</span>
                        <span className="">USER EXPERIENCE</span>
                        <span className="">RESPONSIVE DESIGN</span>
                      </div>
                    </div>

                    <div className="v2-blog-sidebar-posts">
                      <h4>POPULAR POSTS</h4>
                      <div className="all-posts">
                        {recentBlogData.map((blog, index) => (
                          <a
                            href={blog.blogUrl}
                            key={index}
                            className="post-container"
                          >
                            <img src={blog.imgUrl} alt="post-banner" />
                            <p>
                              {blog.title}
                              <br />
                              {/* <span>{blog.publishedOn}</span>  */}
                              <span>{blog.minuteRead} Minute read</span>
                            </p>
                          </a>
                        ))}
                      </div>
                    </div>

                    <div className="v2-blog-sidebar-social">
                      <h4>SHARE</h4>
                      <div className="">
                        <div className="socials-container">
                          <FacebookShareButton
                            url={
                              "https://fingerprintsfuture.com/blog/design-systems"
                            }
                            description={"Design Systems and why they matter"}
                            className="Demo__some-network__share-button"
                          >
                            <FacebookIcon size={36.67} round />
                          </FacebookShareButton>
                          <TwitterShareButton
                            title={"Design Systems and why they matter"}
                            url={
                              "https://fingerprintsfuture.com/blog/design-systems"
                            }
                          >
                            <TwitterIcon size={36.67} round />
                          </TwitterShareButton>
                          <LinkedinShareButton
                            title={"Design Systems and why they matter"}
                            url={
                              "https://fingerprintsfuture.com/blog/design-systems"
                            }
                          >
                            <LinkedinIcon size={36.67} round />
                          </LinkedinShareButton>
                        </div>
                      </div>
                    </div>
                    <div className="">
                      <GetStartedCard />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}
