import React, { Component } from 'react'
import Footer from '../components/footer'
import {Helmet} from "react-helmet";
import gsap from 'gsap'
import SplitText from 'gsap/SplitText'
import ScrollTrigger from 'gsap/ScrollTrigger'


export default class futbank extends Component {
    projectGsap = () =>{
        gsap.registerPlugin(ScrollTrigger, SplitText)

        //Hero

        // gsap.from(".normal-header", 0.9, {
        //     opacity: 0,
        //     ease: "Power3.easeOut",
        //     delay: 3.5,
        //   })
          gsap.from("h2.line span", 0.9, {
            y: 180,
            rotateZ: 6,
            ease: "Power3.easeOut",
            delay: 0.7,
            stagger: {
              amount: 0.2
            }
          })
          gsap.from(".mobile-sunnybee-h4", 0.9, {
            y: 40,
            opacity: 0,
            ease: "Power3.easeOut",
            delay: 1.5,
            stagger: {
              amount: 0.2
            }
          })
          gsap.set(".sunnybee-hero", {height: "100vh"});
          gsap.to(".sunnybee-hero", {
            height: "80vh",
            delay: 1,
            ease: "Power3.easeOut",
            duration: 1
          });
          gsap.set(".sunnybee-hero-listitem li", { visibility: "hidden" });
          ScrollTrigger.batch(".sunnybee-hero-listitem li", {
              onEnter: batch => {
                  gsap.from(batch, {
                      duration: 1,
                      opacity: 0,
                      delay: 1.2,
                      yPercent: 100,
                      scale: 1,
                      autoAlpha: 0,
                      ease: "Power3.easeOut",
                      stagger: 0.1,
                      visibility: "visible",
                  })
              },
              toggleActions: "play none none none",
              once: true,
              markers: false,
          });
          gsap.set(".sunnybee-cover", {opacity: 1});
          gsap.to(".sunnybee-cover", {
            opacity: 0,
            delay: 0,
            ease: "Power3.easeIn",
            duration: 0.3
          });
          gsap.set(".sunnybee-heading", {opacity: 0});
          gsap.to(".sunnybee-heading", {
            opacity: 1,
            delay: 0.1,
            ease: "Power3.easeIn",
            duration: 1
          });
          gsap.set(".sunnybee-hero-cont", {"margin-bottom": 0});
          gsap.to(".sunnybee-hero-cont", {
            "margin-bottom": 90,
            delay: 1,
            ease: "Power3.linear",
            duration: 1.5
          });
          gsap.set(".our-approach-gra", {xPercent: 100});
          gsap.to(".our-approach-gra", {
            xPercent: 0,
            delay: 2,
            ease: "Power3.easeOut",
            duration: 1.5
          });
          gsap.set(".project-text", { visibility: "hidden" });
          ScrollTrigger.batch(".project-text", {
              onEnter: batch => {
                  gsap.from(batch, {
                      duration: 1.5,
                      delay: 0,
                      yPercent: 50,
                      scale: 1,
                      autoAlpha: 0,
                      ease: "Power3.easeOut",
                      stagger: 0.4,
                      visibility: "visible",
                  })
              },
              toggleActions: "play none none none",
              once: true,
              start: 'top 80%',
              end: 'top 40%',
              markers: false,
          });

          gsap.set(".bdr-top", {width: "0%"});
          gsap.to(".bdr-top", {
            width: "100%",
            ease: "Power3.easeOut",
            duration: 1,
            scrollTrigger:{
                trigger: ".bdr-top",
                start: "top 80%"
            }
          });



    }
    

    componentDidMount(){
        this.projectGsap();
        
    }

  render() {
    return (
      <>
        <Helmet>
            <title>Product Design for FUT Bank - UI UX App Design | Fingerprints Future</title>
            <meta name="description" content="Fintech white label app design for FUT. UI/UX design case study for white label mobile app. Website design & mobile app design for Fintech sector." />
        </Helmet>

        
        <style>
            {
                `.home-work-parent h3 {
                    color: #000;
                }`
            }
        </style>

      <div className="home-cover-out"></div>
        <div className='overflow-hidden'>
            <div className='sunnybee-cover'></div>
            {/* Fut Bank Hero */}
            <div className='sunnybee-hero'>
                {/* <div className="d-none d-lg-block our-approach-gra"></div> */}
                {/* <div className="d-block d-lg-none our-approach-gra-resp"></div> */}
                <div className='container sunnybee-hero-cont'>
                    <div className='row'>
                        <div className='col-lg-9'>
                            <div>
                                <h2 className='sunnybee-heading'>Fut Bank</h2>
                            </div>
                            <div className='d-none d-lg-block d-md-block'>
                                <div>
                                    <h2 className='line'><span>Creating a brand-agnostic</span></h2>
                                </div>
                                <div>
                                    <h2 className='line'><span>banking app for the Future.</span></h2>
                                </div>
                            </div>
                            
                            <div className='d-block d-lg-none d-md-none'>
                                <h4 className='mobile-sunnybee-h4'>Creating a brand-agnostic banking app for the Future.</h4>
                            </div>
                        </div>
                        <div className='col-lg-3 sunnybee-hero-listitem'>
                            <ul>
                                <li>DIGITAL PRODUCT STRATEGY</li>
                                <li>USER RESEARCH</li>
                                <li>USER INTERFACE DESIGN (UI)</li>
                                <li>USER EXPERIENCE DESIGN (UX)</li>
                                <li>RAPID PROTOTYPING</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            {/* Fut Bank Hero End */}

            <div className='container sunnybee-video1 hide-nav'>
                <img alt="Fingerprints Future" className='img-fluid d-lg-block d-none' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/fut-bank-img-1.png" />
                <img alt="Fingerprints Future" className='img-fluid d-lg-none d-block' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/fut-mob2.png" />
                {/* <video src={Video1} muted autoPlay={"autoplay"} preload="auto" loop playsInline controlsList="nodownload" /> */}
            </div>

            {/* Fut Bank About */}
            <div className='sunnybee-about'>
                <div className="our-approach-gra"></div>
                <div className='container sunnybee-about-cont'>
                    <div className='row'>
                        <div className='col-lg-1 d-lg-block d-md-none'></div>
                        <div className='col-lg-5 col-md-6'>
                            <div>
                                <h4 className='project-text'>What went into the</h4>
                            </div>
                            <div>
                                <h4 className='project-text'>creation of the app?</h4>
                            </div>
                        </div>
                        <div className='col-lg-5 col-md-6'>
                            <div>
                                <p className='project-text'>For FUT Bank, we created a readily accessible banking application that gives both users and banks a seamless experience. A powerful scalable core banking application, it gave the bank the control it needed while making sure the user experience was absolutely smooth and simple.</p>
                                <p className='project-text'>Right from the wire-frame design upwards, we worked on designing an accessible interface that is intuitive and easy to navigate. </p>
                            </div>
                        </div>
                        <div className='col-lg-1 d-lg-block d-md-none'></div>
                    </div>
                </div>
            </div>
            {/* Fut Bank About End */}

            <div className='container sunnybee-about-img'>
                <div className='row futbank-video-img'>
                    <div className='col-lg-6 col-md-6'>
                        {/* <img alt="Fingerprints Future" className='img-fluid' src={Img2} /> */}
                        <video className='project-text' src="https://storage.googleapis.com/fingerprintsfuturewebsite/videos/fut-bank-video-1.mp4" muted autoPlay={"autoplay"} preload="auto" loop playsInline controlsList="nodownload" />
                    </div>
                    <div className='col-lg-6 col-md-6'>
                        <img alt="Fingerprints Future" className='img-fluid project-text'src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/fut-bank-img-3.png" />
                    </div>
                </div>
            </div>

            {/* Fut Bank About */}
            <div className='sunnybee-about'>
                <div className='container sunnybee-about-cont'>
                    <div className='row'>
                        <div className='col-lg-1 d-lg-block d-md-none'></div>
                        <div className='col-lg-7 d-none d-lg-block d-md-block'>
                            <div>
                                <h4 className='project-text'>As a scalable core banking application, Fut Bank </h4>
                            </div>
                            <div>
                                <h4 className='project-text'>app development includes iOS and Android</h4>
                            </div>
                            <div>
                                <h4 className='project-text'>versions, web applications, system emails,</h4>
                            </div>
                            <div>
                                <h4 className='project-text'>marketing materials, and more.</h4>
                            </div>
                        </div>
                        <div className='d-block d-lg-none d-md-none futbank-cont-resp'>
                            <h4 className='project-text'>As a scalable core banking application, Fut Bank app development includes iOS and Android versions, web applications, system emails, marketing materials, and more.</h4>
                        </div>
                        <div className='col-lg-4 d-lg-block d-md-none'></div>
                    </div>
                </div>
            </div>
            {/* Fut Bank About End */}

            <div className='container futbank-img4'>
                <img alt="Fingerprints Future" className='img-fluid project-text d-none d-lg-block' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/fut-bank-img-4.png" />
                <img alt="Fingerprints Future" className='img-fluid project-text d-block d-lg-none' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/fut-mob1.png" />
            </div>

            {/* Fut Bank About */}
            <div className='sunnybee-about'>
                <div className='container sunnybee-about-cont'>
                    <div className='row futbank-ebp'>
                        <div className='col-lg-1 d-lg-block d-md-none'></div>
                        <div className='col-lg-5 col-md-6'>
                            <div>
                                <h4 className='project-text'>Easy Bill Payments</h4>
                            </div>
                        </div>
                        <div className='col-lg-5 col-md-6'>
                            <div>
                                <p className='project-text'>We designed the Fut Bank app such that it provided a unified payment experience with various payment options, reminders,multiple account integration, options to defer payments and more.</p>
                            </div>
                        </div>
                        <div className='col-lg-1 d-lg-block d-md-none'></div>
                    </div>
                </div>
            </div>
            {/* Fut Bank About End */}

            <div className='container futbank-img5 d-none d-lg-block d-md-block'>
                <img alt="Fingerprints Future" className='img-fluid project-text' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/fut-bank-img-5.png" />
            </div>
            <div className='container futbank-img5-resp d-block d-lg-none d-md-none'>
                <img alt="Fingerprints Future" className='img-fluid project-text' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/fut-bank-img-5-resp.png" />
                <h4 className='project-text'>Total Overdue</h4>
                <p className='project-text'>Our System analyses the bills and calculates the total amount overdue for the month.</p>
                
                <h4 className='project-text'>Easy Switch</h4>
                <p className='project-text'>Pay from the bank account you are comfortable with just a tap.</p>
                
                <h4 className='project-text'>Quick Actions</h4>
                <p className='project-text'>Taking advantage of the long press touch feature enables user to set quick actions to a problem.</p>
                
                <h4 className='project-text'>Never miss a bill</h4>
                <p className='project-text'>Add bills to your account and pay on time. </p>
            </div>

            <div className='container futbank-rd'>
                <div className='row savittr-creative-colr'>
                    <div className='col-lg-1 col-md-1'></div>
                    <div className='col-lg-3 col-md-5 align-self-center'>
                        <h4 className='project-text'>Reachability by design</h4>
                        <p className='project-text'>The app was designed with reachability in mind. After studying user behaviour, we made sure all primary actions were placed in a comfortable reach area with just one finger and secondary actions were placed little further. Important information was always displayed at the top.</p>
                    </div>
                    <div className='col-lg-2 col-md-1'></div>
                    <div className='col-lg-5 col-md-5 futbank-img6'>
                        <img alt="Fingerprints Future" className='img-fluid project-text' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/fut-bank-img-6.png" />
                    </div>
                    <div className='col-lg-1'></div>
                </div>
            </div>

            <div className='container futbank-video7'>
                {/* <img alt="Fingerprints Future" className='img-fluid' src={Img7} /> */}
                <video className='project-text'  src="https://storage.googleapis.com/fingerprintsfuturewebsite/videos/fut-bank-video-2.mp4" muted autoPlay={"autoplay"} preload="auto" loop playsInline controlsList="nodownload" />
            </div>

            <div className='container futbank-rd'>
                <div className='row'>
                    <div className='col-lg-1'></div>
                    <div className='col-lg-5 col-md-5 futbank-img8'>
                        <img alt="Fingerprints Future" className='img-fluid project-text' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/fut-bank-img-8.png" />
                    </div>
                    <div className='col-lg-1 col-md-1'></div>
                    <div className='col-lg-3 col-md-5 align-self-center'>
                        <h4 className='project-text'>Light & Dark modes</h4>
                        <p className='project-text'>Apart from functionality, we designed the app for aesthetic appeal as well. We created a dark mode to protect user’s eyes as well as battery life and made it simple enough to switch between with just  a swipe.</p>
                    </div>
                    <div className='col-lg-2 col-md-1'></div>
                </div>
            </div>

            <div className='container sunnybee-about-img'>
                <div className='row futbank-video-img'>
                    <div className='col-lg-6 col-md-6'>
                        {/* <img alt="Fingerprints Future" className='img-fluid' src={Img9} /> */}
                        <video className='project-text'  src="https://storage.googleapis.com/fingerprintsfuturewebsite/videos/fut-bank-video-3.mp4" muted autoPlay={"autoplay"} preload="auto" loop playsInline controlsList="nodownload" />
                    </div>
                    <div className='col-lg-6 col-md-6'>
                        <img alt="Fingerprints Future" className='img-fluid project-text' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/fut-bank-img-10.png" />
                    </div>
                </div>
            </div>

            <div className='container futbank-rd'>
                <div className='row savittr-creative-colr'>
                    <div className='col-lg-1 col-md-1'></div>
                    <div className='col-lg-3 col-md-5 align-self-center'>
                        <h4 className='project-text'>Beneficiary Bank accounts</h4>
                        <p className='project-text'>To make the user experience even simpler, the app was designed to fetch the bank accounts details of user’s beneficiaries instantly with just a mobile number or a social media account. Users could then perform any inter or intra bank payments quickly and easily.</p>
                    </div>
                    <div className='col-lg-2 col-md-1'></div>
                    <div className='col-lg-5 col-md-5 futbank-img11'>
                        <img alt="Fingerprints Future" className='img-fluid project-text' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/fut-bank-img-11.png" />
                    </div>
                    <div className='col-lg-1'></div>
                </div>
            </div>

        </div>


        {/* Work Section */}
        <div className="sunnybee-work">
            <div className="container fixed-work-cont">
                <div className='bdr-top'></div>
                <h4 className='project-text'>You might also like</h4>
                <div className="row home-work-parent">
                    <div className="col-lg-6  home-work-child project-text">
                        <a className="sunnybee-link" href="/work/sunnybee"> </a>
                        <img alt="Fingerprints Future" className="img-fluid" src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/sunnybeex2.png" />
                        <h3>SunnyBee Market</h3>
                        <p>Mobile App</p>
                    </div>
                    <div className="col-lg-6 home-work-child project-text">
                        <a className="savittr-link" href="/work/savittr"> </a>
                        <img alt="Fingerprints Future" className="img-fluid" src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/savittrx2.png" />
                        <h3>Savittr</h3>
                        <p>Wordpress Design & Development</p>
                    </div>
                </div>
            </div>
            {/* <a href="#"><h3 className="view-all">View all case studies</h3></a> */}
        </div>
        {/* Work Section End*/}

      <Footer />
      </>
    )
  }
}
