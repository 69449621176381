import React, { Component } from "react";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
} from "react-share";
import AuthorIcon from "./../assets/blog-author.png";
import SocialShare from "../components/socialShare";
import Footer from "../components/footer";
import GetStartedCard from "../components/getStartedCard";

class NewblogUsingHeuristicsUiUxDesign extends Component {
  render() {
    const recentBlogData = [
      {
        title:
          "AI Isn't Making Us Dumb: The Positive Impact of Chat GPT and Other Technologies",
        publishedOn: "Apr 03, 2023",
        minuteRead: "3",
        imgUrl:
          "https://ik.imagekit.io/fingerprintsfuture/fps/blog-adn-img.png",
        blogUrl: "/blog/ai-dumb-or-not",
      },
      {
        title:
          "Discovering the Magic of Spatial Input: Interacting Naturally with Your Eyes and Hands",
        publishedOn: "Jun 16, 2023",
        minuteRead: "9",
        imgUrl:
          "https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/blog-dtmsi-img.png",
        blogUrl: "/blog/discovering-the-magic-of-spatial-input",
      },
      {
        title:
          "Simple Models, Big Impact: Why Gamification Works in  Digital Product Design",
        publishedOn: "Feb 16, 2023",
        minuteRead: "6",
        imgUrl:
          "https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/blog-smbi-img.png",
        blogUrl: "/blog/why-gamification-works-in-digital-product-design",
      },
    ];

    return (
      <>
        <div className="blog-temp-parent overflow-hidden v2-blog">
          <style>
            {`.inner-page-btn {
                            display: none;
                        }`}
          </style>
          <div className="home-cover-out"></div>
          <div className="container">
            <div className="row">
              <div className="col-lg-8">
                <div className="blog-temp-hero">
                  <div>
                    <a href="/blog" className="back-btn">
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="5"
                          height="9"
                          viewBox="0 0 5 9"
                          fill="none"
                        >
                          <path
                            d="M4.62012 0.879761L1 4.50014L4.62012 8.12026"
                            stroke="#23262F"
                          />
                        </svg>
                      </span>
                      Back
                    </a>
                  </div>

                  <div className="nwv-blog1-hero">
                    <h1>
                      Learning from Mario:
                      <br className="d-none d-lg-block" />
                      Using Heuristics in UX /UI Design
                    </h1>
                    <div className="nwv-blog1-hero-adt">
                      <p>5 Minute Read</p>
                      {/* <span></span>
                      <img
                        className="img-fluid blog-author-img"
                        src={AuthorIcon}
                        alt="Author"
                      />
                      <p>Aishwarya Daswani</p> */}
                      {/* <span></span>
                                        <p>May 03, 2023</p> */}
                    </div>
                    <h2 className="workanimation">
                      Welcome to the world of Mario, where mushrooms are
                      power-ups and turtles are enemies. But wait, what does
                      Mario have to do with UI/UX design? Well, it turns out
                      that the popular video game character can teach us a thing
                      or two about designing user interfaces that are easy to
                      use and navigate.
                    </h2>
                  </div>
                  <div className="main-hero-blog-row">
                    <div className="bth-right-p workanimation">
                      <img
                        alt="Fingerprints Future"
                        src="https://ik.imagekit.io/fingerprintsfuture/fps/blog-uh-img1.png"
                        className="img-fluid"
                      />
                    </div>
                  </div>
                </div>

                <div className="blog-content">
                  <div className="col-lg-12">
                    <div className="blog-line-bdr"></div>

                    <p className="workanimation">
                      In 1995, Jakob Nielsen, a renowned usability expert,
                      published a paper titled "10 Usability Heuristics for User
                      Interface Design." The paper outlines ten guidelines that
                      designers can use to evaluate the usability of user
                      interfaces. These heuristics are still relevant today and
                      are widely used by designers around the world.
                    </p>
                    <br />

                    <p className="workanimation">
                      But let's be honest, reading a research paper can be
                      boring. So, let's take a more fun approach to learning
                      about these heuristics by applying them to Mario. In this
                      blog post, we will take a journey through the Mushroom
                      Kingdom and explore how the principles of UI/UX design can
                      be observed in Mario's world.
                    </p>
                    <br />

                    <div className="blog-du-imgcont p-0">
                      <h2 className="workanimation">
                        So, grab your controller and let's jump into the world
                        of Mario to learn how applying heuristics can improve
                        UI/UX design. 🎮 🍄
                      </h2>
                    </div>
                    <div className="blog-line-bdr"></div>
                    <br />

                    <div className="row">
                      <div className="col-lg-12">
                        <div className="txt-gradi txt-gradi-resp marg-b1">
                          <h3>01 Visibility and Feedback</h3>
                        </div>

                        <p className="workanimation">
                          We have already discussed this heuristic in the
                          introduction, but to summarize, it emphasizes the
                          importance of providing users with clear and visible
                          feedback. In Mario, visibility and feedback are
                          critical to the game's success, as the player needs to
                          understand the game's status to make progress
                        </p>
                        <br />

                        <div className="">
                          <img
                            className="img-fluid w-100"
                            src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/blog-uh-img-r1.png"
                            alt="blog-banner"
                          />
                          <div className="blog-uh-bg-cont">
                            <p>
                              The principle requires clear system status
                              indication and feedback for users. The message{" "}
                              <br className="d-none d-lg-block" /> confirms
                              successful image upload, increasing system
                              visibility. It assures users their action was{" "}
                              <br className="d-none d-lg-block" /> successful,
                              leading to a positive experience.
                            </p>
                          </div>
                        </div>
                        <br />
                        <br />

                        <div className="txt-gradi txt-gradi-resp marg-b1">
                          <h3>02 Match between system and the real world</h3>
                        </div>

                        <p className="workanimation">
                          This heuristic emphasizes the importance of using
                          language, concepts, and actions familiar to users. In
                          Mario, this heuristic is applied in various ways. For
                          example, the game's controls are easy to understand
                          and use, with actions such as "jump" and "run" being
                          intuitive to the player. Similarly, the game uses
                          objects and concepts from the real world, such as
                          coins, to make it easier for players to understand.
                        </p>
                        <br />

                        <div className="">
                          <img
                            className="img-fluid w-100"
                            src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/blog-uh-img-r2.png"
                            alt="blog-img"
                          />
                          <div className="blog-uh-bg-cont">
                            <p>
                              It's important to consider that adding products to
                              a shopping cart is a familiar process for most
                              users. <br className="d-none d-lg-block" />{" "}
                              Therefore, using clear language for this action
                              can help streamline the user experience.
                            </p>
                          </div>
                        </div>
                        <br />
                        <br />

                        <div className="txt-gradi txt-gradi-resp marg-b1">
                          <h3>03 User control and freedom</h3>
                        </div>

                        <p className="workanimation">
                          This heuristic emphasizes the importance of giving
                          users control over the system and the ability to undo
                          actions. In Mario, the player has full control over
                          Mario's movements, and they can undo actions by
                          rewinding time using the game's power-ups. This gives
                          players the freedom to experiment and try different
                          approaches to overcome obstacles.
                        </p>
                        <br />

                        <div className="">
                          <img
                            className="img-fluid w-100 blog-uh-img3"
                            src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/blog-uh-img-r3.png"
                            alt="blog-img"
                          />
                          <div className="blog-uh-bg-cont">
                            <p>
                              By providing users with the ability to move one
                              step back on every screen, they can easily correct
                              mistakes or <br className="d-none d-lg-block" />{" "}
                              explore different options without having to start
                              their search over again. This enhances the user's
                              sense of <br className="d-none d-lg-block" />{" "}
                              control and freedom while using our app, resulting
                              in a more positive and satisfying experience.
                            </p>
                          </div>
                        </div>
                        <br />
                        <br />

                        <div className="txt-gradi txt-gradi-resp marg-b1">
                          <h3>4: Consistency and standards</h3>
                        </div>

                        <p className="workanimation">
                          This heuristic emphasizes the importance of using
                          consistent and standard design elements across the
                          system. In Mario, consistency can be observed in the
                          game's level design, where obstacles and enemies are
                          introduced gradually, allowing players to understand
                          the game's mechanics and adjust accordingly.
                        </p>
                        <br />

                        <div className="">
                          <img
                            className="img-fluid w-auto"
                            src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/blog-uh-img-r4.png"
                            alt="blog-img"
                          />
                          <div className="blog-uh-bg-cont">
                            <p>
                              Implementing clear and concise labels for input
                              fields. For instance, using labels such as 'From'
                              and <br className="d-none d-lg-block" /> 'To' will
                              make it easy for users to understand the required
                              information they need to provide.
                            </p>
                          </div>
                        </div>
                        <br />
                        <br />

                        <div className="txt-gradi txt-gradi-resp marg-b1">
                          <h3>5: Error prevention</h3>
                        </div>

                        <p className="workanimation">
                          This heuristic emphasizes the importance of using
                          consistent and standard design elements across the
                          system. In Mario, consistency can be observed in the
                          game's level design, where obstacles and enemies are
                          introduced gradually, allowing players to understand
                          the game's mechanics and adjust accordingly.
                        </p>
                        <br />

                        <div className="">
                          <img
                            className="img-fluid w-100"
                            src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/blog-uh-img-r5.png"
                            alt="blog-img"
                          />
                          <div className="blog-uh-bg-cont">
                            <p>
                              When deleting an item a confirmation pop-up will
                              appear to prompt the user to confirm the action.
                              This is <br className="d-none d-lg-block" />{" "}
                              important for any destructive or significant
                              actions, such as deletion, and ensures users are
                              always asked <br className="d-none d-lg-block" />{" "}
                              to confirm before proceeding.
                            </p>
                          </div>
                        </div>
                        <br />
                        <br />

                        <div className="txt-gradi txt-gradi-resp marg-b1">
                          <h3>6: Recognition rather than recall </h3>
                        </div>

                        <p className="workanimation">
                          This heuristic emphasizes the importance of designing
                          systems that do not rely on users' memory to complete
                          tasks. In Mario, this is achieved through the game's
                          level design, where obstacles and enemies are visible
                          to the player, allowing them to react quickly and
                          avoid them.
                        </p>
                        <br />

                        <div className="">
                          <img
                            className="img-fluid w-100"
                            src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/blog-uh-img-r6.png"
                            alt="blog-img"
                          />
                          <div className="blog-uh-bg-cont">
                            <p>
                              Providing real-time suggestions that appear
                              instantly, enabling the user to quickly recognize
                              and <br className="d-none d-lg-block" /> select
                              their desired search query, resulting in a faster
                              and more efficient search experience.
                            </p>
                          </div>
                        </div>
                        <br />
                        <br />

                        <div className="txt-gradi txt-gradi-resp marg-b1">
                          <h3>7: Flexibility and efficiency of use</h3>
                        </div>

                        <p className="workanimation">
                          This heuristic emphasizes the importance of designing
                          systems that cater to both novice and expert users. In
                          Mario, this is achieved through the game's power-ups,
                          which allow novice players to progress through the
                          game, while expert players can use them to complete
                          levels quickly and efficiently.
                        </p>
                        <br />

                        <div className="">
                          <img
                            className="img-fluid w-100"
                            src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/blog-uh-img-r7.png"
                            alt="blog-img"
                          />
                          <div className="blog-uh-bg-cont">
                            <p>
                              The screenshot exemplifies the flexibility and
                              efficiency of the app, particularly for power
                              users. It <br className="d-none d-lg-block" />{" "}
                              showcases how users can customize their products
                              and add them to their Buzz Lists, which can then{" "}
                              <br className="d-none d-lg-block" /> be ordered
                              with a single click, streamlining the ordering
                              process.
                            </p>
                          </div>
                        </div>
                        <br />
                        <br />

                        <div className="txt-gradi txt-gradi-resp marg-b1">
                          <h3>8: Aesthetic and minimalist design</h3>
                        </div>

                        <p className="workanimation">
                          This heuristic emphasizes the importance of designing
                          systems that are visually appealing and free of
                          clutter. In Mario, the game's graphics are simple and
                          minimalist, allowing players to focus on the gameplay
                          and providing a seamless user experience
                        </p>
                        <br />

                        <div className="">
                          <img
                            className="img-fluid w-auto"
                            src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/blog-uh-img-r8.png"
                            alt="blog-img"
                          />
                          <div className="blog-uh-bg-cont">
                            <p>
                              The screenshot above shows a hero section that is
                              designed with minimalistic and clutter-free
                              elements, <br className="d-none d-lg-block" />{" "}
                              featuring only two clear call-to-action buttons.
                              This strategic design enables users to focus
                              solely on the <br className="d-none d-lg-block" />{" "}
                              section we intend them to see and perform an
                              action, resulting in a streamlined user
                              experience.
                            </p>
                          </div>
                        </div>
                        <br />
                        <br />

                        <div className="txt-gradi txt-gradi-resp marg-b1">
                          <h3>
                            {" "}
                            9: Help users recognize, diagnose, and recover from
                            errors
                          </h3>
                        </div>

                        <p className="workanimation">
                          This heuristic emphasizes the importance of designing
                          systems that help users understand and recover from
                          errors. In Mario, this is achieved through the game's
                          power-ups, which allow players to rewind time and
                          retry levels, helping them learn from their mistakes.
                        </p>
                        <br />

                        <div className="">
                          <img
                            className="img-fluid w-100"
                            src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/blog-uh-img-r9.png"
                            alt="blog-img"
                          />
                          <div className="blog-uh-bg-cont">
                            <p>
                              The error message should explain what went wrong,
                              suggest the correct format, and provide a way to
                              correct <br className="d-none d-lg-block" /> the
                              error and resubmit the form. This will help users
                              recognize the error, diagnose the problem, and
                              recover <br className="d-none d-lg-block" /> from
                              it by providing the correct information.
                            </p>
                          </div>
                        </div>
                        <br />
                        <br />

                        <div className="txt-gradi txt-gradi-resp marg-b1">
                          <h3> 10: Help and documentation</h3>
                        </div>

                        <p className="workanimation">
                          This heuristic emphasizes the importance of providing
                          users with help and documentation when needed. In
                          Mario, this is achieved through the game's tutorials
                          and manual, which provide players with information on
                          the game's mechanics and controls.
                        </p>
                        <br />

                        <div className="">
                          <img
                            className="img-fluid w-100"
                            src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/blog-uh-img-r10.png"
                            alt="blog-img"
                          />
                          <div className="blog-uh-bg-cont">
                            <p>
                              The app's highlighters serve as useful guides for
                              users, aiding in their navigation and
                              understanding of{" "}
                              <br className="d-none d-lg-block" /> how to use
                              the application effectively. This feature not only
                              helps users to learn the application quickly{" "}
                              <br className="d-none d-lg-block" /> but also
                              makes the navigation process much smoother.
                            </p>
                          </div>
                        </div>
                        {/* <br/> */}
                      </div>
                    </div>

                    <div className="blog-line-bdr marg-tb"></div>

                    <div className="txt-gradi marg-b">
                      <h3>
                        From Gaming to Mobile: How Mario's Heuristics Can Be{" "}
                        <br className="d-none d-xl-block d-lg-block" /> Applied
                        to Mobile UI/UX Design
                      </h3>
                    </div>

                    <div className="row blog-uh-cards">
                      <div className="col-md-6 blog-du-card position-relative">
                        <img
                          className=""
                          src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/blog-uh-card-bg.png"
                          alt="blog-card-img"
                        />
                        <div className="blog-du-cardcont">
                          <div>
                            <h4>01</h4>
                          </div>
                          <h4 className="text-black">
                            Design for One-Handed Use
                          </h4>
                          <p>
                            In Mario, the game is designed to be played with
                            both hands, with the left hand controlling Mario's
                            movement and the right hand controlling his actions.
                            Similarly, in mobile UI/UX design, it is important
                            to design interfaces that can be easily used with
                            one hand. Designers can achieve this by placing
                            important controls and buttons within easy reach of
                            the user's thumb.
                          </p>
                        </div>
                      </div>
                      <div className="col-md-6 blog-du-card position-relative">
                        <img
                          className=""
                          src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/blog-uh-card-bg.png"
                          alt="blog-card-img"
                        />
                        <div className="blog-du-cardcont">
                          <div>
                            <h4>02</h4>
                          </div>
                          <h4>Prioritize Important Information</h4>
                          <p>
                            In Mario, important information such as the player's
                            score, lives remaining, and time remaining to
                            complete the level are displayed prominently on the
                            screen. Similarly, in mobile UI/UX design, designers
                            should prioritize important information and ensure
                            that it is easily accessible to the user. This can
                            be achieved by using large, bold fonts, and
                            contrasting colors to draw the user's attention.
                          </p>
                        </div>
                      </div>
                      <div className="col-md-6 blog-du-card position-relative">
                        <img
                          className=""
                          src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/blog-uh-card-bg.png"
                          alt="blog-card-img"
                        />
                        <div className="blog-du-cardcont">
                          <div>
                            <h4>03</h4>
                          </div>
                          <h4>Use Simple Navigation</h4>
                          <p>
                            In Mario, the game is designed with simple
                            navigation, with Mario moving from left to right,
                            jumping over obstacles and enemies. Similarly, in
                            mobile UI/UX design, designers should use simple
                            navigation that is easy to understand and use. This
                            can be achieved by using clear and concise
                            navigation labels, minimizing the number of clicks
                            required to navigate the interface, and using a
                            consistent navigation structure across the
                            application.
                          </p>
                        </div>
                      </div>
                      <div className="col-md-6 blog-du-card position-relative">
                        <img
                          className=""
                          src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/blog-uh-card-bg.png"
                          alt="blog-card-img"
                        />
                        <div className="blog-du-cardcont">
                          <div>
                            <h4>04</h4>
                          </div>
                          <h4>Use Gestures</h4>
                          <p>
                            In Mario, players use gestures to control Mario's
                            movement and actions, such as swiping left or right
                            to move or tapping to jump. Similarly, in mobile
                            UI/UX design, designers should use gestures to
                            enhance the user experience. This can be achieved by
                            using gestures such as swiping, pinching, and
                            tapping to navigate the interface or perform
                            actions.
                          </p>
                        </div>
                      </div>
                      <div className="col-md-6 blog-du-card position-relative">
                        <img
                          className=""
                          src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/blog-uh-card-bg.png"
                          alt="blog-card-img"
                        />
                        <div className="blog-du-cardcont">
                          <div>
                            <h4>05</h4>
                          </div>
                          <h4>Use Visual Hierarchy</h4>
                          <p>
                            In Mario, visual hierarchy is used to draw the
                            player's attention to important elements, such as
                            power-ups or enemies. Similarly, in mobile UI/UX
                            design, designers should use visual hierarchy to
                            help users navigate the interface and understand the
                            importance of different elements. This can be
                            achieved by using contrasting colors, larger fonts,
                            and bold text to highlight important information.
                          </p>
                        </div>
                      </div>
                      <div className="col-md-6 blog-du-card position-relative">
                        <img
                          className=""
                          src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/blog-uh-card-bg.png"
                          alt="blog-card-img"
                        />
                        <div className="blog-du-cardcont">
                          <div>
                            <h4>06</h4>
                          </div>
                          <h4>Optimize for Speed</h4>
                          <p>
                            In Mario, the game is designed to be fast-paced,
                            with players having to react quickly to avoid
                            obstacles and enemies. Similarly, in mobile UI/UX
                            design, designers should optimize the interface for
                            speed and ensure that it is fast and responsive.
                            This can be achieved by minimizing the number of
                            images and videos used in the interface, optimizing
                            code and reducing load times.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="blog-line-bdr marg-tb"></div>

                    <div className="txt-gradi marg-b1">
                      <h3>
                        Mario's Lessons in UI/UX Design: Applying Heuristics to{" "}
                        <br
                          className="d-none d-xl-block d-lg-block"
                          alt="blog-card-img"
                        />{" "}
                        Create Accessible Interfaces.{" "}
                      </h3>
                    </div>
                    {/* <br /> */}

                    <div className="blog-du-imgcont p-0">
                      <h2 className="workanimation">
                        Heuristics play an essential role in UI/UX design, and
                        their application can result in interfaces that are
                        intuitive, easy to use, and visually appealing. Whether
                        it's designing for desktop, mobile, or accessibility,
                        applying heuristics can improve the user experience and
                        make interfaces accessible to a wider audience. And what
                        better way to learn about heuristics than through the
                        iconic game character, Mario?
                      </h2>
                    </div>
                    {/* <br/> */}

                    <div className="blog-du-imgcont p-0">
                      <h2 className="workanimation">
                        By analyzing Mario's game design, we can see how the 10
                        Usability Heuristics for User Interface Design can be
                        applied to game design and, more importantly, how they
                        can be applied to UI/UX design. We can also see how
                        Mario's game design can be applied to mobile UI/UX
                        design, making interfaces that are optimized for mobile
                        devices and accessible to a wider audience.
                      </h2>
                    </div>
                    {/* <br/> */}

                    <div className="blog-du-imgcont p-0">
                      <h2 className="workanimation">
                        So, whether you're designing a game or a mobile
                        application, remember to apply heuristics to your UI/UX
                        design. By doing so, you can create interfaces that are
                        easy to use, visually appealing, and accessible to
                        everyone.
                      </h2>
                    </div>
                    <br />
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="v2-blog-sidebar">
                  <div className="v2-blog-sidebar-cont">
                    <div className="v2-blog-sidebar-tags">
                      <h4>Tags</h4>
                      <div className="">
                        <span className="">UI design</span>
                        <span className="">UX DESIGN</span>
                        <span className="">ARTIFICIAL INTELLIGENCE</span>
                        <span className="">WEB DEVELOPMENT</span>
                        <span className="">FRONTEND DEVELOPMENT</span>
                        <span className="">USER INTERFACE</span>
                        <span className="">USER EXPERIENCE</span>
                        <span className="">RESPONSIVE DESIGN</span>
                      </div>
                    </div>

                    <div className="v2-blog-sidebar-posts">
                      <h4>POPULAR POSTS</h4>
                      <div className="all-posts">
                        {recentBlogData.map((blog, index) => (
                          <a
                            href={blog.blogUrl}
                            key={index}
                            className="post-container"
                          >
                            <img src={blog.imgUrl} alt="post-banner" />
                            <p>
                              {blog.title}
                              <br />
                              {/* <span>{blog.publishedOn}</span> */}
                              <span>{blog.minuteRead} Minute read</span>
                            </p>
                          </a>
                        ))}
                      </div>
                    </div>

                    <div className="v2-blog-sidebar-social">
                      <h4>SHARE</h4>
                      <div className="">
                        <div className="socials-container">
                          <FacebookShareButton
                            url={
                              "https://fingerprintsfuture.com/blog/using-heuristics-ui-ux-design"
                            }
                            description={
                              "Learning from Mario: Using Heuristics in UX /UI Design"
                            }
                            className="Demo__some-network__share-button"
                          >
                            <FacebookIcon size={36.67} round />
                          </FacebookShareButton>
                          <TwitterShareButton
                            title={
                              "Learning from Mario: Using Heuristics in UX /UI Design"
                            }
                            url={
                              "https://fingerprintsfuture.com/blog/using-heuristics-ui-ux-design"
                            }
                          >
                            <TwitterIcon size={36.67} round />
                          </TwitterShareButton>
                          <LinkedinShareButton
                            title={
                              "Learning from Mario: Using Heuristics in UX /UI Design"
                            }
                            url={
                              "https://fingerprintsfuture.com/blog/using-heuristics-ui-ux-design"
                            }
                          >
                            <LinkedinIcon size={36.67} round />
                          </LinkedinShareButton>
                        </div>
                      </div>
                    </div>
                    <div className="">
                      <GetStartedCard />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

export default NewblogUsingHeuristicsUiUxDesign;
