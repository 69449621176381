import React, { Component } from 'react'
import Footer from '../components/footer'
import {Helmet} from "react-helmet";
import gsap from 'gsap'
import SplitText from 'gsap/SplitText'
import ScrollTrigger from 'gsap/ScrollTrigger'



export default class sunnybee extends Component {

    projectGsap = () =>{
        gsap.registerPlugin(ScrollTrigger, SplitText)
        // gsap.registerPlugin(ScrollTrigger);

        // if there are objects that may get inline styles added (like via tweens) that should get reverted, use ScrollTrigger.saveStyles() initially so that the current inline styles are saved for later reversion. It's not always necessary, but it goes well with ScrollTrigger.matchMedia() so we figured it'd make sense to show it here (it's not needed in this demo)
        ScrollTrigger.saveStyles(".mobile, .desktop");

        /*** Different ScrollTrigger setups for various screen sizes (media queries) ***/
        ScrollTrigger.matchMedia({
            
            // desktop
            "(min-width: 800px)": function() {

                
                gsap.set(".sunnybee-hero", {height: "100vh"});
                gsap.to(".sunnybee-hero", {
                  height: "80vh",
                  delay: 1,
                  ease: "Power3.easeOut",
                  duration: 1
                });
                
            }, 
        
            // mobile
            "(max-width: 768px)": function() {

                gsap.set(".sunnybee-hero", {height: "100vh"});
                gsap.to(".sunnybee-hero", {
                    height: "70vh",
                    delay: 1,
                    ease: "Power3.easeOut",
                    duration: 1
                });
            }, 
        
            // all 
            "all": function() {
                
                gsap.from("h2.line span", 0.9, {
                    y: 180,
                    rotateZ: 6,
                    ease: "Power3.easeOut",
                    delay: 0.7,
                    stagger: {
                      amount: 0.2
                    }
                  })
                  gsap.from(".mobile-sunnybee-h4", 0.9, {
                    y: 40,
                    opacity: 0,
                    ease: "Power3.easeOut",
                    delay: 0.6,
                    stagger: {
                      amount: 0.2
                    }
                  })
                  gsap.set(".sunnybee-hero-listitem li", { visibility: "hidden" });
                  ScrollTrigger.batch(".sunnybee-hero-listitem li", {
                      onEnter: batch => {
                          gsap.from(batch, {
                              duration: 1,
                              opacity: 0,
                              delay: 1.2,
                              yPercent: 100,
                              scale: 1,
                              autoAlpha: 0,
                              ease: "Power3.easeOut",
                              stagger: 0.1,
                              visibility: "visible",
                          })
                      },
                      toggleActions: "play none none none",
                      once: true,
                      markers: false,
                  });
                  gsap.set(".sunnybee-cover", {opacity: 1});
                  gsap.to(".sunnybee-cover", {
                    opacity: 0,
                    delay: 0,
                    ease: "Power3.easeIn",
                    duration: 0.3
                  });
                  gsap.set(".sunnybee-heading", {opacity: 0});
                  gsap.to(".sunnybee-heading", {
                    opacity: 1,
                    delay: 0.1,
                    ease: "Power3.easeIn",
                    duration: 1
                  });
                  gsap.set(".sunnybee-hero-cont", {"margin-bottom": 0});
                  gsap.to(".sunnybee-hero-cont", {
                    "margin-bottom": 90,
                    delay: 1,
                    ease: "Power3.linear",
                    duration: 1.5
                  });
                  gsap.set(".our-approach-gra", {xPercent: 100});
                  gsap.to(".our-approach-gra", {
                    xPercent: 0,
                    delay: 2,
                    ease: "Power3.easeOut",
                    duration: 1.5
                  });
                  gsap.set(".project-text", { visibility: "hidden" });
                  ScrollTrigger.batch(".project-text", {
                      onEnter: batch => {
                          gsap.from(batch, {
                              duration: 1.5,
                              delay: 0,
                              yPercent: 50,
                              scale: 1,
                              autoAlpha: 0,
                              ease: "Power3.easeOut",
                              stagger: 0.4,
                              visibility: "visible",
                          })
                      },
                      toggleActions: "play none none none",
                      once: true,
                      start: 'top 80%',
                      end: 'top 40%',
                      markers: false,
                  });
        
                  gsap.set(".bdr-top", {width: "0%"});
                  gsap.to(".bdr-top", {
                    width: "100%",
                    ease: "Power3.easeOut",
                    duration: 1,
                    scrollTrigger:{
                        trigger: ".bdr-top",
                        start: "top 80%"
                    }
                  });
            }
        
        });


        //Hero

        // gsap.from(".normal-header", 0.9, {
        //     opacity: 0,
        //     ease: "Power3.easeOut",
        //     delay: 3.5,
        //   })


    }
    

    componentDidMount(){
        this.projectGsap();
        
    }


  render() {
    return (
      <>
        <Helmet>
            <title>UI UX Design for SunnyBee App & Website | Fingerprints Future</title>
            <meta name="description" content="Complete e-commerce UI/UX design, website design, and app design for Sunny Bee Mobile App. Check out our design case study for the Sunny Bee mobile app" />
        </Helmet>
        
        <style>
            {
                `.home-work-parent h3 {
                    color: #000;
                }`
            }
        </style>
      <div className="home-cover-out"></div>
        <div className='overflow-hidden'>
            <div className='sunnybee-cover'></div>

            {/* Sunnybee Hero */}
            <div className='sunnybee-hero'>
                {/* <div className="our-approach-gra"></div> */}

                {/* <div className="d-block d-lg-none our-approach-gra-resp"></div> */}
                <div className='container sunnybee-hero-cont'>
                    <div className='row'>
                        <div className='col-lg-9'>
                            <div>
                                <h2 className='sunnybee-heading'>SunnyBee Market</h2>
                            </div>
                            <div className='d-none d-lg-block d-md-block'>
                                <div>
                                    <h2 className='line'><span>Taking a physical retail store </span></h2>
                                </div>
                                <div>
                                    <h2 className='line'><span>to the Future and meeting its </span></h2>
                                </div>
                                <div>
                                    <h2 className='line'><span>customers there.</span></h2>
                                </div>
                            </div>
                            <div className='d-block d-lg-none d-md-none'>
                                <h4 className='mobile-sunnybee-h4'>Taking a physical retail store to the Future and meeting its customers there.</h4>
                            </div>
                        </div>
                        <div className='col-lg-3 sunnybee-hero-listitem'>
                            <ul>
                                <li>DIGITAL PRODUCT STRATEGY</li>
                                <li>USER RESEARCH</li>
                                <li>USER INTERFACE DESIGN (UI)</li>
                                <li>USER EXPERIENCE DESIGN (UX)</li>
                                <li>RAPID PROTOTYPING</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            {/* Sunnybee Hero End */}

            <div className='container sunnybee-video1 hide-nav'>
                <video src="https://storage.googleapis.com/fingerprintsfuturewebsite/videos/sunnybee-1-comp.mp4" muted autoPlay={"autoplay"} preload="auto" loop playsInline controlsList="nodownload" />
            </div>

            {/* Sunnybee About */}
            <div className='sunnybee-about'>
                {/* <div className="d-none d-lg-block sunnybee-gra1"></div> */}
                {/* <div className="d-block d-lg-none sunnybee-gra1-reps"></div> */}
                <div className='container sunnybee-about-cont'>
                    <div className='row'>
                        <div className='col-lg-1'></div>
                        <div className='col-lg-5 col-md-6'>
                            <div>
                                <h4 className='project-text'>What went into the</h4>
                            </div>
                            <div>
                                <h4 className='project-text'>creation of the app?</h4>
                            </div>
                        </div>
                        <div className='col-lg-5 col-md-6'>
                            <div>
                                <p className='project-text'>During the pandemic, when people were unwilling to step out of their homes, we brought the store to them through a simple, secure and easily navigable mobile app. </p>
                                <br className='d-none d-xl-block' />
                                <p className='project-text'>With a light weight design and AI enabled search, users could browse through thousands of products, pay securely and quickly with our cutting edge wallet system  and have their purchases shipped straight to their door.</p>
                            </div>
                        </div>
                        <div className='col-lg-1'></div>
                    </div>
                </div>
            </div>
            {/* Sunnybee About End */}

            <div className='container sunnybee-about-img'>
                <div className='row'>
                    <div className='col-lg-6 col-md-6'>
                        {/* <img alt="Fingerprints Future" className='img-fluid img-2' src={Img2} /> */}
                        <video className='project-text' src="https://storage.googleapis.com/fingerprintsfuturewebsite/videos/sunnybee-7-comp.mp4" muted autoPlay={"autoplay"} preload="auto" loop playsInline controlsList="nodownload" />
                    </div>
                    <div className='col-lg-6 col-md-6'>
                        <img alt="Fingerprints Future" className='img-fluid project-text' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/sunnybee-img-3.png" />
                    </div>
                    {/* <div className='col-lg-12 sunnybee-about-img4'>
                        <img alt="Fingerprints Future" src={Img4} />
                    </div> */}
                </div>
            </div>
        </div>

        <div className='sunnybee-ai'>
            <div className='container sunnybee-ai-cont'>
                <div className='row'>
                    <div className='col-lg-7 col-md-6'>
                        <img alt="Fingerprints Future" className='project-text' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/sunnybee-img-5.png" />
                    </div>
                    <div className='col-lg-4 col-md-5 align-self-center'>
                        <h4 className='project-text'>AI Powered Search</h4>
                        <p className='project-text'>Search was one of the core functionalities we built into the app. In fact, we built AI into the search which helped users find exactly what they were looking for.</p>
                    </div>
                    <div className='col-lg-1 col-md-1'></div>
                </div>
            </div>
        </div>

        <div className='sunnybee-qe'>
            <div className='container sunnybee-qe-video2 d-block d-lg-none d-md-none'>
                <video className='project-text' src="https://storage.googleapis.com/fingerprintsfuturewebsite/videos/sunnybee-order-place-comp.mp4" muted autoPlay={"autoplay"} preload="auto" loop playsInline controlsList="nodownload" />
            </div>
            {/* <img alt="Fingerprints Future" src={Img6} /> */}
            <div className='sunnybee-qep'>
                <div className='sunnybee-qep-cont'>
                    <h4 className='project-text'>Quick & Easy Payments</h4>
                    <p className='project-text'>The wallet was built within the app to make the payments <br className='d-none d-lg-block d-md-block' /> quicker and easier. Users could load their wallet with money  <br className='d-none d-lg-block d-md-block' /> and checkout instantly with just a tap.</p>
                    <div className='sunnybee-qep-img d-none d-lg-block'>
                        <div className='sunnybee-qep-txt'>
                            <p className='project-text'>Reduce user’s time by <br /> suggesting amount to top up.</p>
                        </div>
                        <img alt="Fingerprints Future" className='img-fluid project-text' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/sunnybee-img-qep-2.png" />
                        <img alt="Fingerprints Future" className='img-fluid project-text' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/sunnybee-img-qep-1.png" />
                        <img alt="Fingerprints Future" className='img-fluid project-text' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/sunnybee-img-qep-3.png" />
                    </div>
                    <div className='d-block d-lg-none sunnybee-qep-img-resp'>
                        <img alt="Fingerprints Future" className='project-text' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/sunnybee-img-qep-resp.png" />
                    </div>
                </div>
            </div>
 
            <div className='container sunnybee-qe-video2 d-none d-lg-block d-md-block'>
                <video className='project-text' src="https://storage.googleapis.com/fingerprintsfuturewebsite/videos/sunnybee-order-place-comp.mp4" muted autoPlay={"autoplay"} preload="auto" loop playsInline controlsList="nodownload" />
            </div>
        </div>

        <div className='sunnybee-ai'>
            <div className='container sunnybee-ai-cont'>
                <div className='row sunnybee-ai-colr'>
                    <div className='col-lg-1 col-md-1'></div>
                    <div className='col-lg-4 col-md-5 align-self-center'>
                        <h4 className='project-text'>Reachability</h4>
                        <p className='project-text'>Primary actions like search, cart and checkout were designed keeping the size of phone screens in mind. To make the user experience seamless, all important actions were placed at the very bottom of the screen for one handed operation.</p>
                    </div>
                    <div className='col-lg-1 d-md-none d-lg-block'></div>
                    <div className='col-lg-6 col-md-6'>
                        <img alt="Fingerprints Future" className='project-text'  src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/sunnybee-img-8.png" />
                    </div>
                </div>
            </div>
        </div>

        <div className='container sunnybee-video1'>
            <video className='project-text' src="https://storage.googleapis.com/fingerprintsfuturewebsite/videos/sunnybee-add-cart-comp.mp4" muted autoPlay={"autoplay"} preload="auto" loop playsInline controlsList="nodownload" />

            <video className='sunnybee-video4 project-text' src="https://storage.googleapis.com/fingerprintsfuturewebsite/videos/sunnybee-4-ff-comp.mp4" muted autoPlay={"autoplay"} preload="auto" loop playsInline controlsList="nodownload" />

            <div className='row sunnybee-video5-6'>
                <div className='col-lg-6 col-md-6'>
                    <video className='video5 project-text' src="https://storage.googleapis.com/fingerprintsfuturewebsite/videos/sunnybee-5-1-comp.mp4" muted autoPlay={"autoplay"} preload="auto" loop playsInline controlsList="nodownload" />
                </div>
                <div className='col-lg-6 col-md-6'>
                <video className='video5 project-text' src="https://storage.googleapis.com/fingerprintsfuturewebsite/videos/sunnybee-place-comp.mp4" muted autoPlay={"autoplay"} preload="auto" loop playsInline controlsList="nodownload" />
                </div>
            </div>
        </div>

        {/* Work Section */}
        <div className="sunnybee-work">
            <div className="container fixed-work-cont">
                <div className='bdr-top'></div>
                <h4 className='project-text'>You might also like</h4>
                <div className="row home-work-parent">
                        <div className="col-lg-6 home-work-child project-text">
                                <a className="authorcafe-link" href="/work/authorcafe"></a>
                                <img alt="Fingerprints Future" className="img-fluid" src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/authorcafe-thumbx2.png" />
                                <h3>Authorcafe</h3>
                                <p>Website Design & Development</p>
                            </div>

                    <div className="col-lg-6 home-work-child project-text">
                        <a className="dealsplus-product-link" href="/work/dealsplus-product"></a>
                        <img alt="Fingerprints Future" className="img-fluid" src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/dealsplus-website-cover-img-nw.png" />
                        <h3>Deals Plus</h3>
                        <p>Product Design</p>
                    </div>
                </div>
            </div>
            {/* <a href="#"><h3 className="view-all">View all case studies</h3></a> */}
        </div>
        {/* Work Section End*/}
      
      <Footer />
      </>
    )
  }
}
