import React, { Component } from 'react'
import Footer from '../components/footer'
import {Helmet} from "react-helmet";
import gsap from 'gsap'
import SplitText from 'gsap/SplitText'
import ScrollTrigger from 'gsap/ScrollTrigger'



export default class dealsplusP extends Component {
    projectGsap = () =>{
        gsap.registerPlugin(ScrollTrigger, SplitText)

        //Hero

        // gsap.from(".normal-header", 0.9, {
        //     opacity: 0,
        //     ease: "Power3.easeOut",
        //     delay: 3.5,
        //   })
          gsap.from("h2.line span", 0.9, {
            y: 180,
            rotateZ: 6,
            ease: "Power3.easeOut",
            delay: 0.7,
            stagger: {
              amount: 0.2
            }
          })
          gsap.from(".mobile-sunnybee-h4", 0.9, {
            y: 40,
            opacity: 0,
            ease: "Power3.easeOut",
            delay: 1.5,
            stagger: {
              amount: 0.2
            }
          })
          gsap.set(".sunnybee-hero", {height: "100vh"});
          gsap.to(".sunnybee-hero", {
            height: "80vh",
            delay: 1,
            ease: "Power3.easeOut",
            duration: 1
          });
          gsap.set(".sunnybee-hero-listitem li", { visibility: "hidden" });
          ScrollTrigger.batch(".sunnybee-hero-listitem li", {
              onEnter: batch => {
                  gsap.from(batch, {
                      duration: 1,
                      opacity: 0,
                      delay: 1.2,
                      yPercent: 100,
                      scale: 1,
                      autoAlpha: 0,
                      ease: "Power3.easeOut",
                      stagger: 0.1,
                      visibility: "visible",
                  })
              },
              toggleActions: "play none none none",
              once: true,
              markers: false,
          });
          gsap.set(".sunnybee-cover", {opacity: 1});
          gsap.to(".sunnybee-cover", {
            opacity: 0,
            delay: 0,
            ease: "Power3.easeIn",
            duration: 0.3
          });
          gsap.set(".sunnybee-heading", {opacity: 0});
          gsap.to(".sunnybee-heading", {
            opacity: 1,
            delay: 0.1,
            ease: "Power3.easeIn",
            duration: 1
          });
          gsap.set(".sunnybee-hero-cont", {"margin-bottom": 0});
          gsap.to(".sunnybee-hero-cont", {
            "margin-bottom": 90,
            delay: 1,
            ease: "Power3.linear",
            duration: 1.5
          });
          gsap.set(".our-approach-gra", {xPercent: 100});
          gsap.to(".our-approach-gra", {
            xPercent: 0,
            delay: 2,
            ease: "Power3.easeOut",
            duration: 1.5
          });
          gsap.set(".project-text", { visibility: "hidden" });
          ScrollTrigger.batch(".project-text", {
              onEnter: batch => {
                  gsap.from(batch, {
                      duration: 1.5,
                      delay: 0,
                      yPercent: 50,
                      scale: 1,
                      autoAlpha: 0,
                      ease: "Power3.easeOut",
                      stagger: 0.4,
                      visibility: "visible",
                  })
              },
              toggleActions: "play none none none",
              once: true,
              start: 'top 80%',
              end: 'top 40%',
              markers: false,
          });

          gsap.set(".bdr-top", {width: "0%"});
          gsap.to(".bdr-top", {
            width: "100%",
            ease: "Power3.easeOut",
            duration: 1,
            scrollTrigger:{
                trigger: ".bdr-top",
                start: "top 80%"
            }
          });



    }
    

    componentDidMount(){
        this.projectGsap();
        
    }

  render() {
    return (
      <>
        <Helmet>
            <title>Design for Deals Plus Track - UI UX Product Design | Fingerprints Future</title>
            <meta name="description" content="Product design for a fund management company. Responsive product design case study, vernacular design principles, complete responsive design guidelines for mobile app." />
        </Helmet>

        
        <style>
            {
                `.home-work-parent h3 {
                    color: #000;
                }`
            }
        </style>

        <div className="home-cover-out"></div>
        <div className='overflow-hidden'>
            <div className='sunnybee-cover'></div>
            {/* Dealsplus Product Hero */}
            <div className='sunnybee-hero'>
            {/* <div className="our-approach-gra"></div> */}
                <div className='container sunnybee-hero-cont'>
                    <div className='row'>
                    <div className='col-lg-9'>
                            <div>
                                <h2 className='sunnybee-heading'>Deals Plus Product</h2>
                            </div>
                            <div className='d-none d-lg-block d-md-block'>
                                <div>
                                    <h2 className='line'><span>A Future-ready app for all </span></h2>
                                </div>
                                <div>
                                    <h2 className='line'><span>capital investments</span></h2>
                                </div>
                            </div>
                            <div className='d-block d-lg-none d-md-none'>
                                <h4 className='mobile-sunnybee-h4'>A Future-ready app for all capital investments</h4>
                            </div>
                        </div>
                        <div className='col-lg-3 sunnybee-hero-listitem'>
                            <ul>
                                <li>User Research</li>
                                <li>Personas</li>
                                <li>User Flows</li>
                                <li>Wire-framing</li>
                                <li>User Experience Design (UX)</li>
                                <li>User Interface Design (UI)</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            {/* Dealsplus Product Hero End */}

            <div className='container sunnybee-video1 hide-nav'>
                <img alt="Fingerprints Future" className='img-fluid d-lg-block d-none' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/dealsplus-product-img-1-new.png" />
                <img alt="Fingerprints Future" className='img-fluid d-lg-none d-block' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/dealsplusproduct-mob1.png" />
                {/* <video src={Video1} muted autoPlay={"autoplay"} preload="auto" loop playsInline controlsList="nodownload" /> */}
            </div>

            {/* Dealsplus Product About */}
            <div className='sunnybee-about'>
                <div className="d-none d-lg-block sunnybee-gra1"></div>
                <div className="d-block d-lg-none sunnybee-gra1-reps"></div>
                <div className='container sunnybee-about-cont'>
                    <div className='row dealsplus-resp-cont1'>
                        <div className='col-lg-1 d-lg-block d-md-none'></div>
                        <div className='col-lg-5 col-md-6'>
                            <div>
                                <h4 className='project-text'>What went into the</h4>
                            </div>
                            <div>
                                <h4 className='project-text'>creation of the app?</h4>
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-6'>
                            <div>
                                <p className='project-text'>When we gave the brand website a revamp, we couldn’t leave out the app. In order for there to be complete synergy, we redesigned it to reflect the new brand aesthetic, restructured the navigation and made various improvements to the product to make it more intuitive and user-friendly.</p>
                            </div>
                        </div>
                        <div className='col-lg-2 d-lg-block d-md-none'></div>
                    </div>
                </div>
            </div>
            {/* Dealsplus Product About End */}

            <div className='container sunnybee-about-img'>
                <div className='row'>
                    <div className='col-lg-6 col-md-6'>
                        <img alt="Fingerprints Future" className='img-fluid dealsplusP-img2-resp project-text' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/dealsplus-product-img-2x2.png" />
                    </div>
                    <div className='col-lg-6 col-md-6'>
                        <img alt="Fingerprints Future" className='img-fluid project-text' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/dealsplus-product-img-3x2.png" />
                    </div>
                </div>
            </div>

            {/* Dealsplus Product About */}
            <div className='sunnybee-about'>
                <div className='container sunnybee-about-cont'>
                    <div className='row'>
                        <div className='col-lg-1 d-lg-block d-md-none'></div>
                        <div className='col-lg-5 col-md-6'>
                            <div>
                                <h4 className='project-text'>Dashboard</h4>
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-6'>
                            <div>
                                <p className='project-text'>At the Future we don’t just work with what we have but are always looking for ways to make things better. We designed a new home screen for the Deals Plus app that serves as a hub for users to easily access important information, view all deals in one central location and initiate deals. Users could now quickly and efficiently navigate the system and take action on deals.</p>
                            </div>
                        </div>
                        <div className='col-lg-2 d-lg-block d-md-none'></div>
                    </div>
                </div>
            </div>
            {/* Dealsplus Product About End */}

            <div className='container'>
                <div className='row dealsplusP-img4-5'>
                    <div className='col-lg-12 dealsplusP-img4'>
                        <img alt="Fingerprints Future" className='img-fluid project-text d-lg-block d-none' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/dealsplus-product-img-4x2.png" />
                        <img alt="Fingerprints Future" className='img-fluid d-lg-none d-block project-text' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/dealsplusproduct-mob2.png" />
                    </div>
                    <div className='col-lg-12 dealsplusP-video1'>
                        {/* <img alt="Fingerprints Future" className='img-fluid' src={Img5} /> */}
                        <video className='project-text' src="https://storage.googleapis.com/fingerprintsfuturewebsite/videos/dealsplusP-video-1.mp4" muted autoPlay={"autoplay"} preload="auto" loop playsInline controlsList="nodownload" />
                    </div>
                </div>
            </div>

            {/* Dealsplus Product About */}
            <div className='sunnybee-about'>
                <div className='container sunnybee-about-cont'>
                    <div className='row'>
                        <div className='col-lg-1 d-lg-block d-md-none'></div>
                        <div className='col-lg-5 col-md-6'>
                            <div>
                                <h4 className='project-text'>Widgets that are</h4>
                            </div>
                            <div>
                                <h4 className='project-text'>customizable</h4>
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-6'>
                            <div>
                                <p className='project-text'>We also included customisable widgets  that allowed users to tailor the dashboard according to their needs. They could add, remove, and arrange the widgets to have quick and easy access to the data and insights they needed to make informed decisions.</p>
                            </div>
                        </div>
                        <div className='col-lg-2 d-lg-block d-md-none'></div>
                    </div>
                </div>
            </div>
            {/* Dealsplus Product About End */}

            <div className='container'>
                <div className='row'>
                    <div className='col-lg-12 dealsplusP-img  bg-gray'>
                        <img alt="Fingerprints Future" className='img-fluid project-text w-100' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/dealsplus-product-img-6-1.png" />
                        {/* <img alt="Fingerprints Future" className='img-fluid img6 project-text' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/dealsplus-product-img-6.svg" />
                        <img alt="Fingerprints Future" className='img-fluid img8 project-text' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/dealsplus-product-img-8.svg" />
                        <img alt="Fingerprints Future" className='img-fluid img7 project-text' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/dealsplus-product-img-7.svg" />
                        <img alt="Fingerprints Future" className='img-fluid img9 project-text' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/dealsplus-product-img-9.svg" />
                        <img alt="Fingerprints Future" className='img-fluid img10 project-text' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/dealsplus-product-img-10.svg" /> */}
                    </div>
                    {/* <div className='d-block d-lg-none bg-gray'>
                        <img alt="Fingerprints Future" className='img-fluid dealsplusP-img6-resp project-text' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/dealsplus-product-img-6-reps.svg" />
                    </div> */}
                    <div className='col-lg-12 dealsplusP-img11'>
                        <img alt="Fingerprints Future" className='img-fluid project-text d-lg-block d-none' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/dealsplus-product-img-11-1.svg" />
                        <img alt="Fingerprints Future" className='img-fluid d-lg-none d-block project-text' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/dealsplusproduct-mob3.png" />
                    </div>
                </div>
            </div>
        </div>


        {/* Work Section */}
        <div className="sunnybee-work">
            <div className="container fixed-work-cont">
                <div className='bdr-top'></div>
                <h4 className='project-text'>You might also like</h4>
                <div className="row home-work-parent">
                    <div className="col-lg-6 home-work-child project-text">
                                <a className="fut-bank-link" href="/work/fut-bank"></a>
                                <img alt="Fingerprints Future" className="img-fluid" src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/futx2.png" />
                                <h3>Fut Bank</h3>
                                <p>Product Design</p>
                            </div>
                            <div className="col-lg-6  home-work-child project-text">
                                <a className="dealsplus-website-link" href="/work/dealsplus-website"></a>
                                <img alt="Fingerprints Future" className="img-fluid" src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/dealsplus-website-cover-img-nw.png" />
                                <h3>Deals Plus</h3>
                                <p>Website Design & Development</p>
                            </div>
                </div>
            </div>
            {/* <a href="#"><h3 className="view-all">View all case studies</h3></a> */}
        </div>
        {/* Work Section End*/}

      <Footer />
      </>
    )
  }
}
