import React, { Component } from "react";
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton, FacebookIcon, LinkedinIcon, TwitterIcon } from "react-share";

class ShareButton extends Component {
  render() {
    return (
      <div className="socials-container">
        <FacebookShareButton
          url={"https://peing.net/ja/"}
          description={"aiueo"}
          className="Demo__some-network__share-button"
        >
          <FacebookIcon size={36.67} round />
        </FacebookShareButton>
        <TwitterShareButton
          title={"test"}
          url={"https://peing.net/ja/"}
          hashtags={["hashtag1", "hashtag2"]}
        >
          <TwitterIcon size={36.67} round />
        </TwitterShareButton>
        <LinkedinShareButton
          title={"test"}
          url={"https://peing.net/ja/"}
          hashtags={[" ", "hashtag2"]}
        >
          <LinkedinIcon size={36.67} round />
        </LinkedinShareButton>
      </div>
    );
  }
}
class SocialShare extends Component {
  render() {
    return (
      <div>
        <ShareButton />
      </div>
    );
  }
}
export default SocialShare;