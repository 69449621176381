import React, { Component } from 'react'
import Footer from '../components/footer'
import gsap from 'gsap'
import SplitText from 'gsap/SplitText'
import ScrollTrigger from 'gsap/ScrollTrigger'
// import Img8 from '../assets/authorcafe-img-8.png'
// import Img9 from '../assets/authorcafe-img-9.png'
// import Img10 from '../assets/authorcafe-img-10.png'
// import Img11 from '../assets/authorcafe-img-11.png'
// import Img12 from '../assets/authorcafe-img-12.png'


export default class authorcafe extends Component {
    projectGsap = () =>{
        gsap.registerPlugin(ScrollTrigger, SplitText)

        //Hero

        // gsap.from(".normal-header", 0.9, {
        //     opacity: 0,
        //     ease: "Power3.easeOut",
        //     delay: 3.5,
        //   })
          gsap.from("h2.line span", 0.9, {
            y: 180,
            rotateZ: 6,
            ease: "Power3.easeOut",
            delay: 0.7,
            stagger: {
              amount: 0.2
            }
          })
          gsap.from(".mobile-sunnybee-h4", 0.9, {
            y: 40,
            opacity: 0,
            ease: "Power3.easeOut",
            delay: 1.5,
            stagger: {
              amount: 0.2
            }
          })
          gsap.set(".sunnybee-hero", {height: "100vh"});
          gsap.to(".sunnybee-hero", {
            height: "80vh",
            delay: 1,
            ease: "Power3.easeOut",
            duration: 1
          });
          gsap.set(".sunnybee-hero-listitem li", { visibility: "hidden" });
          ScrollTrigger.batch(".sunnybee-hero-listitem li", {
              onEnter: batch => {
                  gsap.from(batch, {
                      duration: 1,
                      opacity: 0,
                      delay: 1.2,
                      yPercent: 100,
                      scale: 1,
                      autoAlpha: 0,
                      ease: "Power3.easeOut",
                      stagger: 0.1,
                      visibility: "visible",
                  })
              },
              toggleActions: "play none none none",
              once: true,
              markers: false,
          });
          gsap.set(".sunnybee-cover", {opacity: 1});
          gsap.to(".sunnybee-cover", {
            opacity: 0,
            delay: 0,
            ease: "Power3.easeIn",
            duration: 0.3
          });
          gsap.set(".sunnybee-heading", {opacity: 0});
          gsap.to(".sunnybee-heading", {
            opacity: 1,
            delay: 0.1,
            ease: "Power3.easeIn",
            duration: 1
          });
          gsap.set(".sunnybee-hero-cont", {"margin-bottom": 0});
          gsap.to(".sunnybee-hero-cont", {
            "margin-bottom": 90,
            delay: 1,
            ease: "Power3.linear",
            duration: 1.5
          });
          gsap.set(".our-approach-gra", {xPercent: 100});
          gsap.to(".our-approach-gra", {
            xPercent: 0,
            delay: 2,
            ease: "Power3.easeOut",
            duration: 1.5
          });
          gsap.set(".project-text", { visibility: "hidden" });
          ScrollTrigger.batch(".project-text", {
              onEnter: batch => {
                  gsap.from(batch, {
                      duration: 1.5,
                      delay: 0,
                      yPercent: 50,
                      scale: 1,
                      autoAlpha: 0,
                      ease: "Power3.easeOut",
                      stagger: 0.4,
                      visibility: "visible",
                  })
              },
              toggleActions: "play none none none",
              once: true,
              start: 'top 80%',
              end: 'top 40%',
              markers: false,
          });

          gsap.set(".bdr-top", {width: "0%"});
          gsap.to(".bdr-top", {
            width: "100%",
            ease: "Power3.easeOut",
            duration: 1,
            scrollTrigger:{
                trigger: ".bdr-top",
                start: "top 80%"
            }
          });



    }
    

    componentDidMount(){
        this.projectGsap();
        
    }


  render() {
    return (
      <>

      
        <style>
            {
                `.home-work-parent h3 {
                    color: #000;
                }`
            }
        </style>

      <div className="home-cover-out"></div>
        <div className='overflow-hidden'>
            <div className='sunnybee-cover'></div>
            {/* Fut Bank Hero */}
            <div className='sunnybee-hero'>
            {/* <div className="our-approach-gra"></div> */}
                <div className='container sunnybee-hero-cont'>
                    <div className='row'>
                        <div className='col-lg-9'>
                            <div>
                                <h2 className='sunnybee-heading'>Authorcafe</h2>
                            </div>
                            <div className='d-none d-lg-block d-md-block'>
                                <div>
                                    <h2 className='line'><span>Redesign for a website that’s</span></h2>
                                </div>
                                <div>
                                    <h2 className='line'><span>designed for academic research.​</span></h2>
                                </div>
                            </div>
                            
                            <div className='d-block d-lg-none d-md-none'>
                                <h4 className='mobile-sunnybee-h4'>Redesign for a website that’s designed for academic research.​</h4>
                            </div>
                        </div>
                        <div className='col-lg-3 sunnybee-hero-listitem'>
                            <ul>
                                <li>USER RESEARCH</li>
                                <li>Personas</li>
                                <li>User Flows</li>
                                <li>Wire-framing</li>
                                <li>USER EXPERIENCE DESIGN (UX)</li>
                                <li>USER INTERFACE DESIGN (UI)</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            {/* Fut Bank Hero End */}

            <div className='container sunnybee-video1 hide-nav'>
                <img className='img-fluid d-none d-lg-block' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/authorcafe-img-1x2.png" alt="Future" />
                <img className='img-fluid d-block d-lg-none' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/authorcafe-mob1.png" alt="Future" />
                {/* <video src={Video1} muted autoPlay={"autoplay"} preload="auto" loop playsInline controlsList="nodownload" /> */}
            </div>

            {/* Fut Bank About */}
            <div className='sunnybee-about'>
                <div className="d-none d-lg-block sunnybee-gra1"></div>
                <div className="d-block d-lg-none sunnybee-gra1-reps"></div>
                <div className='container sunnybee-about-cont'>
                    <div className='row'>
                        <div className='col-lg-1 d-lg-block d-md-none'></div>
                        <div className='col-lg-5 col-md-6'>
                            <div>
                                <h4 className='project-text'>What went into the</h4>
                            </div>
                            <div>
                                <h4 className='project-text'>creation of the Website?</h4>
                            </div>
                        </div>
                        <div className='col-lg-5 col-md-6'>
                            <div>
                                <p className='project-text'>To reposition, redesign, and market the product in new ways. The new website had to be optimized for both computer and mobile presence. It had to be designed in a way to build credibility for the brand and with features that enable marketing activities for the product and also ensure more signups for the free and paid version of Author Cafe.</p>
                            </div>
                        </div>
                        <div className='col-lg-1 d-lg-block d-md-none'></div>
                    </div>
                </div>
            </div>
            {/* Fut Bank About End */}

            <div className='container sunnybee-about-img'>
                <div className='row authorcafe-video-img'>
                    <div className='col-lg-12 authorcafe-video1'>
                    <video className='project-text' src="https://storage.googleapis.com/fingerprintsfuturewebsite/videos/authorcafe-video-1.mp4" muted autoPlay={"autoplay"} preload="auto" loop playsInline controlsList="nodownload" />
                    </div>
                    <div className='col-lg-12'>
                        <img className='img-fluid project-text d-none d-lg-block' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/authorcafe-img-3x2.png" alt="Future" />
                        <img className='img-fluid project-text d-block d-lg-none' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/authorcafe-mob2.png" alt="Future" />
                    </div>
                </div>
            </div>

            {/* Fut Bank About */}
            <div className='sunnybee-about'>
                <div className='container sunnybee-about-cont'>
                    <div className='row futbank-ebp'>
                        <div className='col-lg-1 d-lg-block d-md-none'></div>
                        <div className='col-lg-5 col-md-6'>
                            <div>
                                <h4 className='project-text'>Mobile First Approach</h4>
                            </div>
                        </div>
                        <div className='col-lg-5 col-md-6'>
                            <div>
                                <p className='project-text'>We made the decision to prioritize mobile users by implementing a mobile-first design philosophy. This approach was driven by our analytics data, which revealed that the majority of our website traffic was coming from mobile devices. By designing for small screens and touch interfaces first, we were able to ensure an optimal user experience for our mobile users, before adapting the design for larger screens and desktop devices</p>
                            </div>
                        </div>
                        <div className='col-lg-1 d-lg-block d-md-none'></div>
                    </div>
                </div>
            </div>
            {/* Fut Bank About End */}

            <div className='container sunnybee-about-img'>
                <div className='row authorcafe-video-img'>
                    <div className='col-lg-6 col-md-6 authorcafe-img4'>
                        <img className='img-fluid project-text' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/authorcafe-img-4x2.png"alt="Future" />
                    </div>
                    <div className='col-lg-6 col-md-6'>
                        <video className='project-text' src="https://storage.googleapis.com/fingerprintsfuturewebsite/videos/authorcafe-video-2.mp4" muted autoPlay={"autoplay"} preload="auto" loop playsInline controlsList="nodownload" />
                    </div>
                    
                    <div className='col-lg-12 authorcafe-img6'>
                        <img className='img-fluid project-text d-none d-lg-block' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/authorcafe-img-6x2.png"alt="Future" />
                        <img className='img-fluid project-text d-block d-lg-none' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/authorcafe-mob3.png" alt="Future" />
                    </div>
                </div>
            </div>

            {/* Fut Bank About */}
            <div className='sunnybee-about'>
                <div className='container sunnybee-about-cont'>
                    <div className='row futbank-ebp'>
                        <div className='col-lg-1 d-lg-block d-md-none'></div>
                        <div className='col-lg-5 col-md-6 d-none d-lg-block'>
                            <div>
                                <h4 className='project-text'>A distinctive </h4>
                            </div>
                            <div>
                                <h4 className='project-text'>artistic style</h4>
                            </div>
                        </div>
                        <div className='col-lg-5 col-md-6 d-block d-lg-none'>
                            <div>
                                <h4 className='project-text'>A distinctive artistic style</h4>
                            </div>
                        </div>
                        <div className='col-lg-5 col-md-6'>
                            <div>
                                <p className='project-text'>We created customized illustrations in a mix of different scenarios to represent the content in a visually engaging manner. This helped us to effectively convey the applications features while also creating a distinct experience for its users</p>
                            </div>
                        </div>
                        <div className='col-lg-1 d-lg-block d-md-none'></div>
                    </div>
                </div>
            </div>
            {/* Fut Bank About End */}

            <div className='container authorcafe-video3'>
                <video className='project-text' src="https://storage.googleapis.com/fingerprintsfuturewebsite/videos/authorcafe-video-3.mp4.mp4" muted autoPlay={"autoplay"} preload="auto" loop playsInline controlsList="nodownload" />
            </div>

            {/* Fut Bank About */}
            <div className='sunnybee-about'>
                <div className='container sunnybee-about-cont'>
                    <div className='row futbank-ebp'>
                        <div className='col-lg-1 d-lg-block d-md-none'></div>

                        <div className='col-lg-5 col-md-6 d-none d-lg-block'>
                            <div>
                                <h4 className='project-text'>Customised</h4>
                            </div>
                            <div>
                                <h4 className='project-text'>Feature Cards</h4>
                            </div>
                        </div>
                        <div className='col-lg-5 col-md-6 d-block d-lg-none'>
                            <div>
                                <h4 className='project-text'>Customised Feature Cards</h4>
                            </div>
                        </div>
                        <div className='col-lg-5 col-md-6'>
                            <div>
                                <p className='project-text'>We also made a conscious effort to present the key features of our product in a visually-appealing manner, with the aim of highlighting their unique value to the user. This helped to effectively communicate the product's capabilities and benefits to our audience.</p>
                            </div>
                        </div>
                        <div className='col-lg-1 d-lg-block d-md-none'></div>
                    </div>
                </div>
            </div>
            {/* Fut Bank About End */}

            <div className='dealsplusW-sitecore'>
              <div className='container'>
                  <div className='row'>
                      <div className='col-lg-12 authorcafe-img'>
                        <img className='img-fluid project-text' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/author-cafe-multipng.png" alt="Future" />
                          {/* <img className='img-fluid img8 project-text' src={Img8} alt="Future" />
                          <img className='img-fluid img11 project-text' src={Img11} alt="Future" />
                          <img className='img-fluid img9 project-text' src={Img9} alt="Future" />
                          <img className='img-fluid img10 project-text' src={Img10} alt="Future" />
                          <img className='img-fluid img12 project-text' src={Img12} alt="Future" /> */}
                      </div>
                      <div className='d-block d-lg-none'>
                          {/* <img className='img-fluid dealsplusP-img6-resp project-text' src={ImgResp6} alt="Future" /> */}
                      </div>
                  </div>
              </div>

              <div className='container savittr-creative-contress'>
                  <div className='row'>
                      <div className='col-lg-1 d-lg-block d-md-none'></div>
                      <div className='col-lg-2 col-md-3 savittr-img6'>
                          <img className='img-fluid project-text' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/authorcafe-img-13x2.png" alt="Future" />
                      </div>
                      <div className='col-lg-2 col-md-3 savittr-img7'>
                          <img className='img-fluid project-text' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/authorcafe-img-14x2.png"alt="Future" />
                      </div>
                      <div className='col-lg-2 d-lg-block d-md-none'></div>
                      <div className='col-lg-4 col-md-6 align-self-center'>
                          <h4 className='project-text'>Bringing the brand to life</h4>
                          <p className='project-text'>Everything from the brand colours to the fonts and brand tone of voice was recreated to be in line with the new brand identity. A new tone of voice was identified and then used uniformly, to give the brand a consistent sound as well as an easily identifiable look. </p>
                      </div>
                      <div className='col-lg-1 d-lg-block d-md-none'></div>
                  </div>
              </div>

              <div className='container savittr-img8'>
                  <div className='row'>
                      <div className='col-lg-1'></div>
                      <div className='col-lg-10'>
                          <img className='img-fluid project-text' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/authorcafe-img-15.svg" alt="Future" />
                      </div>
                      <div className='col-lg-1'></div>
                  </div>
              </div>
            </div>

        </div>


        {/* Work Section */}
        <div className="sunnybee-work">
            <div className="container fixed-work-cont">
                <div className='bdr-top'></div>
                <h4 className='project-text'>You might also like</h4>
                <div className="row home-work-parent">
                <div className="col-lg-6 home-work-child project-text">
                                <a className="savittr-link" href="/work/savittr"></a>
                                <img alt="Fingerprints Future" className="img-fluid" src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/savittrx2.png" />
                                <h3>Savittr</h3>
                                <p>Wordpress Design & Development</p>
                            </div>
                            <div className="col-lg-6 home-work-child project-text">
                                <a className="dealsplus-product-link" href="/work/dealsplus-product"></a>
                                <img alt="Fingerprints Future" className="img-fluid" src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/dealsplus-website-cover-img-nw.png" />
                                <h3>Deals Plus</h3>
                                <p>Product Design</p>
                            </div>
                </div>
            </div>
            {/* <a href="#"><h3 className="view-all">View all case studies</h3></a> */}
        </div>
        {/* Work Section End*/}

      <Footer />
      </>
    )
  }
}
