import React, { Component } from 'react'
import Footer from '../components/footer'
import {Helmet} from "react-helmet";
import gsap from 'gsap'
import SplitText from 'gsap/SplitText'
import ScrollTrigger from 'gsap/ScrollTrigger'

export default class CryptoWallet extends Component {

    projectGsap = () =>{
        gsap.registerPlugin(ScrollTrigger, SplitText)

        //Hero

        // gsap.from(".normal-header", 0.9, {
        //     opacity: 0,
        //     ease: "Power3.easeOut",
        //     delay: 3.5,
        //   })
          gsap.from("h2.line span", 0.9, {
            y: 180,
            rotateZ: 6,
            ease: "Power3.easeOut",
            delay: 0.7,
            stagger: {
              amount: 0.2
            }
          })
          gsap.from(".mobile-sunnybee-h4", 0.9, {
            y: 40,
            opacity: 0,
            ease: "Power3.easeOut",
            delay: 1.5,
            stagger: {
              amount: 0.2
            }
          })
          gsap.set(".sunnybee-hero", {height: "100vh"});
          gsap.to(".sunnybee-hero", {
            height: "80vh",
            delay: 1,
            ease: "Power3.easeOut",
            duration: 1
          });
          gsap.set(".sunnybee-hero-listitem li", { visibility: "hidden" });
          ScrollTrigger.batch(".sunnybee-hero-listitem li", {
              onEnter: batch => {
                  gsap.from(batch, {
                      duration: 1,
                      opacity: 0,
                      delay: 1.2,
                      yPercent: 100,
                      scale: 1,
                      autoAlpha: 0,
                      ease: "Power3.easeOut",
                      stagger: 0.1,
                      visibility: "visible",
                  })
              },
              toggleActions: "play none none none",
              once: true,
              markers: false,
          });
          gsap.set(".sunnybee-cover", {opacity: 1});
          gsap.to(".sunnybee-cover", {
            opacity: 0,
            delay: 0,
            ease: "Power3.easeIn",
            duration: 0.3
          });
          gsap.set(".sunnybee-heading", {opacity: 0});
          gsap.to(".sunnybee-heading", {
            opacity: 1,
            delay: 0.1,
            ease: "Power3.easeIn",
            duration: 1
          });
          gsap.set(".sunnybee-hero-cont", {"margin-bottom": 0});
          gsap.to(".sunnybee-hero-cont", {
            "margin-bottom": 90,
            delay: 1,
            ease: "Power3.linear",
            duration: 1.5
          });
          gsap.set(".our-approach-gra", {xPercent: 100});
          gsap.to(".our-approach-gra", {
            xPercent: 0,
            delay: 2,
            ease: "Power3.easeOut",
            duration: 1.5
          });
          gsap.set(".project-text", { visibility: "hidden" });
          ScrollTrigger.batch(".project-text", {
              onEnter: batch => {
                  gsap.from(batch, {
                      duration: 1.5,
                      delay: 0,
                      yPercent: 50,
                      scale: 1,
                      autoAlpha: 0,
                      ease: "Power3.easeOut",
                      stagger: 0.4,
                      visibility: "visible",
                  })
              },
              toggleActions: "play none none none",
              once: true,
              start: 'top 80%',
              end: 'top 40%',
              markers: false,
          });

          gsap.set(".bdr-top", {width: "0%"});
          gsap.to(".bdr-top", {
            width: "100%",
            ease: "Power3.easeOut",
            duration: 1,
            scrollTrigger:{
                trigger: ".bdr-top",
                start: "top 80%"
            }
          });



    }
    

    componentDidMount(){
        this.projectGsap();
        
    }

    render() {
        return (
            <>

            
            <style>
                {
                    `.home-work-parent h3 {
                        color: #000;
                    }`
                }
            </style>

            <div className="home-cover-out"></div>
            <div className='overflow-hidden'>
                <div className='sunnybee-cover'></div>
                {/* Savittr Hero */}
                <div className='sunnybee-hero'>
                    {/* <div className="our-approach-gra"></div> */}
                    <div className='container sunnybee-hero-cont'>
                        <div className='row'>
                            <div className='col-lg-9'>
                                <div>
                                    <h2 className='sunnybee-heading'>Crypto Wallet</h2>
                                </div>
                                <div className='d-none d-lg-block d-md-block'>
                                    <div>
                                        <h2 className='line'><span>Securely Manage Your Digital</span></h2>
                                    </div>
                                    <div>
                                        <h2 className='line'><span>Assets with our Crypto Wallet</span></h2>
                                    </div>
                                    <div>
                                        <h2 className='line'><span></span></h2>
                                    </div>
                                </div>
                                <div className='d-block d-lg-none d-md-none'>
                                    <h4 className='mobile-sunnybee-h4'>Securely Manage Your Digital Assets with our Crypto Wallet</h4>
                                </div>
                            </div>
                            <div className='col-lg-3 sunnybee-hero-listitem'>
                                <ul>
                                    <li>USER RESEARCH</li>
                                    <li>USER INTERFACE DESIGN (UI)</li>
                                    <li>USER EXPERIENCE DESIGN (UX)</li>
                                    <li>Rapid Prototyping</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Savittr Hero End */}

                <div className='container sunnybee-video1 hide-nav '>
                    <img alt="Fingerprints Future" className='img-fluid d-none d-lg-block' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/cw-img-1.png"  />
                    <img alt="Fingerprints Future" className='img-fluid d-block d-lg-none' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/cw-img-resp1.png"  />
                    {/* <img alt="Fingerprints Future" className='img-fluid d-lg-none d-block ' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/ewallet-img-1-m2.png"  /> */}
                    {/* <video src={Video1} muted autoPlay={"autoplay"} preload="auto" loop playsInline controlsList="nodownload" /> */}
                </div>

                {/* Savittr About */}
                <div className='sunnybee-about'>
                    <div className="d-none d-lg-block sunnybee-gra1"></div>
                    <div className="d-block d-lg-none sunnybee-gra1-reps"></div>
                    <div className='container sunnybee-about-cont'>
                        <div className='row'>
                            <div className='col-lg-1 d-lg-block d-md-none'></div>
                            <div className='col-lg-5 col-md-6'>
                                <div>
                                    <h4 className='project-text'>What went into the</h4>
                                </div>
                                <div>
                                    <h4 className='project-text'>creation of the app?</h4>
                                </div>
                            </div>
                            <div className='col-lg-5 col-md-6'>
                                <div>
                                    <p className='project-text'>In our crypto wallet app, we have created a user-friendly and secure platform for managing and transacting with various cryptocurrencies. Our app enables users to easily and securely send and receive cryptocurrencies, view their transaction history, and manage their digital assets from a single, convenient location.</p>
                                </div>
                            </div>
                            <div className='col-lg-1 d-lg-block d-md-none'></div>
                        </div>
                    </div>
                </div>
                {/* Savittr About End */}

                <div className='container savittr-video3 '>
                    <div className='d-none d-lg-block text-center'>
                        <video className='project-text' src="https://storage.googleapis.com/fingerprintsfuturewebsite/videos/cw-video-1-2.mp4" muted autoPlay={"autoplay"} preload="auto" loop playsInline controlsList="nodownload" />
                    </div>
                    <div className='d-block d-lg-none'>
                        <video className='project-text p-0' src="https://storage.googleapis.com/fingerprintsfuturewebsite/videos/cw-video-resp1.mp4" muted autoPlay={"autoplay"} preload="auto" loop playsInline controlsList="nodownload" />
                    </div>
                    
                </div>

                <div className='container futbank-rd ew-resp'>
                    <div className='row savittr-creative-colr'>
                        <div className='col-lg-1 col-md-1'></div>
                        <div className='col-lg-4 col-md-5 align-self-center'>
                            <h4 className='project-text'>Holdings</h4>
                            <p className='project-text'>The Holdings screen designed to be intuitive and easy to use, even for those who are new to the world of cryptocurrencies. With a clean and simple interface, users can easily view their account balance, track their transaction history, and send or receive cryptocurrencies with just a few clicks.</p>
                        </div>
                        <div className='col-lg-1 col-md-1'></div>
                        <div className='col-lg-5 col-md-5 futbank-img6 resp-img-width'>
                            <img alt="Fingerprints Future" className='img-fluid project-text' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/cw-img-3.png" />
                        </div>
                        <div className='col-lg-1'></div>
                    </div>
                </div>

                <div className='container sunnybee-about-img'>
                    <div className='row authorcafe-video-img'>
                        <div className='col-lg-12 authorcafe-img6'>
                            <img className='img-fluid project-text d-none d-lg-block' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/cw-img-4.png"alt="Future" />
                            <img className='img-fluid project-text d-block d-lg-none' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/cw-img-resp2.png"alt="Future" />
                            {/* <img className='img-fluid project-text d-block d-lg-none' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/ewallet-img-5-m2.png" alt="Future" /> */}
                        </div>
                    </div>
                </div>

                <div className='container futbank-rd ew-resp'>
                    <div className='row savittr-creative-colr'>
                        <div className='col-lg-1 col-md-1'></div>
                        <div className='col-lg-4 col-md-5 align-self-center'>
                            <h4 className='project-text'>One-click trading</h4>
                            <p className='project-text'>Our crypto wallet app also offers a convenient one-click trading feature that allows users to quickly and easily execute trades with a single tap or click. This feature eliminates the need for users to navigate multiple screens or input a series of commands to complete a trade. With just one tap, users can quickly buy or sell cryptocurrencies at the current market price, or set limit orders for more precise trades</p>
                        </div>
                        <div className='col-lg-1 col-md-1'></div>
                        <div className='col-lg-5 col-md-5 futbank-img6 resp-img-width savittr-video3'>
                            {/* <img alt="Fingerprints Future" className='img-fluid project-text' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/cw-img-5.png" /> */}
                            <video className='project-text' src="https://storage.googleapis.com/fingerprintsfuturewebsite/videos/cw-video-2.mp4" muted autoPlay={"autoplay"} preload="auto" loop playsInline controlsList="nodownload" />
                        </div>
                        <div className='col-lg-1'></div>
                    </div>
                </div>

                <div className='container'>
                    <img alt="Fingerprints Future" className='img-fluid project-text d-none d-lg-block' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/cw-img-6.png" />
                    <img alt="Fingerprints Future" className='img-fluid project-text d-block d-lg-none' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/cw-img-resp3.png" />
                </div>

                <div className='sunnybee-about'>
                    <div className='container sunnybee-about-cont'>
                        <div className='row'>
                            <div className='col-lg-1 d-lg-block d-md-none'></div>
                            <div className='col-lg-5 col-md-6'>
                                <div className='d-none d-lg-block'>
                                    <h4 className='project-text'>Multi Device </h4>
                                </div>
                                <div className='d-none d-lg-block'>
                                    <h4 className='project-text'>Compatibility</h4>
                                </div>
                                <div className='d-block d-lg-none'>
                                    <h4 className='project-text'>Multi Device Compatibility</h4>
                                </div>
                            </div>
                            <div className='col-lg-5 col-md-6'>
                                <div>
                                    <p className='project-text'>We have ensured that the application's user interface is consistent across all devices, including desktops, tablets, and smartphones. This consistency ensures that users can easily navigate the application regardless of which device they are using.</p>
                                </div>
                            </div>
                            <div className='col-lg-1 d-lg-block d-md-none'></div>
                        </div>
                    </div>
                </div>

                <div className='container futbank-rd ew-resp'>
                    <div className='row'>
                        <div className='col-lg-1'></div>
                        <div className='col-lg-5 col-md-5 futbank-img8 resp-img-width savittr-video3'>
                            {/* <img alt="Fingerprints Future" className='img-fluid project-text' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/cw-img-7.png" /> */}
                            <video className='project-text' src="https://storage.googleapis.com/fingerprintsfuturewebsite/videos/cw-video-3.mp4" muted autoPlay={"autoplay"} preload="auto" loop playsInline controlsList="nodownload" />
                        </div>
                        <div className='col-lg-1 col-md-1'></div>
                        <div className='col-lg-4 col-md-5 align-self-center'>
                            <h4 className='project-text'>Notifications</h4>
                            <p className='project-text'>By customizing notifications, users can receive the updates that matter most to them, helping them to make informed investment decisions. These notifications are delivered in real-time, ensuring that users are always up-to-date with the latest market trends and developments.</p>
                        </div>
                        <div className='col-lg-1 col-md-1'></div>
                    </div>
                </div>

                <div className='container futbank-rd ew-resp'>
                    <div className='row savittr-creative-colr'>
                        <div className='col-lg-1 col-md-1'></div>
                        <div className='col-lg-4 col-md-5 align-self-center'>
                            <h4 className='project-text'>Onboarding</h4>
                            <p className='project-text'>Our onboarding process is designed to be user-friendly, allowing users to create an account quickly and easily. We use industry-standard encryption protocols to protect user data during the onboarding process and throughout their use of the application.</p>
                        </div>
                        <div className='col-lg-1 col-md-1'></div>
                        <div className='col-lg-5 col-md-5 futbank-img6 resp-img-width'>
                            <img alt="Fingerprints Future" className='img-fluid project-text' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/cw-img-8.png" />
                        </div>
                        <div className='col-lg-1'></div>
                    </div>
                </div>

                <div className='container'>
                    <img alt="Fingerprints Future" className='img-fluid project-text d-none d-lg-block' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/cw-img-9.png"  />
                    <img alt="Fingerprints Future" className='img-fluid project-text d-block d-lg-none' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/cw-img-resp4.png"  />
                </div>

                <div className='sunnybee-about'>
                    <div className='container sunnybee-about-cont'>
                        <div className='row'>
                            <div className='col-lg-1 d-lg-block d-md-none'></div>
                            <div className='col-lg-5 col-md-6'>
                                <div>
                                    <h4 className='project-text'>Light and dark mode</h4>
                                </div>
                            </div>
                            <div className='col-lg-5 col-md-6'>
                                <div>
                                    <p className='project-text'>Our application offers both light and dark mode options, allowing users to customize their viewing experience based on their preferences. The light mode is perfect for users who prefer a traditional, brighter interface, while the dark mode is ideal for those who prefer a more subdued, low-light interface.</p>
                                </div>
                            </div>
                            <div className='col-lg-1 d-lg-block d-md-none'></div>
                        </div>
                    </div>
                </div>

            </div>

            <Footer />
            </>
        )
    }
}
