import React, { Component } from 'react'
import {Helmet} from "react-helmet";
import Lottie from "lottie-react";
import Footer from '../components/footer'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'

import ResearchJson from '../assets/magnifier icon-02.json'
import DesignJson from '../assets/square icons.json'
import BuildJson from '../assets/open bracket-02.json'


export default class process extends Component {

    processGsap = () =>{

        gsap.registerPlugin(ScrollTrigger)

        //Basic
        gsap.set(".sunnybee-cover", {opacity: 1});
        gsap.to(".sunnybee-cover", {
          opacity: 0,
          delay: 0,
          ease: "Power3.easeIn",
          duration: 0.3
        });

        gsap.set(".workanimation", { visibility: "hidden" });
        ScrollTrigger.batch(".workanimation", {
            onEnter: batch => {
                gsap.from(batch, {
                    duration: 1.5,
                    delay: 0,
                    yPercent: 50,
                    scale: 1,
                    autoAlpha: 0,
                    ease: "Power3.easeOut",
                    stagger: 0.4,
                    visibility: "visible",
                })
            },
            toggleActions: "play none none none",
            once: true,
            start: 'top 80%',
            end: 'top 40%',
            markers: false,
        });

        // Hero
          gsap.from(".normal-header", 0.9, {
            opacity: 0,
            ease: "Power3.easeOut",
            delay: 0.5,
          })
          gsap.from("h1.line span", 0.9, {
            y: 180,
            rotateZ: 6,
            ease: "Power3.easeOut",
            delay: 0.6,
            stagger: {
              amount: 0.15
            }
          })
          gsap.set(".about-hero-gra", {xPercent: 100});
          gsap.to(".about-hero-gra", {
            xPercent: 0,
            ease: "Power3.easeOut",
            duration: 1.5,
            delay: 1
          });
          gsap.set(".ah-mobile-h1", {opacity: 0, yPercent: 20});
          gsap.to(".ah-mobile-h1", {
              delay: 1.5,
              opacity: 1,
              yPercent: 0,
              duration: 0.7
          });

          //ProcessIMG 
          gsap.set(".cp-image-reveal", {opacity: 0});
          gsap.to(".cp-image-reveal", {
            opacity: 1,
            ease: "Power3.easeOut",
            delay: 1,
            duration: 1
          });

      //Our APPROACH
      gsap.set(".process-approach .line span", {y: 180, rotateZ: 6});
      gsap.to(".process-approach .line span", {
        y: 0,
        rotateZ: 0,
        ease: "Power3.easeOut",
        delay: 0,
        stagger: {
          amount: 0.2
        },
        scrollTrigger: {
          trigger: ".process-approach .line span",
          start: "top 90%",
        }
      });

      //RDB
      gsap.set(".bdr-ryt", {height: 0});
      gsap.to(".bdr-ryt", {
        height: "100%",
        scrollTrigger: {
          trigger: ".bdr-ryt",
          start: "top 50%",
        }
      });


      gsap.set(".rdb", { visibility: "hidden" });
        ScrollTrigger.batch(".rdb", {
            onEnter: batch => {
                gsap.from(batch, {
                    duration: 1.5,
                    delay: .5,
                    yPercent: 50,
                    scale: 1,
                    autoAlpha: 0,
                    ease: "Power3.easeOut",
                    stagger: 0.2,
                    visibility: "visible",
                })
            },
            toggleActions: "play none none none",
            once: true,
            start: 'top 70%',
            end: 'top 50%',
            markers: false,
        });

        //OUR PROCESS 
        gsap.set(".our-process-txt1", { y: "27vw", rotateZ:"5deg" });
        gsap.to(".our-process-txt1", {
        y: 0,
        rotateZ:"0deg",
        ease: "Power3.easeOut",
        duration: 0.7,
        scrollTrigger: {
            trigger: ".our-process-txt1",
            start: "top 80%"
        }
        });
        gsap.set(".our-process-txt2", { y: "27vw", rotateZ:"5deg", });
        gsap.to(".our-process-txt2", {
        y: 0,
        rotateZ:"0deg",
        ease: "Power3.easeOut",
        duration: 0.71,
        scrollTrigger: {
            trigger: ".our-process-txt2",
            start: "top 100%"
        }
        });

        //OPW
        gsap.set(".owpimg-desktop1", {xPercent: 100 });
        gsap.to(".owpimg-desktop1", {
          xPercent: 0,
          duration: 1,
          ease: "Power3.easeOut",
          scrollTrigger: {
            trigger: ".owpimg-desktop1",
            start: 'top 70%'
          }
        });
        gsap.set(".owpimg-desktop2", {xPercent: -100 });
        gsap.to(".owpimg-desktop2", {
          xPercent: 0,
          duration: 1,
          ease: "Power3.easeOut",
          scrollTrigger: {
            trigger: ".owpimg-desktop2",
            start: 'top 70%'
          }
        });
        gsap.set(".owpimg-desktop3", {xPercent: 100 });
        gsap.to(".owpimg-desktop3", {
          xPercent: 0,
          duration: 1,
          ease: "Power3.easeOut",
          scrollTrigger: {
            trigger: ".owpimg-desktop3",
            start: 'top 70%'
          }
        });





      //WORK
      gsap.set(".fixed-work", { position: "relative" });
      gsap.to(".fixed-work", {
      position: "fixed",
      top: "10%",
      ease: "Power3.easeOut",
      scrollTrigger: {
          trigger: ".fixed-work",
          start: "1%",
          scrub: true,
      }
      });
      gsap.set(".fixed-work", {opacity: 1 });
      gsap.to(".fixed-work", {
        opacity: 0,
        ease: "Power3.easeOut",
        scrollTrigger: {
          trigger: ".work-hide",
          start: 'top 150%',
          scrub: true,
        }
      });
    }

    componentDidMount(){
        this.processGsap()
    }


    render() {
        
        return (

        <>
            <Helmet>
                <title>Fingerprints Future | Expertise in Website Design, UI/UX, Product Design, and App Design</title>
                <meta name="description" content="Expert website design, mobile app design and development, UI/UX, and product strategy and design for MNCs, product companies, enterprises, and more - all available at our company." />
            </Helmet>
            <div className='home2 services2'>
            <div className="home-cover-out"></div>
            <div className='sunnybee-cover'></div>
            <style>
                {
                    `.inner-page-btn {
                        display: none;
                    }`
                }
            </style>

            {/* Our Process Hero */}
            <div className="home-hero process-hero" id="hero">
                <div className="v2-home-banner-shape-img v2-home-banner-video">
                    <div className="d-none d-lg-block d-md-block">
                        <img alt="Fingerprints Future" className="img-fluid" src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/v2-home-banner-shape-img1-1.png"  />
                        {/* <video className='project-text'  src="https://storage.googleapis.com/fingerprintsfuturewebsite/videos/v2-home-banner-video.mp4" muted autoPlay={"autoplay"} preload="auto" loop playsInline controlsList="nodownload" /> */}
                    </div>
                    <div className="d-block d-lg-none d-md-none">
                        <img alt="Fingerprints Future" className="img-fluid" src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/v2-home-banner-shape-img-resp1-1.png"  />
                        {/* <video className='project-text'  src="https://storage.googleapis.com/fingerprintsfuturewebsite/videos/v2-home-banner-video.mp4" muted autoPlay={"autoplay"} preload="auto" loop playsInline controlsList="nodownload" /> */}
                    </div>
                </div>
                <div className="container"> 
                    <div className="process-hero-content home-hero-content">
                        <h1 className="line d-none d-lg-block d-md-block ">
                            <span>The Future is all about</span>
                        </h1>
                        <h1 className="line d-none d-lg-block d-md-block ">
                            <span>crafting engaging</span>
                        </h1>
                        <h1 className="line d-none d-lg-block d-md-block ">
                            <span>human experiences.</span>
                        </h1>
                    </div>
                    <h1 className="d-block d-lg-none d-md-none ah-mobile-h1">The Future is <br className='d-block d-lg-none d-md-none' /> all about crafting <br className='d-block d-lg-none d-md-none' /> engaging human experiences.</h1>
                    
                </div>
                <div className="marquee-parent">
                    <div className="content"><h2 className='marquee-e'> PRODUCT DESIGN <span></span> MVP <span></span> DESIGN SPRINTS <span></span> DESIGN SYSTEMS <span></span> PRODUCT DESIGN <span></span> MVP <span></span> DESIGN SPRINTS <span></span> DESIGN SYSTEMS <span></span> PRODUCT DESIGN <span></span> MVP <span></span> DESIGN SPRINTS <span></span> DESIGN SYSTEMS <span></span></h2></div>
                </div>
            </div>
            <div className="process-hero d-none">
                {/* <div className="about-hero-gra"></div> */}
                <div className="container"> 
                    <div className="process-hero-content">
                        <h1 className="line d-none d-lg-block d-md-block">
                            <span>The Future is all about crafting</span>
                        </h1>
                        <h1 className="line d-none d-lg-block d-md-block">
                            <span>engaging human experiences.</span>
                        </h1>
                    </div>
                    <h1 className="d-block d-lg-none d-md-none ah-mobile-h1">The Future is all about crafting engaging human experiences.</h1>
                </div>
            </div>
            {/* Our Process Hero End*/}

            {/* Our Process Img */}
            <div className='our-process-img cp-image-reveal d-none'>
                <img alt="Fingerprints Future" src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/our-process-img-1.png" />
            </div>
            {/* Our Process Img End */}

            <main>
                {/* Our Process Approach */}
                <div className='our-approach'>
                    {/* <div className="our-approach-gra"></div> */}
                    <div className='container our-approach-cont'>
                        <div className='row'>
                            <div className='col-lg-1'></div>
                            <div className='col-lg-10 col-md-10 process-approach'>
                                <h2 className="line d-none d-lg-block"><span>We create people-centric, data driven experiences, backed by</span></h2>
                                <h2 className="line d-none d-lg-block"><span>great design.  Across mediums, these products and services</span></h2>
                                <h2 className="line d-none d-lg-block"><span>deliver consistent, measurable and delightful digital experiences. </span></h2>
                                <div className="workanimation d-block d-lg-none d-md-none">
                                    <h2>We create people-centric, data driven experiences, backed by great design.  Across mediums, these products and services deliver consistent, measurable and delightful digital experiences.</h2>
                                </div>
                            </div>
                            <div className='col-lg-1'></div>
                            <div className='col-lg-1'></div>
                            <div className='col-lg-10 col-md-10'>
                                <div className='our-approach-keys'>
                                    {/* <h4 className='workanimation'>Key Services</h4> */}
                                    <div className='chips-cover text-lg-center'>
                                            <div className='chip workanimation'><span>Web & Mobile App Development</span></div>
                                            <div className='chip workanimation'><span>Fintech Product Development</span></div>
                                            <div className='chip workanimation'><span>Wordpress Development</span></div>
                                            <div className='chip workanimation'><span>UX Consulting</span></div>
                                            <br className='d-none d-xl-block'/>
                                            <div className='chip workanimation'><span>SaaS Development</span></div>
                                            <div className='chip workanimation'><span>eCommerce Development</span></div>
                                            <div className='chip workanimation'><span>MVP Development</span></div>
                                            {/* <br className='d-none d-lg-block'/> */}
                                            <div className='chip workanimation'><span>UX Audit & Testing</span></div>
                                            {/* <br className='d-none d-lg-block'/> */}
                                            <div className='chip workanimation'><span>Design-to-Development Support</span></div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-1'></div>
                        </div>
                    </div>

                    {/* Our Process Work */}
                    <div className='our-work'>
                        <div className='container our-work-cont d-none'>
                            <div className='row our-p'>
                                <div className='col-lg-1'></div>
                                <div className='col-lg-3'>
                                    <div className='our-work-process bdr-ryt  d-none d-lg-block'>
                                        {/* <img alt="Fingerprints Future" src={Icon1} /> */}
                                        <div className='rdb'>
                                            <Lottie
                                            loop
                                            animationData={ResearchJson}
                                            play
                                            style={{ width: "2.778vw", height: "2.778vw" }}
                                            />
                                        </div>
                                        <h4 className='rdb'>Research</h4>
                                        <ul>
                                            <li className='rdb'>Digital Product Strategy</li>
                                            <li className='rdb'>User Research</li>
                                            <li className='rdb'>Heuristic Analysis</li>
                                            <li className='rdb'>User Personas</li>
                                            <li className='rdb'>Content Strategy</li>
                                            <li className='rdb'>Information Architecture</li>
                                            <li className='rdb'>Design Audit</li>
                                            <li className='rdb'>Usability Testing</li>
                                            <li className='rdb'>User Interviews</li>
                                            <li className='rdb'>Wireframing</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className='col-lg-4'>
                                    <div className='our-work-process bdr-ryt  d-none d-lg-block'>
                                        {/* <img alt="Fingerprints Future" src={Icon2} /> */}
                                        <div className='rdb'>
                                            <Lottie
                                            loop
                                            animationData={DesignJson}
                                            play
                                            style={{ width: "2.778vw", height: "2.778vw" }}
                                            />
                                        </div>
                                        <h4 className='rdb'>Design</h4>
                                        <ul>
                                            <li className='rdb'>Digital Branding</li>
                                            <li className='rdb'>User Experience Design</li>
                                            <li className='rdb'>User interface Design</li>
                                            <li className='rdb'>Interaction Design</li>
                                            <li className='rdb'>Digital Prototyping</li>
                                            <li className='rdb'>Motion Design</li>
                                            <li className='rdb'>Illustrations and Iconography</li>
                                            <li className='rdb'>Design System</li>
                                        </ul>
                                    </div>
                                    <div className="home-what-inner2-mobile d-block d-lg-none">
                                        <div className="accordion" id="accordionExample">
                                            <div className="accordion-item workanimation">
                                                <h2 className="accordion-header" id="headingOne">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                                    <div className="row">
                                                        <div className="col-6 mobile-lottie-acc">
                                                        <Lottie
                                                        loop
                                                        animationData={ResearchJson}
                                                        play
                                                        style={{ width: "30px", height: "30px" }}
                                                        />
                                                        </div>
                                                        <div className="col-6"><h3>Research</h3></div>
                                                    </div>
                                                </button>
                                                </h2>
                                                <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                <div className="accordion-body">
                                                <p>
                                                Digital Product Strategy <br/>
                                                User Research <br/>
                                                Personas <br/>
                                                UX Copywriting <br/>
                                                Information Architecture <br/>
                                                Information Architecture <br/>
                                                User Interviews <br/>
                                                Wireframing <br/>
                                                </p>
                                                </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item workanimation">
                                                <h2 className="accordion-header" id="headingTwo">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                <div className="row">
                                                        <div className="col-6 mobile-lottie-acc">
                                                        <Lottie
                                                        loop
                                                        animationData={DesignJson}
                                                        play
                                                        style={{ width: "30px", height: "30px" }}
                                                        />
                                                        </div>
                                                        <div className="col-6"><h3>Design</h3></div>
                                                    </div>
                                                </button>
                                                </h2>
                                                <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                                <div className="accordion-body">
                                                <p>
                                                User Experience Design (UX) <br/>
                                                User Interface Design (UI) <br/>
                                                Rapid Prototyping <br/>
                                                Micro-interaction Animation <br/>
                                                Design Systems <br/>
                                                Usability Testing <br/>
                                                Accessibility Audits <br/>
                                                UX + Heuristic Audits <br/>
                                                </p>
                                                </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item workanimation">
                                                <h2 className="accordion-header" id="headingThree">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                <div className="row">
                                                        <div className="col-6 mobile-lottie-acc">
                                                        <Lottie
                                                        loop
                                                        animationData={BuildJson}
                                                        play
                                                        style={{ width: "30px", height: "30px" }}
                                                        />
                                                        </div>
                                                        <div className="col-6"><h3>Build</h3></div>
                                                    </div>
                                                </button>
                                                </h2>
                                                <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                                <div className="accordion-body">
                                                <p>
                                                E Commerce Solutions <br/>
                                                Content Management System (CMS) <br/>
                                                Custom Design & Development <br/>
                                                Custom Wordpress Websites <br/>
                                                Interactive Experiences (Gamification) <br/>
                                                Website Maintenance <br/>
                                                iOS & Android Mobile App Development <br/>
                                                </p>
                                                </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-3'>
                                    <div className='our-work-process  d-none d-lg-block'>
                                        {/* <img alt="Fingerprints Future" src={Icon3} /> */}
                                        <div className='rdb'>
                                            <Lottie
                                            loop
                                            animationData={BuildJson}
                                            play
                                            style={{ width: "2.778vw", height: "2.778vw" }}
                                            />
                                        </div>
                                        <h4 className='rdb'>Build</h4>
                                        <ul className='rdb'>
                                            <li className='rdb'>E Commerce Solutions</li>
                                            <li className='rdb'>Content Management System (CMS)</li>
                                            <li className='rdb'>Custom Design & Development</li>
                                            <li className='rdb'>Custom Wordpress Websites</li>
                                            <li className='rdb'>Interactive Experiences (Gamification)</li>
                                            <li className='rdb'>Website Maintenance</li>
                                            <li className='rdb'>iOS & Android Mobile App Development</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className='col-lg-1'></div>
                            </div>
                        </div>

                        <div className='container our-process-cont d-none'>
                            <div className="process-inner2-h2-div"> <h2 className='our-process-txt our-process-txt1'>Our</h2></div>
                            <div className="process-inner2-h22-div"> <h2 className='our-process-txt our-process-txt2'><span>Services</span></h2></div>
                        </div>

                        <div className='our-work-process-cont'>
                            <div className='row owpis'>
                                <div className='col-lg-6 col-md-6 align-self-center chip-p-m'>
                                    <div className='d-block'>
                                        <h2 className='workanimation' id='research' >Research</h2>
                                        <p className='workanimation'>Before we get started we get into the important stuff. We delve <br className='d-none d-xl-block'/> deep to understand your business goals and objectives. We <br className='d-none d-xl-block'/> approach every problem with a clear understanding before <br className='d-none d-xl-block'/> defining an optimal strategy for design and development.</p>
                                        <div className='chips-cover'>
                                            <div className='chip workanimation'><span>Digital Product Strategy</span></div>
                                            <div className='chip workanimation'><span>User Research</span></div>
                                            <div className='chip workanimation'><span>Heuristic Analysis</span></div>
                                            <div className='chip workanimation'><span>User Personas</span></div>
                                            <div className='chip workanimation'><span>Design Audit</span></div>
                                            <div className='chip workanimation'><span>Content Strategy</span></div>
                                            <div className='chip workanimation'><span>Usability Testing</span></div>
                                            <div className='chip workanimation'><span>User Interviews</span></div>
                                            <div className='chip workanimation'><span>Information Architecture</span></div>
                                            <div className='chip workanimation'><span>Wireframing</span></div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-6'>
                                    {/* <div className="our-work-process-gra1"></div> */}
                                    <img alt="Fingerprints Future" className='owpimg-desktop1 d-none d-lg-block' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/our-work-process-img1-2x2.png" />
                                    <img alt="Fingerprints Future" className='owpimg-mobile1 workanimation d-block d-lg-none' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/our-work-process-img1-2x2.png" />

                                </div>
                            </div>
                            <div className='row owpis-1'>
                                <div className='col-lg-6 col-md-6'>
                                    {/* <div className="our-work-process-gra2"></div> */}
                                    <img alt="Fingerprints Future" className='owpimg-desktop2 d-none d-lg-block' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/our-work-process-img2-1x2.png" />
                                    <img alt="Fingerprints Future" className='owpimg-mobile2 workanimation d-block d-lg-none' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/our-work-process-img2-1x2.png" />

                                </div>
                                <div className='col-lg-6 col-md-6 align-self-center chip-p-m'>
                                    <div className='d-block'>
                                        <h2 className='workanimation'>Design</h2>
                                        <p className='workanimation'>Design is key in the Future. We create beautiful, immersive and <br className='d-none d-xl-block'/> fully responsive products, apps, websites and more. Every <br className='d-none d-xl-block'/> successful digital product or experience is a result of our <br className='d-none d-xl-block'/> obsession and experimentation with design.</p>
                                        <div className='chips-cover'>
                                            <div className='chip workanimation'><span>Digital Branding</span></div>
                                            <div className='chip workanimation'><span>User Experience Design</span></div>
                                            <div className='chip workanimation'><span>User interface Design</span></div>
                                            <div className='chip workanimation'><span>Interaction Design</span></div>
                                            <div className='chip workanimation'><span>Digital Prototyping</span></div>
                                            <div className='chip workanimation'><span>Motion Design</span></div>
                                            <div className='chip workanimation'><span>Illustrations and Iconography</span></div>
                                            <div className='chip workanimation'><span>Design System</span></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row owpis'>
                                <div className='col-lg-6 col-md-6 align-self-center'>
                                    <div className='d-block'>
                                        <h2 className='workanimation'>Build</h2>
                                        <p className='workanimation'>Our expertise lies in full stack development of <br className='d-none d-xl-block'/> applications, products and digital experiences. We help <br className='d-none d-xl-block'/> large and small scale businesses achieve their digital <br className='d-none d-xl-block'/> transformation and navigate them into the Future. </p>
                                        <div className='chips-cover'>
                                            <div className='chip workanimation'><span>Front-End Development</span></div>
                                            <div className='chip workanimation'><span>Web Application</span></div>
                                            <div className='chip workanimation'><span>Mobile Application</span></div>
                                            <div className='chip workanimation'><span>Custom Application</span></div>
                                            <div className='chip workanimation'><span>SAAS Implementation</span></div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-6'>
                                    {/* <div className="our-work-process-gra3"></div> */}
                                    <img alt="Fingerprints Future" className='owpimg-desktop3 d-none d-lg-block' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/our-work-process-img3-1x2.png" />
                                    <img alt="Fingerprints Future" className='owpimg-mobile3 workanimation d-block d-lg-none' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/our-work-process-img3-1x2.png" />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Our Process Work End */}
                </div>
                {/* Our Process Approach End */}
                    {/* Work Section */}
                    <div className="home-work v2-home-work">
                        <div className="home-work-gra"></div>
                        {/* <div className="fixed-work-parent"><h4 className="fixed-work">WORK</h4></div> */}
                        <div className='row'>
                            <div className='col-xl-2 col-lg-1'></div>
                            <div className='col-xl-8 col-lg-10 col-md-8 process-approach'>
                                <h2 className="line d-none d-lg-block"><span>Exceptional Design, Meticulous Development:</span></h2>
                                <h2 className="line d-none d-lg-block"><span>Why We're a Cut Above the Rest</span></h2>
                                <div className="workanimation d-block d-lg-none">
                                    <h2>Exceptional Design, Meticulous Development: Why We're a Cut Above the Rest</h2>
                                </div>
                            </div>
                            <div className='col-xl-2 col-lg-1'></div>
                        </div>
                        <div className="container fixed-work-cont">
                            <div className="row home-work-parent">
                                <div className="col-lg-6  home-work-child workanimation">
                                    <a className="sunnybee-link" href="/work/sunnybee"></a>
                                    <img alt="Fingerprints Future" className="img-fluid" src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/sunnybeex2.png"  />
                                    <h3>SunnyBee Market</h3>
                                    <p>Mobile App</p>
                                </div>
                                <div className="col-lg-6 home-work-child workanimation">
                                    <a className="savittr-link" href="/work/savittr"></a>
                                    <img alt="Fingerprints Future" className="img-fluid" src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/savittrx2.png"  />
                                    <h3>Savittr</h3>
                                    <p>Wordpress Design & Development</p>
                                </div>
                            </div>
                                
                            <div className="row home-work-parent work-hide">
                                <div className="col-lg-6  home-work-child workanimation">
                                    <a className="e-wallet-link" href="/work/e-wallet"></a>
                                    <img alt="Fingerprints Future" className="img-fluid d-none d-lg-block" src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/ewallet2x2.png" />
                                    <img alt="Fingerprints Future" className="img-fluid d-block d-lg-none" src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/ewallet1.png" />
                                    <h3>E-Wallet</h3>
                                    <p>Mobile App</p>
                                </div>
                                <div className="col-lg-6 home-work-child workanimation">
                                    <a className="dealsplus-product-link" href="/work/dealsplus-product"></a>
                                    <img alt="Fingerprints Future" className="img-fluid" src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/dealsplus-thumbx2.png"  />
                                    <h3>Deals Plus</h3>
                                    <p>Product Design</p>
                                </div>
                            </div>

                            <div className="row home-work-parent">
                                <div className="col-lg-6 home-work-child workanimation">
                                    <a className="fut-bank-link" href="/work/fut-bank"></a>
                                    <img alt="Fingerprints Future" className="img-fluid" src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/futx2.png"  />
                                    <h3>Fut Bank</h3>
                                    <p>Product Design</p>
                                </div>
                                <div className="col-lg-6  home-work-child workanimation">
                                    <a className="authorcafe-link" href="/work/authorcafe"></a>
                                    <img alt="Fingerprints Future" className="img-fluid d-none d-lg-block" src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/authorcafe-thumbx2.png" />
                                    <img alt="Fingerprints Future" className="img-fluid d-block d-lg-none" src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/authorcafe-thumbx1.png" />
                                    <h3>Authorcafe</h3>
                                    <p>Website Design & Development</p>
                                </div>
                            </div>
                        </div>
                        <div className="extra-button-col d-none">
                            <a href="/work" className="form-control work-link">View all work</a>
                        </div>
                    </div>
                    {/* Work Section End*/}
            </main>




            </div>

                <Footer />

        </>

        )
    }
}
