import React, { Component } from 'react'
import Footer from '../components/footer'
import {Helmet} from "react-helmet";
import gsap from 'gsap'
import SplitText from 'gsap/SplitText'
import ScrollTrigger from 'gsap/ScrollTrigger'


export default class savittr extends Component {
    projectGsap = () =>{
        gsap.registerPlugin(ScrollTrigger, SplitText)

        //Hero

        // gsap.from(".normal-header", 0.9, {
        //     opacity: 0,
        //     ease: "Power3.easeOut",
        //     delay: 3.5,
        //   })
          gsap.from("h2.line span", 0.9, {
            y: 180,
            rotateZ: 6,
            ease: "Power3.easeOut",
            delay: 0.7,
            stagger: {
              amount: 0.2
            }
          })
          gsap.from(".mobile-sunnybee-h4", 0.9, {
            y: 40,
            opacity: 0,
            ease: "Power3.easeOut",
            delay: 1.5,
            stagger: {
              amount: 0.2
            }
          })
          gsap.set(".sunnybee-hero", {height: "100vh"});
          gsap.to(".sunnybee-hero", {
            height: "80vh",
            delay: 1,
            ease: "Power3.easeOut",
            duration: 1
          });
          gsap.set(".sunnybee-hero-listitem li", { visibility: "hidden" });
          ScrollTrigger.batch(".sunnybee-hero-listitem li", {
              onEnter: batch => {
                  gsap.from(batch, {
                      duration: 1,
                      opacity: 0,
                      delay: 1.2,
                      yPercent: 100,
                      scale: 1,
                      autoAlpha: 0,
                      ease: "Power3.easeOut",
                      stagger: 0.1,
                      visibility: "visible",
                  })
              },
              toggleActions: "play none none none",
              once: true,
              markers: false,
          });
          gsap.set(".sunnybee-cover", {opacity: 1});
          gsap.to(".sunnybee-cover", {
            opacity: 0,
            delay: 0,
            ease: "Power3.easeIn",
            duration: 0.3
          });
          gsap.set(".sunnybee-heading", {opacity: 0});
          gsap.to(".sunnybee-heading", {
            opacity: 1,
            delay: 0.1,
            ease: "Power3.easeIn",
            duration: 1
          });
          gsap.set(".sunnybee-hero-cont", {"margin-bottom": 0});
          gsap.to(".sunnybee-hero-cont", {
            "margin-bottom": 90,
            delay: 1,
            ease: "Power3.linear",
            duration: 1.5
          });
          gsap.set(".our-approach-gra", {xPercent: 100});
          gsap.to(".our-approach-gra", {
            xPercent: 0,
            delay: 2,
            ease: "Power3.easeOut",
            duration: 1.5
          });
          gsap.set(".project-text", { visibility: "hidden" });
          ScrollTrigger.batch(".project-text", {
              onEnter: batch => {
                  gsap.from(batch, {
                      duration: 1.5,
                      delay: 0,
                      yPercent: 50,
                      scale: 1,
                      autoAlpha: 0,
                      ease: "Power3.easeOut",
                      stagger: 0.4,
                      visibility: "visible",
                  })
              },
              toggleActions: "play none none none",
              once: true,
              start: 'top 80%',
              end: 'top 40%',
              markers: false,
          });

          gsap.set(".bdr-top", {width: "0%"});
          gsap.to(".bdr-top", {
            width: "100%",
            ease: "Power3.easeOut",
            duration: 1,
            scrollTrigger:{
                trigger: ".bdr-top",
                start: "top 80%"
            }
          });



    }
    

    componentDidMount(){
        this.projectGsap();
        
    }

  render() {
    return (
      <>
        <Helmet>
            <title>Design for Savittr - Custom WordPress design | Fingerprints Future</title>
            <meta name="description" content="Revamp Savittr's brand tone and aesthetic. Utilized design thinking to redesign the website with improved navigation and fluid animations" />
        </Helmet>
        
        <style>
            {
                `.home-work-parent h3 {
                    color: #000;
                }`
            }
        </style>
      <div className="home-cover-out"></div>
        <div className='overflow-hidden'>
            <div className='sunnybee-cover'></div>
            {/* Savittr Hero */}
            <div className='sunnybee-hero'>
                {/* <div className="our-approach-gra"></div> */}
                <div className='container sunnybee-hero-cont'>
                    <div className='row'>
                        <div className='col-lg-9'>
                            <div>
                                <h2 className='sunnybee-heading'>Savittr</h2>
                            </div>
                            <div className='d-none d-lg-block d-md-block'>
                                <div>
                                    <h2 className='line'><span>Creating a Future ready </span></h2>
                                </div>
                                <div>
                                    <h2 className='line'><span>website that converts visitors </span></h2>
                                </div>
                                <div>
                                    <h2 className='line'><span>into customers</span></h2>
                                </div>
                            </div>
                            <div className='d-block d-lg-none d-md-none'>
                                <h4 className='mobile-sunnybee-h4'>Creating a Future ready website that converts visitors into customers</h4>
                            </div>
                        </div>
                        <div className='col-lg-3 sunnybee-hero-listitem'>
                            <ul>
                                <li>Wireframing</li>
                                <li>User Experience Design (UX)</li>
                                <li>User Interface Design (UI)</li>
                                <li>Rapid Prototyping</li>
                                <li>Micro-interaction Animation</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            {/* Savittr Hero End */}

            <div className='container sunnybee-video1 hide-nav'>
                <img alt="Fingerprints Future" className='img-fluid d-lg-block d-none' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/savittr-img-1.png"  />
                <img alt="Fingerprints Future" className='img-fluid d-lg-none d-block ' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/savittr-mob1.png"  />
                {/* <video src={Video1} muted autoPlay={"autoplay"} preload="auto" loop playsInline controlsList="nodownload" /> */}
            </div>

            {/* Savittr About */}
            <div className='sunnybee-about'>
                <div className="d-none d-lg-block sunnybee-gra1"></div>
                <div className="d-block d-lg-none sunnybee-gra1-reps"></div>
                <div className='container sunnybee-about-cont'>
                    <div className='row'>
                        <div className='col-lg-1 d-lg-block d-md-none'></div>
                        <div className='col-lg-5 col-md-6'>
                            <div>
                                <h4 className='project-text'>What went into the</h4>
                            </div>
                            <div>
                                <h4 className='project-text'>creation of the website?</h4>
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-6'>
                            <div>
                                <p className='project-text'>We revamped the brand website by re-imagining it’s navigation and added fluid animations to improve the user experience. We also redesigned the layout to create a modern, visually-appealing site that is easy to use. As a result, the website now generates more leads and offers a clear call to action for users.</p>
                            </div>
                        </div>
                        <div className='col-lg-2 d-lg-block d-md-none'></div>
                    </div>
                </div>
            </div>
            {/* Savittr About End */}

            <div className='container savittr-video1'>
                {/* <img alt="Fingerprints Future" className='img-fluid' src={Img2} /> */}
                <video className='project-text' src="https://storage.googleapis.com/fingerprintsfuturewebsite/videos/savittr-video-1.mp4" muted autoPlay={"autoplay"} preload="auto" loop playsInline controlsList="nodownload" />
            </div>
        </div>

        <div className='savittr-creative'>
            <div className='savittr-creative-bg'>
                <div className='container savittr-creative-das'>
                    <div className='row savittr-creative-colr'>
                        <div className='col-lg-1 d-lg-block d-md-none'></div>
                        <div className='col-lg-4 col-md-6 align-self-center'>
                            <div className=''>
                                <h4 className='project-text'>A distinctive artistic style</h4>
                                <p className='project-text'>We created customized illustrations in a mix of geometric shapes to represent the content in a visually engaging manner. This helped us to effectively convey the website's key message while also creating a distinct experience for its users</p>
                            </div>
                        </div>
                        <div className='col-lg-2 col-md-1'></div>
                        <div className='col-lg-4 col-md-5 savittr-video4'>
                            {/* <img className='img-fluid project-text' src={Imgicon} alt="Future" /> */}
                            <video className='project-text' src="https://storage.googleapis.com/fingerprintsfuturewebsite/videos/savittr-video-4.mp4" muted autoPlay={"autoplay"} preload="auto" loop playsInline controlsList="nodownload" />
                        </div>
                        <div className='col-lg-1 d-lg-block d-md-none'></div>
                    </div>
                </div>
            </div>

            <div className='container savittr-img3-4'>
                <div className='row'>
                    <div className='col-lg-6 col-md-6 savittr-video3'>
                        {/* <img alt="Fingerprints Future" className='img-fluid img3' src={Img3} /> */}
                        <video className='project-text' src="https://storage.googleapis.com/fingerprintsfuturewebsite/videos/savittr-video-3.mp4" muted autoPlay={"autoplay"} preload="auto" loop playsInline controlsList="nodownload" />
                    </div>
                    <div className='col-lg-6 col-md-6 savittr-video2'>
                        {/* <img alt="Fingerprints Future" className='img-fluid' src={Img4} /> */}
                        <video className='project-text' src="https://storage.googleapis.com/fingerprintsfuturewebsite/videos/savittr-video-2.mp4" muted autoPlay={"autoplay"} preload="auto" loop playsInline controlsList="nodownload" />
                    </div>
                </div>
            </div>

            <div className='container savittr-creative-intra txt-black'>
                <div className='row savittr-creative-colr'>
                    <div className='col-lg-1 d-lg-block d-md-none'></div>
                    <div className='col-lg-4 col-md-6 align-self-center'>
                        <h4 className='project-text'>Creating a more engaging <br />user experience.</h4>
                        <p className='project-text'>We enhanced the user experience by incorporating fluid animation into the content which allows users to quickly and easily skim through it, while also providing a more visually engaging and interactive experience. This also helped keep users engaged and on the site for longer periods of time.</p>
                    </div>
                    <div className='col-lg-7 col-md-6'>
                        <img alt="Fingerprints Future" className='img-fluid project-text' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/savittr-img-5.png" />
                        {/* <video src={Video3} muted autoPlay={"autoplay"} preload="auto" loop playsInline controlsList="nodownload" /> */}
                    </div>
                </div>
            </div>

            <div className='container savittr-creative-contress'>
                <div className='row'>
                    <div className='col-lg-1 d-lg-block d-md-none'></div>
                    <div className='col-lg-2 col-md-3 savittr-img6'>
                        <img alt="Fingerprints Future" className='img-fluid project-text' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/savittr-img-6-new.png" />
                    </div>
                    <div className='col-lg-2 col-md-3 savittr-img7'>
                        <img alt="Fingerprints Future" className='img-fluid project-text' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/savittr-img-7-new.png" />
                    </div>
                    <div className='col-lg-2 d-lg-block d-md-none'></div>
                    <div className='col-lg-4 col-md-6 align-self-center'>
                        <h4 className='project-text'>Bringing the brand to life</h4>
                        <p className='project-text'>The brand identity was brought to life through uniform use of brand colours, fonts, illustrations and a consistent tone of voice. By adding design and a distinct communication style to the website, we were able to give it a personality and make it come alive.</p>
                    </div>
                    <div className='col-lg-1 d-lg-block d-md-none'></div>
                </div>
            </div>

            <div className='container savittr-img8'>
                <div className='row'>
                    <div className='col-lg-1'></div>
                    <div className='col-lg-10'>
                        <img alt="Fingerprints Future" className='img-fluid project-text' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/savittr-img-8.svg" />
                    </div>
                    <div className='col-lg-1'></div>
                </div>
            </div>
        </div>

        {/* Work Section */}
        <div className="sunnybee-work">
            <div className="container fixed-work-cont">
                <div className='bdr-top'></div>
                <h4 className='project-text'>You might also like</h4>
                <div className="row home-work-parent">
                    <div className="col-lg-6  home-work-child project-text">
                        <a className="sunnybee-link" href="/work/sunnybee"></a>
                        <img alt="Fingerprints Future" className="img-fluid" src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/sunnybeex2.png" />
                        <h3>SunnyBee Market</h3>
                        <p>Mobile App</p>
                    </div>
                    <div className="col-lg-6  home-work-child project-text">
                        <a className="dealsplus-website-link" href="/work/dealsplus-website"></a>
                        <img alt="Fingerprints Future" className="img-fluid" src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/dealsplus-website-cover-img-nw.png" />
                        <h3>Deals Plus</h3>
                        <p>Website Design & Development</p>
                    </div>
                    </div>
            </div>
            {/* <a href="#"><h3 className="view-all">View all case studies</h3></a> */}
        </div>
        {/* Work Section End*/}
      

      <Footer />
      </>
    )
  }
}
