import React, { Component } from "react";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
} from "react-share";
import AuthorIcon from "./../assets/blog-author.png";
import SocialShare from "../components/socialShare";
import Footer from "../components/footer";
import GetStartedCard from "../components/getStartedCard";

class NewblogResearchandproductdesign extends Component {
  render() {
    const recentBlogData = [
      {
        title: "Learning from Mario: Using Heuristics in UX /UI Design",
        publishedOn: "May 03, 2023",
        minuteRead: "5",
        imgUrl: "https://ik.imagekit.io/fingerprintsfuture/fps/blog-uh-img.png",
        blogUrl: "/blog/using-heuristics-ui-ux-design",
      },
      {
        title: "Design Systems and Why They Matter",
        publishedOn: "Jan 24, 2023",
        minuteRead: "7",
        imgUrl:
          "https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/blog-ds-img.png",
        blogUrl: "/blog/design-systems",
      },
      {
        title: "The Importance of Designing for usability in UI and UX",
        publishedOn: "Jan 24, 2023",
        minuteRead: "7",
        imgUrl:
          "https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/blog-du-img.png",
        blogUrl: "/blog/the-importance-of-designing-for-usability",
      },
    ];

    return (
      <>
        <div className="blog-temp-parent overflow-hidden v2-blog">
          <style>
            {`.inner-page-btn {
                            display: none;
                        }`}
          </style>
          <div className="home-cover-out"></div>
          <div className="container">
            <div className="row">
              <div className="col-lg-8">
                <div className="blog-temp-hero">
                  <div>
                    <a href="/blog" className="back-btn">
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="5"
                          height="9"
                          viewBox="0 0 5 9"
                          fill="none"
                        >
                          <path
                            d="M4.62012 0.879761L1 4.50014L4.62012 8.12026"
                            stroke="#23262F"
                          />
                        </svg>
                      </span>
                      Back
                    </a>
                  </div>

                  <div className="nwv-blog1-hero">
                    <h1>
                      Building Products People Love:
                      <br className="d-none d-lg-block" />
                      The Role of Research in Product Design
                    </h1>
                    <div className="nwv-blog1-hero-adt">
                      <p>7 Minute Read</p>
                      {/* <span></span>
                      <img
                        className="img-fluid blog-author-img"
                        src={AuthorIcon}
                        alt="Author"
                      />
                      <p>Aishwarya Daswani</p> */}
                      {/* <span></span>
                                        <p>Jan 14, 2023</p> */}
                    </div>
                    <h2 className="workanimation">
                      Hey there, Future here! We're a user experience agency
                      that specializes in helping companies design products that
                      people love. And we can't stress enough the importance of
                      research when it comes to product design.{" "}
                    </h2>
                    <p>
                      You see, research is like the foundation of a building -
                      without it, your product might look pretty on the outside,
                      but it's not going to stand the test of time.
                    </p>
                  </div>
                  <div className="main-hero-blog-row">
                    <div className="bth-right-p workanimation">
                      <img
                        alt="Fingerprints Future"
                        src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/blog-rapd-img3.png"
                        className="img-fluid"
                      />
                    </div>
                  </div>
                </div>

                <div className="blog-content">
                  <div className="col-lg-12">
                    <p>
                      Let's be real, no one knows your customers better than
                      your customers themselves. And that's why research is so
                      important - it gives you a direct line to understanding
                      their needs, wants and pain points. It's like being able
                      to read their minds, without the creepy vibes.
                    </p>
                    <br />

                    <div className="blog-du-imgcont">
                      <div className="row">
                        <div className="col-md-6">
                          <img
                            src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/blog-rapd-img-1.png"
                            alt="blog-img"
                          />
                        </div>
                        <div className="col-md-6 align-self-center">
                          <h2>
                            By conducting research, you're able to make informed
                            decisions about what features to include, how to
                            design the user interface, and how to position your
                            product. It allows you to build products that truly
                            solve your customers' problems and makes their lives
                            easier.
                          </h2>
                        </div>
                      </div>
                    </div>

                    <div className="txt-bdr-du">
                      <h2 className="workanimation">
                        And the best part? Research doesn't have to be
                        complicated or time-consuming. With the right approach,
                        you can gather valuable insights in a way that's
                        efficient and effective. So whether you're just starting
                        out with your product or you're looking to improve upon
                        an existing one, don't overlook the power of research.
                        Trust us, your customers <br /> (and your bottom line)
                        will thank you.
                      </h2>
                    </div>

                    <div className="txt-gradi txt-gradi-resp">
                      <h3>Practice what you preach - An oldie but a Goldie</h3>
                    </div>

                    <p className="workanimation">
                      So you've read about the importance of research in product
                      design, but you're probably wondering - what does that
                      look like in practice? Well, let us tell you about a
                      little project we worked on here at Future. We had the
                      opportunity to work with a company called Loyalty Caravan,
                      who had a vision for a web-based application that helps
                      banks and their customers redeem their debit or credit
                      card usage points for miles on airplane tickets. And we
                      were thrilled to be able to not only design but also
                      develop this application from scratch for them.
                    </p>
                    <br />
                    <p className="workanimation">
                      When we first got our hands on the project, it was clear
                      that it had potential. But, as with most products, it
                      wasn't quite hitting the mark when it came to user
                      experience. That's where we came in. We dove headfirst
                      into research mode, talking to customers, bank
                      representatives, and even travel agents to get a better
                      understanding of what they needed and wanted from a
                      loyalty program.
                    </p>
                    <br />

                    <div className="blog-du-img3">
                      <img
                        className="img-fluid"
                        src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/blog-rapd-img-2.png"
                        alt="blog-img"
                      />
                    </div>
                    <br />

                    <p>
                      Through our research, we learned that customers wanted a
                      seamless experience when it came to redeeming their points
                      and booking travel. They wanted to be able to easily
                      browse and compare deals, and have the ability to book and
                      cancel directly on the platform. We also learned that
                      banks wanted a platform that was easy to integrate with
                      their existing systems and provided detailed reporting on
                      customer engagement.
                    </p>
                    <br />
                    <p>
                      Armed with this information, we were able to work with the
                      Loyalty Caravan team to design and develop the application
                      from scratch, creating a user experience that met the
                      needs of both customers and banks. We streamlined the
                      redemption process, made it easy to browse and compare
                      deals, and integrated reporting capabilities for the
                      banks. And let us tell you, the end result was a thing of
                      beauty.
                    </p>
                    <br />
                    <p>
                      It's always a thrilling to see a product go from an idea
                      to a reality, and that's exactly what happened with
                      Loyalty Caravan. And it's all thanks to the power of
                      research. By truly understanding the needs of our users,
                      we were able to design and develop a product from scratch
                      that not only looked good, but delivered real value. So,
                      next time you're planning on building something new,
                      remember: research is the key ingredient to making
                      something great.
                    </p>
                    <br />

                    <div className="blog-line-bdr"></div>

                    <div className="txt-gradi">
                      <h3>
                        Designing with the User in Mind: Inside Look at the{" "}
                        <br className="d-none d-xl-block d-lg-block" /> Research
                        Behind Loyalty Caravan's Web-based Application
                      </h3>
                    </div>
                    <br />

                    <p>
                      In terms of secondary research, we looked at a variety of
                      sources to gather information that had{" "}
                      <br className="d-none d-xl-block d-lg-block" /> already
                      been collected and published by others. This include
                    </p>
                    <br />

                    <div className="row">
                      <div className="col-md-4  blog-du-card position-relative">
                        {/* <img className='w-100' src={BlogDU3} /> */}
                        <div className="blog-rapd-card1 blog-rapd-cardcont">
                          <div className="txt-outline">
                            <h4>01</h4>
                          </div>
                          <h4>Market Research</h4>
                          <p>
                            We conducted research to understand the competitive
                            landscape in the loyalty program and travel booking
                            industry. This helped us to identify key players in
                            the market and understand how they were currently
                            rewarding customers
                          </p>
                        </div>
                      </div>
                      <div className="col-md-4  blog-du-card position-relative">
                        {/* <img className='w-100' src={BlogDU4} /> */}
                        <div className="blog-rapd-card2 blog-rapd-cardcont">
                          <div className="txt-outline">
                            <h4>02</h4>
                          </div>
                          <h4>Industry trends and best practices</h4>
                          <p>
                            We also looked at industry trends and best practices
                            to understand what was currently popular and
                            effective in the market. This helped us to identify
                            features and functionality that were in demand and
                            could be included in the Loyalty Caravan platform.
                          </p>
                        </div>
                      </div>
                      <div className="col-md-4  blog-du-card position-relative">
                        {/* <img className='w-100' src={BlogDU5} /> */}
                        <div className="blog-rapd-card3 blog-rapd-cardcont">
                          <div className="txt-outline">
                            <h4>03</h4>
                          </div>
                          <h4>Existing loyalty programs</h4>
                          <p>
                            We also looked at existing loyalty programs offered
                            by banks and airlines to understand how they were
                            currently rewarding customers and what kind of
                            rewards customers were looking for. This helped us
                            to identify opportunities for differentiation and
                            understand what was already out there.
                          </p>
                        </div>
                      </div>
                    </div>
                    <br />

                    <p>
                      You may read this and think to yourself, how very basic.
                      Sometimes, that's all you need to create an app that is
                      successful. As we discussed, usability is an essential
                      aspect of app and digital product design, and it is
                      especially important for apps that are frequently used on
                      smartphones. One of the key challenges in designing apps
                      for smartphones is ensuring that they are easy to use with
                      just one hand. With so many apps vying for our attention,
                      it's crucial for designers to create products that are
                      both easy to use and accessible for everyone.
                    </p>
                    <br />

                    <p>
                      The primary and secondary research we conducted for the
                      Loyalty Caravan project was a crucial step in designing
                      and developing a platform that met the needs of both
                      customers and banks. By gathering information ,we were
                      able to gain a deep understanding of the specific needs,
                      wants, and pain points of our customers. This information
                      allowed us to create a platform that truly solved their
                      problems and made their lives easier.
                    </p>
                    <br />
                    <p>
                      Additionally, the secondary research we conducted helped
                      us to understand the competitive landscape, identify
                      industry trends and best practices, and understand
                      existing loyalty programs offered by banks and airlines.
                      This helped us to identify opportunities for
                      differentiation and understand what was already out there
                      in the market.
                    </p>
                    <br />
                    <p>
                      Overall, by combining both primary and secondary research
                      methods, we were able to gain a comprehensive
                      understanding of the needs and wants of our target
                      audience, which allowed us to create a platform that met
                      the specific needs of our customers while also standing
                      out in a crowded market. The research we conducted helped
                      us to identify key features and functionality that needed
                      to be included in the platform, as well as areas where the
                      user experience could be improved.
                    </p>
                    <br />

                    <p>
                      The research was a vital step in the development of
                      Loyalty Caravan, it allowed us to understand the user's
                      needs and preferences, which resulted in a platform that
                      is user-friendly and meets their needs. Thanks to the
                      research, we were able to create a product that not only
                      looked good, but truly solved their problems and made
                      their lives easier.
                    </p>
                    <br />

                    <div className="blog-line-bdr"></div>

                    <div>
                      <div className="txt-gradi">
                        <h3>Designing for Real People</h3>
                      </div>
                      <p>
                        Understanding the Customer Personas and User Journeys
                        behind Loyalty Caravan's Web-based Application
                      </p>
                    </div>
                    <br />

                    <h2>
                      As part of the research process for the Loyalty Caravan
                      project, we also created customer personas to represent
                      the different types of users that would be using the
                      platform.
                    </h2>
                    <br />

                    <div className="row blog-du-img8-9">
                      <div className="col-md-12">
                        <img
                          className=""
                          src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/blog-rapd-img-6.png"
                          alt="blog-img"
                        />
                      </div>
                      <div className="col-md-12">
                        <img
                          className=""
                          src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/blog-rapd-img-7.png"
                          alt="blog-img"
                        />
                      </div>
                      <div className="col-md-12">
                        <h2>
                          For example, Sanjay’s user journey would include:
                        </h2>
                        <img
                          className=""
                          src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/blog-rapd-img-8.png"
                          alt="blog-img"
                        />
                      </div>
                    </div>

                    <div className="blog-du-coux">
                      <h2>Conculsion</h2>
                    </div>

                    <p>
                      In conclusion, creating customer personas and user
                      journeys was an essential step in the design process for
                      the Loyalty Caravan project. By understanding the wants,
                      needs, and pain points of our target users, we were able
                      to create a platform that truly met their needs and made
                      their lives easier.
                    </p>
                    <br />

                    <p>
                      With this understanding in hand, we moved on to the next
                      phase of the design process, which involved creating
                      wireframes and prototypes of the platform.
                    </p>
                    <br />

                    <p>
                      Through this process, we were able to take the Loyalty
                      Caravan platform from an idea to a fully-functioning
                      web-based application that provided real value to our
                      customers. The end result was a platform that not only
                      looked good, but delivered real value to our customers and
                      stood out in the market.
                    </p>
                    <br />

                    <p>
                      The team at Future lives for projects like these, where we
                      can apply our design and development expertise to create
                      products that truly solve problems and make a difference
                      in people's lives. We are passionate about creating
                      user-centered designs and enjoy the process of taking an
                      idea and turning it into a reality.
                    </p>
                    <br />
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="v2-blog-sidebar">
                  <div className="v2-blog-sidebar-cont">
                    <div className="v2-blog-sidebar-tags">
                      <h4>Tags</h4>
                      <div className="">
                        <span className="">UI design</span>
                        <span className="">UX DESIGN</span>
                        <span className="">ARTIFICIAL INTELLIGENCE</span>
                        <span className="">WEB DEVELOPMENT</span>
                        <span className="">FRONTEND DEVELOPMENT</span>
                        <span className="">USER INTERFACE</span>
                        <span className="">USER EXPERIENCE</span>
                        <span className="">RESPONSIVE DESIGN</span>
                      </div>
                    </div>

                    <div className="v2-blog-sidebar-posts">
                      <h4>POPULAR POSTS</h4>
                      <div className="all-posts">
                        {recentBlogData.map((blog, index) => (
                          <a
                            href={blog.blogUrl}
                            key={index}
                            className="post-container"
                          >
                            <img src={blog.imgUrl} alt="post-banner" />
                            <p>
                              {blog.title}
                              <br />
                              {/* <span>{blog.publishedOn}</span> */}
                              <span>{blog.minuteRead} Minute read</span>
                            </p>
                          </a>
                        ))}
                      </div>
                    </div>

                    <div className="v2-blog-sidebar-social">
                      <h4>SHARE</h4>
                      <div className="">
                        <div className="socials-container">
                          <FacebookShareButton
                            url={
                              "https://fingerprintsfuture.com/blog/the-role-of-resarch-and-product-design"
                            }
                            description={
                              "Building Products People Love: The Role of Research in Product Design"
                            }
                            className="Demo__some-network__share-button"
                          >
                            <FacebookIcon size={36.67} round />
                          </FacebookShareButton>
                          <TwitterShareButton
                            title={
                              "Building Products People Love: The Role of Research in Product Design"
                            }
                            url={
                              "https://fingerprintsfuture.com/blog/the-role-of-resarch-and-product-design"
                            }
                          >
                            <TwitterIcon size={36.67} round />
                          </TwitterShareButton>
                          <LinkedinShareButton
                            title={
                              "Building Products People Love: The Role of Research in Product Design"
                            }
                            url={
                              "https://fingerprintsfuture.com/blog/the-role-of-resarch-and-product-design"
                            }
                          >
                            <LinkedinIcon size={36.67} round />
                          </LinkedinShareButton>
                        </div>
                      </div>
                    </div>
                    <div className="">
                      <GetStartedCard />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

export default NewblogResearchandproductdesign;
