import React, { Component } from "react";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
} from "react-share";
import AuthorIcon from "./../assets/blog-author.png";
import Footer from "../components/footer";
import SocialShare from "../components/socialShare";
import GetStartedCard from "../components/getStartedCard";

export default class NewblogDesignsystem extends Component {
  render() {
    const recentBlogData = [
      {
        title: "Chatbots AI - Hot or not?",
        publishedOn: "Mar 08, 2023",
        minuteRead: "7",
        imgUrl:
          "https://ik.imagekit.io/fingerprintsfuture/fps/blog-chn-img.png",
        blogUrl: "/blog/chatbots-hot-or-not",
      },
      {
        title:
          "Building Products People Love: The Role of Research in Product Design",
        publishedOn: "Jan 14, 2023",
        minuteRead: "7",
        imgUrl:
          "https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/blog-rapd-img1.png",
        blogUrl: "/blog/the-role-of-resarch-and-product-design",
      },
      {
        title:
          "Discovering the Magic of Spatial Input: Interacting Naturally with Your Eyes and Hands",
        publishedOn: "Jun 16, 2023",
        minuteRead: "9",
        imgUrl:
          "https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/blog-dtmsi-img.png",
        blogUrl: "/blog/discovering-the-magic-of-spatial-input",
      },
    ];

    return (
      <>
        <div className="blog-temp-parent overflow-hidden v2-blog">
          <style>
            {`.inner-page-btn {
                    display: none;
                }`}
          </style>
          <div className="home-cover-out"></div>
          <div className="container">
            <div className="row">
              <div className="col-lg-8">
                <div className="blog-temp-hero">
                  <div>
                    <a href="/blog" className="back-btn">
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="5"
                          height="9"
                          viewBox="0 0 5 9"
                          fill="none"
                        >
                          <path
                            d="M4.62012 0.879761L1 4.50014L4.62012 8.12026"
                            stroke="#23262F"
                          />
                        </svg>
                      </span>
                      Back
                    </a>
                  </div>

                  <div className="nwv-blog1-hero">
                    <h1>
                      AI Isn't Making Us Dumb:
                      <br className="d-none d-lg-block" />
                      The Positive Impact of Chat GPT{" "}
                      <br className="d-none d-lg-block" />
                      and Other Technologies
                    </h1>
                    <div className="nwv-blog1-hero-adt">
                      <p>3 Minute Read</p>
                      {/* <span></span>
                      <img
                        className="img-fluid blog-author-img"
                        src={AuthorIcon}
                        alt="Author"
                      />
                      <p>Aishwarya Daswani</p> */}
                      {/* <span></span>
                                <p>Apr 03, 2023</p> */}
                    </div>
                    <h2>
                      Hello Fellows, Lately, there's been a lot of talk about
                      whether AI is making us dumb. People are worried that
                      tools like Chat GPT are replacing human intelligence or
                      making us lazy. But here's the tea: AI is actually a total
                      game-changer for our smarts and creativity.
                    </h2>
                  </div>
                  <div className="main-hero-blog-row">
                    <div className="bth-left-p d-none">
                      <div className="bth-left-p-cont">
                        <div className="blog-chips-cover workanimation">
                          <div className="blog-chip">
                            <span>UX DESIGN</span>
                          </div>
                        </div>
                        <h1 className="workanimation">
                          Design Systems and{" "}
                          <br className="d-none d-lg-block" />
                          Why They Matter
                        </h1>
                        <p className="workanimation">7 Minute Read</p>
                      </div>
                      <div className="daterow workanimation">
                        <p>FEBRUARY 7, 2023</p>
                        <p>BY FUTURE</p>
                      </div>
                      {/* <div className="row daterow workanimation">
                                    <div className="col-6 blog-date"><h6>JANUARY 12, 2022</h6></div>
                                    <div className="col-6 blog-author"><h6>BY FUTURE</h6></div>
                                </div> */}
                    </div>
                    <div className="bth-right-p workanimation">
                      <img
                        alt="Fingerprints Future"
                        src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/blog-adn-img1.jpg"
                        className="img-fluid"
                      />
                    </div>
                  </div>
                </div>
                <div className="blog-content">
                  <div className="col-lg-12">
                    <p>
                      In this blog post, we're gonna spill the tea on why AI,
                      including Chat GPT, is actually a dope ally to our human
                      intelligence. We'll show you how these tools are unlocking
                      new possibilities, improving our problem-solving skills,
                      and giving us all kinds of benefits. So sit back, relax,
                      and get ready to level up your intelligence with AI!
                    </p>
                    <br />

                    <div className="txt-bdr-du">
                      <h2 className="workanimation">
                        So, Chat GPT isn't the only cool thing happening in the
                        world of AI and machine learning. These cutting-edge
                        technologies are revolutionizing various industries, and
                        the possibilities are endless!
                      </h2>
                    </div>
                    <br />
                    <br />
                    <div className="blog-du-card position-relative blog-adn-card">
                      <img
                        className="w-100 h-100"
                        src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/blog-adn-img-dec1.png"
                        alt="blog-img"
                      />
                      <div className="blog-du-cardcont">
                        <div className="txt-outline">
                          <h4>01</h4>
                        </div>
                        <h4>Education</h4>
                        <br />
                        <p>
                          AI-powered adaptive learning platforms are like your
                          own personal tutor, but better! They analyze your
                          performance and adjust the curriculum based on your
                          strengths and weaknesses. And NLP algorithms are like
                          your language coach, helping you improve your speaking
                          and writing skills.
                        </p>
                      </div>
                    </div>
                    <br />
                    <div className="blog-du-card position-relative blog-adn-card">
                      <img
                        className="w-100 h-100"
                        src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/blog-adn-img-dec1.png"
                        alt="blog-img"
                      />
                      <div className="blog-du-cardcont">
                        <div className="txt-outline">
                          <h4>02</h4>
                        </div>
                        <h4>Health</h4>
                        <br />
                        <p>
                          AI is helping medical professionals diagnose and treat
                          patients with greater accuracy and speed. And
                          AI-powered chatbots are like your personal health
                          coach, providing you with personalized advice and
                          support.
                        </p>
                      </div>
                    </div>
                    <br />
                    <div className="blog-du-card position-relative blog-adn-card">
                      <img
                        className="w-100 h-100"
                        src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/blog-adn-img-dec1.png"
                        alt="blog-img"
                      />
                      <div className="blog-du-cardcont">
                        <div className="txt-outline">
                          <h4>03</h4>
                        </div>
                        <h4>Customer Service</h4>
                        <br />
                        <p>
                          AI-powered chatbots and virtual assistants are like
                          your own personal customer service reps, answering
                          your questions and resolving your issues quickly and
                          efficiently. Plus, sentiment analysis algorithms can
                          tell businesses what you really think, so they can
                          improve their products and services.
                        </p>
                      </div>
                    </div>
                    <br />
                    <div className="blog-du-card position-relative blog-adn-card">
                      <img
                        className="w-100 h-100"
                        src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/blog-adn-img-dec1.png"
                        alt="blog-img"
                      />
                      <div className="blog-du-cardcont">
                        <div className="txt-outline">
                          <h4>04</h4>
                        </div>
                        <h4>Marketing</h4>
                        <br />
                        <p>
                          AI-powered recommendation engines are like your own
                          personal shopper, suggesting products based on your
                          preferences and behaviors. And ML algorithms can
                          predict what you'll do next, so businesses can create
                          personalized marketing campaigns just for you.
                        </p>
                      </div>
                    </div>
                    <br />
                    <br />
                    <p>Now Let's Talk About Chat GPT Specifically:</p>
                    <br />
                    <div className="txt-gradi txt-gradi-resp">
                      <h3>
                        How It's Enhancing Knowledge and Creativity{" "}
                        <br className="d-none d-lg-block" />
                        in the advertising and marketing industry
                      </h3>
                    </div>
                    <br />
                    <p>
                      First up, content creation. Chat GPT can generate
                      high-quality content in no time, saving marketers and
                      content creators tons of time and effort. It's also great
                      for generating new ideas and perspectives, leading to more
                      creative and innovative content.
                    </p>
                    <br />
                    <h3>
                      And that's not all - Chat GPT can also be used for
                      strategy. It can analyze customer data and provide
                      insights into consumer behavior, helping businesses make
                      informed decisions about their marketing strategies. Plus,
                      it can assist with writing instructional and long format
                      content, without the least amount of effort
                    </h3>
                    <br />
                    <p>
                      Now, there's a common concern that using Chat GPT or other
                      AI-powered technologies will make us lazy or less
                      intelligent. However, this couldn't be further from the
                      truth. In fact, Chat GPT is more like a calculator - when
                      they were first introduced, people worried that
                      calculators would make us less intelligent and lazy. But
                      in reality, calculators helped us become more efficient
                      and improved our problem-solving skills.
                    </p>
                    <br />
                    <p>
                      Similarly, Chat GPT is a tool that enhances our creativity
                      and knowledge. It's not a replacement for human
                      intelligence, but rather a supplement that allows us to
                      focus on the aspects of our jobs that require more
                      creativity and problem-solving skills. So, let's embrace
                      Chat GPT and other AI-powered tools as a way to enhance
                      our skills and work smarter, not harder.
                    </p>
                    <br />
                    <br />
                    <div className="txt-gradi txt-gradi-resp">
                      <h3>
                        AI is an enhancer for the  mind - Definitely not{" "}
                        <br className="d-none d-lg-block" />a replacement - So
                        let’s calm down folks 
                      </h3>
                    </div>
                    <br />
                    <p>
                      Now, there's one thing you should keep in mind when using
                      Chat GPT - what you get out of it is only as good as what
                      you put into it. In other words, if you don't know the
                      subject matter you're asking Chat GPT to generate content
                      about, it won't be able to add much great value.
                    </p>

                    <br />
                    <p>
                      But that's true of any tool or technology - it's only as
                      effective as the person using it. That's why it's
                      important to keep learning and expanding your knowledge
                      base, so you can make the most of tools like Chat GPT and
                      other AI-powered technologies.
                    </p>
                    <br />
                    <p>
                      So, whether you're in HR,  or a content creator, social
                      media manager, or  a strategist, Chat GPT is a tool you
                      should definitely have in your toolkit. It's making our
                      lives easier, more efficient, and more creative - and
                      that's something we can all get behind!
                    </p>
                    <br />
                    <br />
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="v2-blog-sidebar">
                  <div className="v2-blog-sidebar-cont">
                    <div className="v2-blog-sidebar-tags">
                      <h4>Tags</h4>
                      <div className="">
                        <span className="">UI design</span>
                        <span className="">UX DESIGN</span>
                        <span className="">ARTIFICIAL INTELLIGENCE</span>
                        <span className="">WEB DEVELOPMENT</span>
                        <span className="">FRONTEND DEVELOPMENT</span>
                        <span className="">USER INTERFACE</span>
                        <span className="">USER EXPERIENCE</span>
                        <span className="">RESPONSIVE DESIGN</span>
                      </div>
                    </div>

                    <div className="v2-blog-sidebar-posts">
                      <h4>POPULAR POSTS</h4>
                      <div className="all-posts">
                        {recentBlogData.map((blog, index) => (
                          <a
                            href={blog.blogUrl}
                            key={index}
                            className="post-container"
                          >
                            <img src={blog.imgUrl} alt="post-banner" />
                            <p>
                              {blog.title}
                              <br />
                              {/* <span>{blog.publishedOn}</span> */}
                              <span>{blog.minuteRead} Minute read</span>
                            </p>
                          </a>
                        ))}
                      </div>
                    </div>

                    <div className="v2-blog-sidebar-social">
                      <h4>SHARE</h4>
                      <div className="">
                        <div className="socials-container">
                          <FacebookShareButton
                            url={
                              "https://fingerprintsfuture.com/blog/ai-dumb-or-not"
                            }
                            description={
                              "AI Isn't Making Us Dumb: The Positive Impact of Chat GPT and Other Technologies"
                            }
                            className="Demo__some-network__share-button"
                          >
                            <FacebookIcon size={36.67} round />
                          </FacebookShareButton>
                          <TwitterShareButton
                            title={
                              "AI Isn't Making Us Dumb: The Positive Impact of Chat GPT and Other Technologies"
                            }
                            url={
                              "https://fingerprintsfuture.com/blog/ai-dumb-or-not"
                            }
                          >
                            <TwitterIcon size={36.67} round />
                          </TwitterShareButton>
                          <LinkedinShareButton
                            title={
                              "AI Isn't Making Us Dumb: The Positive Impact of Chat GPT and Other Technologies"
                            }
                            url={
                              "https://fingerprintsfuture.com/blog/ai-dumb-or-not"
                            }
                          >
                            <LinkedinIcon size={36.67} round />
                          </LinkedinShareButton>
                        </div>
                      </div>
                    </div>
                    <div className="">
                      <GetStartedCard />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}
