import React, { Component } from 'react'
import Footer from '../components/footer'
import {Helmet} from "react-helmet";
import gsap from 'gsap'
import SplitText from 'gsap/SplitText'
import ScrollTrigger from 'gsap/ScrollTrigger'

export default class XTM extends Component {

    projectGsap = () =>{
        gsap.registerPlugin(ScrollTrigger, SplitText)

        //Hero

        // gsap.from(".normal-header", 0.9, {
        //     opacity: 0,
        //     ease: "Power3.easeOut",
        //     delay: 3.5,
        //   })
          gsap.from("h2.line span", 0.9, {
            y: 180,
            rotateZ: 6,
            ease: "Power3.easeOut",
            delay: 0.7,
            stagger: {
              amount: 0.2
            }
          })
          gsap.from(".mobile-sunnybee-h4", 0.9, {
            y: 40,
            opacity: 0,
            ease: "Power3.easeOut",
            delay: 1.5,
            stagger: {
              amount: 0.2
            }
          })
          gsap.set(".sunnybee-hero", {height: "100vh"});
          gsap.to(".sunnybee-hero", {
            height: "80vh",
            delay: 1,
            ease: "Power3.easeOut",
            duration: 1
          });
          gsap.set(".sunnybee-hero-listitem li", { visibility: "hidden" });
          ScrollTrigger.batch(".sunnybee-hero-listitem li", {
              onEnter: batch => {
                  gsap.from(batch, {
                      duration: 1,
                      opacity: 0,
                      delay: 1.2,
                      yPercent: 100,
                      scale: 1,
                      autoAlpha: 0,
                      ease: "Power3.easeOut",
                      stagger: 0.1,
                      visibility: "visible",
                  })
              },
              toggleActions: "play none none none",
              once: true,
              markers: false,
          });
          gsap.set(".sunnybee-cover", {opacity: 1});
          gsap.to(".sunnybee-cover", {
            opacity: 0,
            delay: 0,
            ease: "Power3.easeIn",
            duration: 0.3
          });
          gsap.set(".sunnybee-heading", {opacity: 0});
          gsap.to(".sunnybee-heading", {
            opacity: 1,
            delay: 0.1,
            ease: "Power3.easeIn",
            duration: 1
          });
          gsap.set(".sunnybee-hero-cont", {"margin-bottom": 0});
          gsap.to(".sunnybee-hero-cont", {
            "margin-bottom": 90,
            delay: 1,
            ease: "Power3.linear",
            duration: 1.5
          });
          gsap.set(".our-approach-gra", {xPercent: 100});
          gsap.to(".our-approach-gra", {
            xPercent: 0,
            delay: 2,
            ease: "Power3.easeOut",
            duration: 1.5
          });
          gsap.set(".project-text", { visibility: "hidden" });
          ScrollTrigger.batch(".project-text", {
              onEnter: batch => {
                  gsap.from(batch, {
                      duration: 1.5,
                      delay: 0,
                      yPercent: 50,
                      scale: 1,
                      autoAlpha: 0,
                      ease: "Power3.easeOut",
                      stagger: 0.4,
                      visibility: "visible",
                  })
              },
              toggleActions: "play none none none",
              once: true,
              start: 'top 80%',
              end: 'top 40%',
              markers: false,
          });

          gsap.set(".bdr-top", {width: "0%"});
          gsap.to(".bdr-top", {
            width: "100%",
            ease: "Power3.easeOut",
            duration: 1,
            scrollTrigger:{
                trigger: ".bdr-top",
                start: "top 80%"
            }
          });



    }
    

    componentDidMount(){
        this.projectGsap();
        
    }

    render() {
        return (
            <>

            
            <style>
                {
                    `.home-work-parent h3 {
                        color: #000;
                    }`
                }
            </style>

            <div className="home-cover-out"></div>
            <div className='overflow-hidden'>
                <div className='sunnybee-cover'></div>
                {/* Savittr Hero */}
                <div className='sunnybee-hero'>
                    {/* <div className="our-approach-gra"></div> */}
                    <div className='container sunnybee-hero-cont'>
                        <div className='row'>
                            <div className='col-lg-9'>
                                <div>
                                    <h2 className='sunnybee-heading'>XTM</h2>
                                </div>
                                <div className='d-none d-lg-block d-md-block'>
                                    <div>
                                        <h2 className='line'><span>Empowering XTM with an E-commerce </span></h2>
                                    </div>
                                    <div>
                                        <h2 className='line'><span>Platform to Drive Sales and Growth</span></h2>
                                    </div>
                                    <div>
                                        <h2 className='line'><span></span></h2>
                                    </div>
                                </div>
                                <div className='d-block d-lg-none d-md-none'>
                                    <h4 className='mobile-sunnybee-h4'>Empowering XTM with an E-commerce Platform to Drive Sales and Growth</h4>
                                </div>
                            </div>
                            <div className='col-lg-3 sunnybee-hero-listitem'>
                                <ul>
                                    <li>USER RESEARCH</li>
                                    <li>PERSONAS</li>
                                    <li>USER FLOWS</li>
                                    <li>Wire-framing</li>
                                    <li>User Experience Design (UX)</li>
                                    <li>User Interface Design (UI)</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Savittr Hero End */}

                <div className='container sunnybee-video1 hide-nav '>
                    <img alt="Fingerprints Future" className='img-fluid d-none d-lg-block' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/xtm-img1.png"  />
                    <img alt="Fingerprints Future" className='img-fluid d-block d-lg-none' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/xtm-resp-img1.png"  />
                </div>

                {/* Savittr About */}
                <div className='sunnybee-about'>
                    <div className="d-none d-lg-block sunnybee-gra1"></div>
                    <div className="d-block d-lg-none sunnybee-gra1-reps"></div>
                    <div className='container sunnybee-about-cont'>
                        <div className='row'>
                            <div className='col-lg-6 col-md-6'>
                                <img className='img-fluid project-text d-none d-lg-block' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/xtm-img2.png"alt="Future" />
                                <img className='img-fluid project-text d-block d-lg-none' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/xtm-resp-img2.png"alt="Future" />
                            </div>
                            <div className='col-lg-6 col-md-6 futbank-img6 resp-img-width savittr-video3'>
                                <video className='project-text' src="https://storage.googleapis.com/fingerprintsfuturewebsite/videos/xtm-video1.mp4" muted autoPlay={"autoplay"} preload="auto" loop playsInline controlsList="nodownload" />
                            </div>
                        </div>
                    </div>
                </div>
                {/* Savittr About End */}


                <div className='container sunnybee-about-img'>
                    <div className='row authorcafe-video-img'>
                        <div className='col-lg-12'>
                            <video className='project-text' src="https://storage.googleapis.com/fingerprintsfuturewebsite/videos/xtm-video2.mp4" muted autoPlay={"autoplay"} preload="auto" loop playsInline controlsList="nodownload" />
                            {/* <img className='img-fluid project-text d-none d-lg-block' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/xtm-img4.png"alt="Future" /> */}
                            {/* <img className='img-fluid project-text d-block d-lg-none' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/health-care2.png"alt="Future" /> */}
                            {/* <img className='img-fluid project-text d-block d-lg-none' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/ewallet-img-5-m2.png" alt="Future" /> */}
                        </div>
                        <div className='col-lg-12 authorcafe-img6'>
                            <img className='img-fluid project-text d-none d-lg-block' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/xtm-img5.png"alt="Future" />
                            <img className='img-fluid project-text d-block d-lg-none' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/xtm-resp-img5.png"alt="Future" />
                            {/* <img className='img-fluid project-text d-block d-lg-none' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/ewallet-img-5-m2.png" alt="Future" /> */}
                        </div>
                    </div>
                </div>

                <div className='sunnybee-about'>
                    <div className="d-none d-lg-block sunnybee-gra1"></div>
                    <div className="d-block d-lg-none sunnybee-gra1-reps"></div>
                    <div className='container sunnybee-about-cont'>
                        <div className='row'>
                            <div className='col-lg-6 col-md-6'>
                                <img className='img-fluid project-text d-none d-lg-block' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/xtm-img6.png"alt="Future" />
                                <img className='img-fluid project-text d-block d-lg-none' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/xtm-resp-img6.png"alt="Future" />
                            </div>
                            <div className='col-lg-6 col-md-6'>
                                <img className='img-fluid project-text d-none d-lg-block' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/xtm-img7.png"alt="Future" />
                                <img className='img-fluid project-text d-block d-lg-none' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/xtm-resp-img7.png"alt="Future" />
                            </div>
                        </div>
                    </div>
                </div>

            </div>

                    {/* Work Section */}
        <div className="sunnybee-work">
            <div className="container fixed-work-cont">
                <div className='bdr-top'></div>
                <h4 className='project-text'>You might also like</h4>
                <div className="row home-work-parent">
                    <div className="col-lg-6 home-work-child project-text">
                                <a className="fut-bank-link" href="/work/fut-bank"></a>
                                <img alt="Fingerprints Future" className="img-fluid" src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/futx2.png" />
                                <h3>Fut Bank</h3>
                                <p>Product Design</p>
                            </div>
                            <div className="col-lg-6  home-work-child project-text">
                                <a className="dealsplus-website-link" href="/work/dealsplus-website"></a>
                                <img alt="Fingerprints Future" className="img-fluid" src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/dealsplus-webx2.png" />
                                <h3>Deals Plus</h3>
                                <p>Website Design & Development</p>
                            </div>
                </div>
            </div>
            {/* <a href="#"><h3 className="view-all">View all case studies</h3></a> */}
        </div>
        {/* Work Section End*/}

            <Footer />
            </>
        )
    }
}
