import React, { useEffect } from 'react';

const HeaderButton = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = '//embed.typeform.com/next/embed.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
      <button
        data-tf-slider="Doj9cRKt"
        data-tf-position="right"
        data-tf-opacity="100"
        data-tf-iframe-props="title=Future Contact Form"
        data-tf-open-value="30"
        data-tf-auto-close=""
        data-tf-transitive-search-params=""
        data-tf-medium="snippet"
        className="button1"
      >
        Get Started
      </button>
    </>
  );
};

export default HeaderButton;
