import React, { Component } from 'react'
import Footer from '../components/footer'
import {Helmet} from "react-helmet";
import gsap from 'gsap'
import SplitText from 'gsap/SplitText'
import ScrollTrigger from 'gsap/ScrollTrigger'


export default class EWallet extends Component {
    projectGsap = () =>{
        gsap.registerPlugin(ScrollTrigger, SplitText)

        //Hero

        // gsap.from(".normal-header", 0.9, {
        //     opacity: 0,
        //     ease: "Power3.easeOut",
        //     delay: 3.5,
        //   })
          gsap.from("h2.line span", 0.9, {
            y: 180,
            rotateZ: 6,
            ease: "Power3.easeOut",
            delay: 0.7,
            stagger: {
              amount: 0.2
            }
          })
          gsap.from(".mobile-sunnybee-h4", 0.9, {
            y: 40,
            opacity: 0,
            ease: "Power3.easeOut",
            delay: 1.5,
            stagger: {
              amount: 0.2
            }
          })
          gsap.set(".sunnybee-hero", {height: "100vh"});
          gsap.to(".sunnybee-hero", {
            height: "80vh",
            delay: 1,
            ease: "Power3.easeOut",
            duration: 1
          });
          gsap.set(".sunnybee-hero-listitem li", { visibility: "hidden" });
          ScrollTrigger.batch(".sunnybee-hero-listitem li", {
              onEnter: batch => {
                  gsap.from(batch, {
                      duration: 1,
                      opacity: 0,
                      delay: 1.2,
                      yPercent: 100,
                      scale: 1,
                      autoAlpha: 0,
                      ease: "Power3.easeOut",
                      stagger: 0.1,
                      visibility: "visible",
                  })
              },
              toggleActions: "play none none none",
              once: true,
              markers: false,
          });
          gsap.set(".sunnybee-cover", {opacity: 1});
          gsap.to(".sunnybee-cover", {
            opacity: 0,
            delay: 0,
            ease: "Power3.easeIn",
            duration: 0.3
          });
          gsap.set(".sunnybee-heading", {opacity: 0});
          gsap.to(".sunnybee-heading", {
            opacity: 1,
            delay: 0.1,
            ease: "Power3.easeIn",
            duration: 1
          });
          gsap.set(".sunnybee-hero-cont", {"margin-bottom": 0});
          gsap.to(".sunnybee-hero-cont", {
            "margin-bottom": 90,
            delay: 1,
            ease: "Power3.linear",
            duration: 1.5
          });
          gsap.set(".our-approach-gra", {xPercent: 100});
          gsap.to(".our-approach-gra", {
            xPercent: 0,
            delay: 2,
            ease: "Power3.easeOut",
            duration: 1.5
          });
          gsap.set(".project-text", { visibility: "hidden" });
          ScrollTrigger.batch(".project-text", {
              onEnter: batch => {
                  gsap.from(batch, {
                      duration: 1.5,
                      delay: 0,
                      yPercent: 50,
                      scale: 1,
                      autoAlpha: 0,
                      ease: "Power3.easeOut",
                      stagger: 0.4,
                      visibility: "visible",
                  })
              },
              toggleActions: "play none none none",
              once: true,
              start: 'top 80%',
              end: 'top 40%',
              markers: false,
          });

          gsap.set(".bdr-top", {width: "0%"});
          gsap.to(".bdr-top", {
            width: "100%",
            ease: "Power3.easeOut",
            duration: 1,
            scrollTrigger:{
                trigger: ".bdr-top",
                start: "top 80%"
            }
          });



    }
    

    componentDidMount(){
        this.projectGsap();
        
    }

  render() {
    return (
      <>
        <Helmet>
            <title>E-Wallet | Fingerprints Future</title>
            <meta name="description" content="E-Wallet" />
        </Helmet>
        
        <style>
            {
                `.home-work-parent h3 {
                    color: #000;
                }`
            }
        </style>
        
      <div className="home-cover-out"></div>
        <div className='overflow-hidden'>
            <div className='sunnybee-cover'></div>
            {/* Savittr Hero */}
            <div className='sunnybee-hero'>
                {/* <div className="our-approach-gra"></div> */}
                <div className='container sunnybee-hero-cont'>
                    <div className='row'>
                        <div className='col-lg-9'>
                            <div>
                                <h2 className='sunnybee-heading'>E- Wallet</h2>
                            </div>
                            <div className='d-none d-lg-block d-md-block'>
                                <div>
                                    <h2 className='line'><span>Simplify Your Spending with </span></h2>
                                </div>
                                <div>
                                    <h2 className='line'><span>Our E-Wallet Solution </span></h2>
                                </div>
                            </div>
                            <div className='d-block d-lg-none d-md-none'>
                                <h4 className='mobile-sunnybee-h4'>Simplify Your Spending with Our E-Wallet Solution</h4>
                            </div>
                        </div>
                        <div className='col-lg-3 sunnybee-hero-listitem'>
                            <ul>
                                <li>DIGITAL PRODUCT STRATEGY</li>
                                <li>USER RESEARCH</li>
                                <li>USER INTERFACE DESIGN (UI)</li>
                                <li>USER EXPERIENCE DESIGN (UX)</li>
                                <li>Rapid Prototyping</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            {/* Savittr Hero End */}

            <div className='container sunnybee-video1 hide-nav '>
                <img alt="Fingerprints Future" className='img-fluid d-lg-block d-none' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/ewallet-img-1.png"  />
                <img alt="Fingerprints Future" className='img-fluid d-lg-none d-block ' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/ewallet-img-1-m2.png"  />
                {/* <video src={Video1} muted autoPlay={"autoplay"} preload="auto" loop playsInline controlsList="nodownload" /> */}
            </div>

            {/* Savittr About */}
            <div className='sunnybee-about'>
                <div className="d-none d-lg-block sunnybee-gra1"></div>
                <div className="d-block d-lg-none sunnybee-gra1-reps"></div>
                <div className='container sunnybee-about-cont'>
                    <div className='row'>
                        <div className='col-lg-1 d-lg-block d-md-none'></div>
                        <div className='col-lg-5 col-md-6'>
                            <div>
                                <h4 className='project-text'>What went into the</h4>
                            </div>
                            <div>
                                <h4 className='project-text'>creation of the app?</h4>
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-6'>
                            <div>
                                <p className='project-text'>Our E-Wallet Solution not only connects you with real-time information and suggestions, but also helps you track your spending and define your financial goals. Stay on top of your finances with ease and make informed decisions about your money. With its user-friendly interface and advanced tracking capabilities, our E-Wallet makes managing your finances a breeze.</p>
                            </div>
                        </div>
                        <div className='col-lg-2 d-lg-block d-md-none'></div>
                    </div>
                </div>
            </div>
            {/* Savittr About End */}
        </div>
            <div className='container savittr-img3-4'>
                <div className='row'>
                    <div className='col-lg-6 col-md-6 savittr-video3'>
                        {/* <img alt="Fingerprints Future" className='img-fluid img3' src={Img3} /> */}
                        <video className='project-text' src="https://storage.googleapis.com/fingerprintsfuturewebsite/videos/e-walet-video-1.mp4" muted autoPlay={"autoplay"} preload="auto" loop playsInline controlsList="nodownload" />
                    </div>
                    <div className='col-lg-6 col-md-6 savittr-video2'>
                        {/* <img alt="Fingerprints Future" className='img-fluid' src={Img4} /> */}
                        <video className='project-text' src="https://storage.googleapis.com/fingerprintsfuturewebsite/videos/e-walet-video-2.mp4" muted autoPlay={"autoplay"} preload="auto" loop playsInline controlsList="nodownload" />
                    </div>
                </div>
            </div>
            <div className='container ewallet-extra-text'>
                <h4 className='project-text text-center d-none d-lg-block' >
                    A variety of widgets that display key information <br />about your finances, including your spending <br />habits, transaction history, and financial goals.
                </h4>
                <h4 className='project-text d-block d-lg-none' >
                    A variety of widgets that display key information about your finances, including your spending habits, transaction history, and financial goals.
                </h4>
                <div className='e-wallet-w'>
                    <img alt="Fingerprints Future" className='img-fluid project-text' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/ewallet-img-2.png"  />
                </div>
            </div>
            <div className='e-wallet-w2'>
                    <img alt="Fingerprints Future" className='img-fluid project-text d-none d-lg-block d-md-block' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/ewallet-img-3s2.png"  />
                    <img alt="Fingerprints Future img2" className='img-fluid project-text d-block d-lg-none d-md-none' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/ewallet-img-3s4.png"  />
            </div>
            <div className='container futbank-rd ew-resp'>
                <div className='row savittr-creative-colr'>
                    <div className='col-lg-1 col-md-1'></div>
                    <div className='col-lg-4 col-md-5 align-self-center'>
                        <h4 className='project-text'>Track your Goals</h4>
                        <p className='project-text'>At Future, we understand the importance of goal setting in financial management. We included a feature that enables users to establish financial targets and monitor their progress. <br/> <br/>
This function facilitates saving for specific objectives such as vacations, down payments, or emergency funds through automated transfers to a dedicated savings account. The visually engaging interface of the user's progress towards their goal serves to provide motivation and keep them on track towards their financial objectives.</p>
                    </div>
                    <div className='col-lg-1 col-md-1'></div>
                    <div className='col-lg-5 col-md-5 futbank-img6'>
                        <img alt="Fingerprints Future" className='img-fluid project-text' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/ewallet-img-4-2.png" />
                    </div>
                    <div className='col-lg-1'></div>
                </div>
            </div>
            <div className='container sunnybee-about-img'>
                <div className='row authorcafe-video-img'>
                    <div className='col-lg-12 authorcafe-img6'>
                        <img className='img-fluid project-text d-none d-lg-block' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/ewallet-img-5.png"alt="Future" />
                        <img className='img-fluid project-text d-block d-lg-none' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/ewallet-img-5-m2.png" alt="Future" />
                    </div>
                </div>
            </div>

            {/* Fut Bank About */}
            <div className='sunnybee-about'>
                <div className='container sunnybee-about-cont'>
                    <div className='row futbank-ebp'>
                        <div className='col-lg-1 d-lg-block d-md-none'></div>
                        <div className='col-lg-5 col-md-6 d-none d-lg-block'>
                            <div>
                                <h4 className='project-text'>Effortless Payments </h4>
                            </div>
                            <div>
                                <h4 className='project-text'>from Your Wrist</h4>
                            </div>
                        </div>
                        <div className='col-lg-5 col-md-6 d-block d-lg-none'>
                            <div>
                                <h4 className='project-text'>Effortless Payments from <br/>Your Wrist</h4>
                            </div>
                        </div>
                        <div className='col-lg-5 col-md-6'>
                            <div>
                                <p className='project-text'>Our app allows you to transfer money with just a few taps on your watch, making it easier than ever before. No more fumbling for your phone or waiting in line at the bank. With our watch app you can complete transactions instantly and on-the-go.</p>
                            </div>
                        </div>
                        <div className='col-lg-1 d-lg-block d-md-none'></div>
                    </div>
                </div>
            </div>

            <div className='container futbank-rd ew-resp'>
                <div className='row'>
                    <div className='col-lg-1'></div>
                    <div className='col-lg-5 col-md-5 futbank-img8'>
                        <img alt="Fingerprints Future" className='img-fluid project-text' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/ewallet-img-6.png" />
                    </div>
                    <div className='col-lg-1 col-md-1'></div>
                    <div className='col-lg-4 col-md-5 align-self-center'>
                        <h4 className='project-text'>Track your Spends</h4>
                        <p className='project-text'>Our advanced tracking features allow you to monitor your expenses in real-time, giving you a complete overview of your financial status. From daily purchases to recurring bills, you'll have all the information you need to make informed decisions about your money. With our user-friendly interface and intuitive tracking tools, keeping track of your spending has never been simpler.</p>
                    </div>
                    <div className='col-lg-1 col-md-1'></div>
                </div>
            </div>

            <div className='container sunnybee-about-img'>
                <div className='row authorcafe-video-img'>
                    <div className='col-lg-12 authorcafe-img6'>
                        <img className='img-fluid project-text d-none d-lg-block' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/ewallet-img-7.png"alt="Future" />
                        <img className='img-fluid project-text d-block d-lg-none' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/ewallet-img-7-m2.png" alt="Future" />
                    </div>
                </div>
            </div>

            {/* Fut Bank About */}
            <div className='sunnybee-about'>
                <div className='container sunnybee-about-cont'>
                    <div className='row futbank-ebp'>
                        <div className='col-lg-1 d-lg-block d-md-none'></div>
                        <div className='col-lg-5 col-md-6 d-none d-lg-block'>
                            <div>
                                <h4 className='project-text'>Customisable</h4>
                            </div>
                            <div>
                                <h4 className='project-text'>Dashboard</h4>
                            </div>
                        </div>
                        <div className='col-lg-5 col-md-6 d-block d-lg-none'>
                            <div>
                                <h4 className='project-text'>Customisable Dashboard</h4>
                            </div>
                        </div>
                        <div className='col-lg-5 col-md-6'>
                            <div>
                                <p className='project-text'>At  Future, we strive to continuously improve and enhance our products. With that in mind, we have designed a new home screen for our e-wallet app, which acts as a central hub for users. The UI allows for easy access to vital information, providing a comprehensive view of all critical details in one place. With this improved dashboard, users can quickly initiate payments and efficiently manage their accounts.</p>
                            </div>
                        </div>
                        <div className='col-lg-1 d-lg-block d-md-none'></div>
                    </div>
                </div>
            </div>

            <div className='container sunnybee-about-img'>
                <div className='row'>
                    <div className='col-lg-6 col-md-6'>
                        <img alt="Fingerprints Future" className='img-fluid dealsplusP-img2-resp project-text' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/ewallet-img-8.png" />
                    </div>
                    <div className='col-lg-6 col-md-6'>
                        <img alt="Fingerprints Future" className='img-fluid project-text' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/ewallet-img-9.png" />
                    </div>
                </div>
            </div>

        

        {/* Work Section */}
        <div className="sunnybee-work">
            <div className="container fixed-work-cont">
                <div className='bdr-top'></div>
                <h4 className='project-text'>You might also like</h4>
                <div className="row home-work-parent">
                    <div className="col-lg-6  home-work-child project-text">
                        <a className="sunnybee-link" href="/work/sunnybee"></a>
                        <img alt="Fingerprints Future" className="img-fluid" src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/sunnybeex2.png" />
                        <h3>SunnyBee Market</h3>
                        <p>Mobile App</p>
                    </div>
                    <div className="col-lg-6 home-work-child project-text">
                        <a className="fut-bank-link" href="/work/fut-bank"></a>
                        <img alt="Fingerprints Future" className="img-fluid d-none d-lg-block" src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/futx2.png" />
                        <img alt="Fingerprints Future" className="img-fluid d-block d-lg-none" src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/fut.png" />
                        <h3>Fut Bank</h3>
                        <p>Product Design</p>
                    </div>
                    </div>
            </div>
            {/* <a href="#"><h3 className="view-all">View all case studies</h3></a> */}
        </div>
        {/* Work Section End*/}
      

      <Footer />
      </>
    )
  }
}
